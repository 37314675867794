import firebase from 'services/firebase';

export const getFirestoreRef = (path) => firebase.firestore().collection(path);

const processDoc = (doc) => (doc.data() ? { id: doc.id, ...doc.data() } : null);

const processDocs = (query) => query.docs?.map((doc) => processDoc(doc)) || [];

export const getDocuments = async (firestoreRef, options = {}) => {
  let baseQuery = firestoreRef;

  if (options.queries) {
    const { queries } = options;

    queries.forEach(({ attribute, operator, value }) => {
      baseQuery = baseQuery.where(attribute, operator, value);
    });
  }

  if (options.sort) {
    const { attribute, order } = options.sort;

    baseQuery = baseQuery.orderBy(attribute, order);
  }

  const allDocs = await baseQuery.get();

  return !allDocs.empty ? processDocs(allDocs) : [];
};

export const getDocument = async (collectionName, id) => {
  const docRef = await getFirestoreRef(collectionName).doc(id).get();

  return processDoc(docRef);
};
