import styled, { css } from 'styled-components';
import * as yup from 'yup';

import Input, { InputType } from 'components/common/Input';
import FormControl from 'components/FormControls/FormControl';
import SelectFormControl from 'components/FormControls/SelectFormControl';
import FileFormControl from 'components/FormControls/FileInputFormControl/FileInputFormControl';
import { FileImageIcon } from 'icons';

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem 0.75rem;

  ${({ theme }) => css`
    color: ${theme.colors.darkerGray};
    caret-color: ${theme.colors.darkerGray};
    font-size: ${theme.fonts.primary.size.xl};
    vertical-align: middle;
  `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${({ theme }) =>
      ` -webkit-box-shadow: 0 0 0 30px ${theme.colors.white} inset !important;
    -webkit-text-fill-color: ${theme.colors.darkerGray};
    -webkit-text-size-adjust: ${theme.fonts.primary.size.xl};`}
  }
`;

export const editDealershipFields = [
  {
    name: 'dealershipName',
    label: 'Dealership name',
    placeholder: 'Dealership Name',
    type: InputType.Text,
    Control: StyledInput,
    FormControl: StyledFormControl,
  },
  {
    name: 'address',
    label: 'Address',
    placeholder: 'Enter your address',
    type: InputType.Text,
    Control: StyledInput,
    FormControl: StyledFormControl,
  },
  {
    name: 'oems',
    label: 'OEMs',
    placeholder: 'OEMs',
    FormControl: SelectFormControl,
    isMulti: true,
    canSelectAll: true,
  },
  {
    name: 'dealershipImage',
    label: 'Dealership image (optional)',
    FormControl: FileFormControl,
    Icon: FileImageIcon,
    deletable: true,
    fileTypes: ['JPEG', 'JPG', 'PNG'],
  },
  {
    name: 'dealerContract',
    label: 'Dealer contract',
    FormControl: FileFormControl,
    deletable: true,
    fileTypes: ['PDF'],
  },
];

export const validationSchema = yup.object().shape({
  dealershipName: yup.string().required('Dealership Name is required'),
  address: yup.string().required('Address is required'),
  oems: yup.array().min(1, 'Oems are required.').required('Oems are required.').nullable(),
  dealerContract: yup.mixed().required('Contract is required'),
});
