import React from 'react';
import PropTypes from 'prop-types';

const FileDocumentIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 16.0005H11C11.2167 16.0005 11.396 15.9298 11.538 15.7885C11.6793 15.6465 11.75 15.4672 11.75 15.2505C11.75 15.0338 11.6793 14.8545 11.538 14.7125C11.396 14.5712 11.2167 14.5005 11 14.5005H5C4.78333 14.5005 4.60433 14.5712 4.463 14.7125C4.321 14.8545 4.25 15.0338 4.25 15.2505C4.25 15.4672 4.321 15.6465 4.463 15.7885C4.60433 15.9298 4.78333 16.0005 5 16.0005ZM5 12.0005H11C11.2167 12.0005 11.396 11.9295 11.538 11.7875C11.6793 11.6462 11.75 11.4672 11.75 11.2505C11.75 11.0338 11.6793 10.8545 11.538 10.7125C11.396 10.5712 11.2167 10.5005 11 10.5005H5C4.78333 10.5005 4.60433 10.5712 4.463 10.7125C4.321 10.8545 4.25 11.0338 4.25 11.2505C4.25 11.4672 4.321 11.6462 4.463 11.7875C4.60433 11.9295 4.78333 12.0005 5 12.0005ZM2.3 19.7505C1.8 19.7505 1.375 19.5755 1.025 19.2255C0.675 18.8755 0.5 18.4505 0.5 17.9505V2.55049C0.5 2.05049 0.675 1.62549 1.025 1.27549C1.375 0.925488 1.8 0.750488 2.3 0.750488H9.5C9.75 0.750488 9.98333 0.796155 10.2 0.887488C10.4167 0.979488 10.6083 1.10882 10.775 1.27549L14.975 5.47549C15.1417 5.64215 15.271 5.83382 15.363 6.05049C15.4543 6.26716 15.5 6.50049 15.5 6.75049V17.9505C15.5 18.4505 15.325 18.8755 14.975 19.2255C14.625 19.5755 14.2 19.7505 13.7 19.7505H2.3ZM9.5 5.85049V2.25049H2.3C2.23333 2.25049 2.16667 2.28382 2.1 2.35049C2.03333 2.41715 2 2.48382 2 2.55049V17.9505C2 18.0172 2.03333 18.0838 2.1 18.1505C2.16667 18.2172 2.23333 18.2505 2.3 18.2505H13.7C13.7667 18.2505 13.8333 18.2172 13.9 18.1505C13.9667 18.0838 14 18.0172 14 17.9505V6.75049H10.4C10.15 6.75049 9.93733 6.66282 9.762 6.48749C9.58733 6.31282 9.5 6.10049 9.5 5.85049Z"
      fill={color}
    />
  </svg>
);

FileDocumentIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

FileDocumentIcon.defaultProps = {
  color: '#212121',
  width: 16,
  height: 20,
};

export default FileDocumentIcon;
