import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';

const Form = ({
  children,
  useFormProps,
  onSubmit,
  resetValues,
  resetable,
  loading,
  success,
  setFormState,
  ...props
}) => {
  const formMethods = useForm(useFormProps);
  const {
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, isValid },
  } = formMethods;

  useEffect(() => {
    if (setFormState) {
      setFormState({ isValid });
    }
  }, [isValid, setFormState]);

  useEffect(() => {
    if (resetable && !loading && (isSubmitSuccessful || success)) {
      reset(resetValues);
    }
  }, [reset, loading, resetable, resetValues, success, isSubmitSuccessful]);

  return (
    <FormProvider {...formMethods}>
      <form {...props} onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

Form.defaultProps = {
  useFormProps: {},
  resetValues: {},
  resetable: false,
  className: '',
  loading: false,
  success: false,
  setFormState: null,
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  useFormProps: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  resetValues: PropTypes.shape({}),
  resetable: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  setFormState: PropTypes.func,
};

export default Form;
