import React, { useMemo, useCallback, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { selectAuthData } from 'store/selectors/auth';
import { clearBlockUserState } from 'store/slices/usersSlice';
import { blockUserAction, getUserDataAction, getUsersAction } from 'store/asyncActions';
import { selectBlockUserState } from 'store/selectors/users';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';
import Title, { TitleKind } from 'components/common/Title';
import { BigWarningIcon, DefaultUserIcon, LoadingAnimatedIcon } from 'icons';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  gap: 2rem;
  margin-top: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      background-color: ${theme.colors.white};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        width: 50vw;
      }

      @media screen and (min-width: ${theme.mediaQueries.largerTabet}) {
        width: 35vw;
      }
    `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    flex-direction: row;
  }
`;

const BlockUserForm = ({ toggle, userData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { blockUserLoading, blockUserSuccess, blockUserError } = useSelector(selectBlockUserState, shallowEqual);
  const { userId } = useSelector(selectAuthData, shallowEqual);

  const { icon, firstName, lastName, id } = userData;

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
    }),
    []
  );

  const onSubmit = useCallback(() => {
    dispatch(blockUserAction(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (blockUserSuccess) {
      dispatch(getUsersAction());
      dispatch(getUserDataAction(userId));
      toggle();
    }
  }, [dispatch, toggle, blockUserSuccess, userId]);

  useEffect(
    () => () => {
      dispatch(clearBlockUserState());
      toast.clearWaitingQueue();
    },
    [dispatch]
  );

  useEffect(() => {
    if (blockUserError?.message) {
      toast.error(blockUserError.message);
    }
  }, [blockUserError?.message]);

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <BigWarningIcon />
        <Title
          css={css`
            font-weight: 500;
            color: ${theme.colors.black};
            margin: 2rem 0;
          `}
          kind={TitleKind.h3}
        >
          Are you sure you want to block this user?
        </Title>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.75rem;
            font-weight: 500;
          `}
        >
          {icon ? (
            <img
              src={icon}
              alt="UserIcon"
              css={css`
                width: 3.5rem;
                height: 3.5rem;
              `}
            />
          ) : (
            <DefaultUserIcon height="3.5rem" width="3.5rem" />
          )}
          {`${firstName} ${lastName}`}
        </div>
      </div>
      <ButtonsContainer>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
          `}
          disabled={blockUserLoading}
          kind={ButtonKind.Secondary}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          disabled={blockUserLoading}
          type={ButtonType.Submit}
        >
          {blockUserLoading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Block User'
          )}
        </Button>
      </ButtonsContainer>
    </StyledForm>
  );
};

BlockUserForm.propTypes = {
  toggle: PropTypes.func,
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    icon: PropTypes.string,
    Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
  }).isRequired,
};

BlockUserForm.defaultProps = {
  toggle: null,
};

export default BlockUserForm;
