import React from 'react';
import PropTypes from 'prop-types';

const BuildAndCarIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 20C8.71667 20 8.47933 19.904 8.288 19.712C8.096 19.5207 8 19.2833 8 19V13.775C8 13.7417 8.01667 13.6583 8.05 13.525L9.4 9.65C9.48333 9.46667 9.604 9.31267 9.762 9.188C9.92067 9.06267 10.1167 9 10.35 9H17.65C17.8833 9 18.0793 9.06267 18.238 9.188C18.396 9.31267 18.5167 9.46667 18.6 9.65L19.95 13.525L20 13.775V19C20 19.2833 19.904 19.5207 19.712 19.712C19.5207 19.904 19.2833 20 19 20C18.7167 20 18.4793 19.904 18.288 19.712C18.096 19.5207 18 19.2833 18 19V18.5H10V19C10 19.2833 9.90433 19.5207 9.713 19.712C9.521 19.904 9.28333 20 9 20ZM10 12.5H18L17.3 10.5H10.7L10 12.5ZM11 16.5C11.2833 16.5 11.521 16.404 11.713 16.212C11.9043 16.0207 12 15.7833 12 15.5C12 15.2167 11.9043 14.9793 11.713 14.788C11.521 14.596 11.2833 14.5 11 14.5C10.7167 14.5 10.4793 14.596 10.288 14.788C10.096 14.9793 10 15.2167 10 15.5C10 15.7833 10.096 16.0207 10.288 16.212C10.4793 16.404 10.7167 16.5 11 16.5ZM17 16.5C17.2833 16.5 17.5207 16.404 17.712 16.212C17.904 16.0207 18 15.7833 18 15.5C18 15.2167 17.904 14.9793 17.712 14.788C17.5207 14.596 17.2833 14.5 17 14.5C16.7167 14.5 16.4793 14.596 16.288 14.788C16.096 14.9793 16 15.2167 16 15.5C16 15.7833 16.096 16.0207 16.288 16.212C16.4793 16.404 16.7167 16.5 17 16.5ZM4 12V10H6V12H4ZM9 6V4H11V6H9ZM4 16V14H6V16H4ZM4 20V18H6V20H4ZM0 20V8C0 7.45 0.196 6.979 0.588 6.587C0.979333 6.19567 1.45 6 2 6H5V2C5 1.45 5.196 0.979 5.588 0.587C5.97933 0.195667 6.45 0 7 0H13C13.55 0 14.021 0.195667 14.413 0.587C14.8043 0.979 15 1.45 15 2V7H13V2H7V8H2V20H0ZM9.5 17H18.5V14H9.5V17Z"
      fill={color}
    />
  </svg>
);

BuildAndCarIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

BuildAndCarIcon.defaultProps = {
  color: '#212121',
  width: 20,
  height: 20,
};

export default BuildAndCarIcon;
