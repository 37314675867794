import React from 'react';
import PropTypes from 'prop-types';

const CrossIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3002 0.710215C12.9102 0.320215 12.2802 0.320215 11.8902 0.710215L7.00022 5.59022L2.11022 0.700215C1.72022 0.310215 1.09021 0.310215 0.700215 0.700215C0.310215 1.09021 0.310215 1.72022 0.700215 2.11022L5.59022 7.00022L0.700215 11.8902C0.310215 12.2802 0.310215 12.9102 0.700215 13.3002C1.09021 13.6902 1.72022 13.6902 2.11022 13.3002L7.00022 8.41021L11.8902 13.3002C12.2802 13.6902 12.9102 13.6902 13.3002 13.3002C13.6902 12.9102 13.6902 12.2802 13.3002 11.8902L8.41021 7.00022L13.3002 2.11022C13.6802 1.73022 13.6802 1.09022 13.3002 0.710215Z"
      fill={color}
    />
  </svg>
);

CrossIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CrossIcon.defaultProps = {
  color: '#212121',
  width: 14,
  height: 14,
};

export default CrossIcon;
