import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFilters } from 'react-table';
import styled, { css, useTheme } from 'styled-components';

import { ArrowIcon, LoadingAnimatedIcon } from 'icons';

const TContainerDefaultComponent = styled.table`
  width: 100%;
  border-spacing: 0;
  ${({ theme }) => css`
    color: ${theme.colors.darkerGray};
    font-family: ${theme.fonts.primary.family};
  `};
`;

const THeadDefaultComponent = styled.thead`
  ${({ theme }) => css`
    font-size: ${theme.fonts.primary.size.xs};
    background-color: ${theme.colors.lighterGray};
    height: 2.75rem;
  `}
`;

const TBodyDefaultComponent = styled.tbody`
  font-size: ${({ theme }) => theme.fonts.primary.size.sm};
`;

const TRheadDefaultComponent = styled.tr`
  ${({ theme }) => css`
    background-color: ${theme.colors.lighterGray};
  `}
`;

const TRbodyDefaultComponent = styled.tr`
  height: 4.5rem;
`;

const TDDefaultComponent = styled.td`
  text-align: start;
  height: 4.5rem;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.lightGray};
  `}

  :first-child {
    padding-left: 3.75rem;
  }

  :last-child {
    padding-right: 3.75rem;
  }
`;

const THDefaultComponent = styled.th`
  text-align: start;
  font-weight: 400;

  ${({ theme, sortable }) => css`
    background-color: ${theme.colors.lighterGray};
    border-top: 1px solid ${theme.colors.lightGray};
    border-bottom: 1px solid ${theme.colors.lightGray};

    ${sortable &&
    css`
      cursor: pointer;
    `}
  `}

  :first-child {
    padding-left: 3.75rem;
  }

  :last-child {
    padding-right: 3.75rem;
    width: 0;
  }
`;

/* eslint-disable react/prop-types */
const Arrow = ({ customCss, rotate, color }) => (
  <span
    css={css`
      display: flex;
      ${rotate && `transform: rotate(180deg);`}
      ${customCss}
    `}
  >
    <ArrowIcon color={color} />
  </span>
);

const SortArrows = ({ isSorted, isSortedDesc }) => {
  const theme = useTheme();
  const arrowsColor = theme.colors.darkerGray;

  if (isSorted) {
    if (isSortedDesc) {
      return (
        <Arrow
          customCss={css`
            margin-top: auto;
          `}
          color={arrowsColor}
        />
      );
    }

    return (
      <Arrow
        customCss={css`
          margin-bottom: auto;
        `}
        color={arrowsColor}
        rotate
      />
    );
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.18rem;
      `}
    >
      <Arrow rotate color={arrowsColor} />
      <Arrow color={arrowsColor} />
    </div>
  );
};

const HeaderEnhancer = ({ sortable, isSorted, isSortedDesc }) => {
  if (sortable) {
    return <SortArrows isSorted={isSorted} isSortedDesc={isSortedDesc} />;
  }

  return null;
};
/* eslint-enable react/prop-types */

const Table = ({ columns, data, TContainer, THead, TBody, TRbody, TRhead, TD, TH, triggerFilters, loading }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, setFilter } = useTable(
    {
      columns,
      data,
      autoResetHiddenColumns: false,
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    triggerFilters.forEach(({ column, value }) => {
      if (column) {
        setFilter(column, value);
      }
    });
  }, [setFilter, triggerFilters]);

  return (
    <>
      <TContainer {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <TRhead {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const { sortable } = column;

                const headerProps = [...(sortable ? [column.getSortByToggleProps()] : [])];

                return (
                  <TH sortable={sortable} {...column.getHeaderProps(headerProps)} style={column.style}>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                      `}
                    >
                      {column.render('Header')}
                      <HeaderEnhancer
                        sortable={sortable}
                        isSortedDesc={column.isSortedDesc}
                        isSorted={column.isSorted}
                      />
                    </div>
                  </TH>
                );
              })}
            </TRhead>
          ))}
        </THead>
        {!loading && (
          <TBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <TRbody {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                  ))}
                </TRbody>
              );
            })}
          </TBody>
        )}
      </TContainer>
      {loading && (
        <span
          css={css`
            display: flex;
            margin: 0.5rem auto;
            height: 2rem;
            width: 2rem;
          `}
        >
          <LoadingAnimatedIcon />
        </span>
      )}
      {!rows.length && !loading && (
        <p
          css={css`
            margin: 1rem 0;
            width: 100%;
            text-align: center;
            ${({ theme }) => css`
              color: ${theme.colors.darkerGray};
              font-family: ${theme.fonts.primary.family};
            `};
          `}
        >
          Not Available Results
        </p>
      )}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      Cell: PropTypes.func,
      sortType: PropTypes.string,
      id: PropTypes.string,
      disableSortBy: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paginationConfig: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  TContainer: PropTypes.elementType,
  THead: PropTypes.elementType,
  TBody: PropTypes.elementType,
  TRhead: PropTypes.elementType,
  TRbody: PropTypes.elementType,
  TD: PropTypes.elementType,
  TH: PropTypes.elementType,
  loading: PropTypes.bool,
  triggerFilters: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    })
  ),
};

Table.defaultProps = {
  TContainer: (props) => <TContainerDefaultComponent {...props} />,
  THead: (props) => <THeadDefaultComponent {...props} />,
  TBody: (props) => <TBodyDefaultComponent {...props} />,
  TRhead: (props) => <TRheadDefaultComponent {...props} />,
  TRbody: (props) => <TRbodyDefaultComponent {...props} />,
  TD: (props) => <TDDefaultComponent {...props} />,
  TH: (props) => <THDefaultComponent {...props} />,
  paginationConfig: { pageSize: 10 },
  loading: false,
  triggerFilters: [],
};

export default Table;
