import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import useModal from 'hooks/useModal';
import Modal from 'components/common/Modals/Modal';
import DeleteDealershipForm from 'components/Forms/DeleteDealershipForm';
import Menu from 'components/common/Menu';
import EditDealershipForm from 'components/Forms/EditDealershipForm';
import { EditIcon, EllipsisIcon, TrashIcon } from 'icons';

const ActionButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  border: none;
  background: none;

  :hover {
    cursor: pointer;
  }

  :active {
    filter: brightness(0);
  }
`;

const MenuItem = styled.span`
  display: flex;
  gap: 0.75rem;
  align-items: baseline;
  padding: 1rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const MenuIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;

/* eslint-disable react/prop-types */
const MenuIcon = ({ active }) => {
  const theme = useTheme();

  return (
    <span
      css={css`
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        ${active &&
        css`
          background-color: ${theme.colors.lightGray};
        `}
      `}
    >
      <EllipsisIcon color={theme.colors.darkerGray} />
    </span>
  );
};
/* eslint-enable react/prop-types */

const DealershipActions = ({ row: { original }, dealershipData }) => {
  const theme = useTheme();
  const { isOpen, toggle } = useModal();

  const {
    id,
    logo,
    name,
    address,
    oems,
    dealershipImage,
    dealershipContract: dealerContract,
  } = original || dealershipData;

  const [{ Content, title }, setActionModal] = useState({ Content: null, title: null });

  const actionsDealershipData = { id, logo, name, address, oems, dealershipImage, dealerContract };

  const onClickEditAction = () => {
    setActionModal((prevState) => ({
      ...prevState,
      Content: <EditDealershipForm dealershipData={actionsDealershipData} toggle={toggle} />,
      title: 'Dealership details',
    }));
    toggle();
  };

  const onClickDeleteAction = () => {
    setActionModal((prevState) => ({
      ...prevState,
      Content: <DeleteDealershipForm dealershipData={actionsDealershipData} toggle={toggle} />,
      title: null,
    }));
    toggle();
  };

  const mobileActionsMenuItems = [
    {
      key: `delete-${id}${logo}${name}`,
      component: (
        <MenuItem>
          <MenuIconContainer>
            <TrashIcon color={theme.colors.mediumGray} />
          </MenuIconContainer>
          Delete
        </MenuItem>
      ),
      onClick: onClickDeleteAction,
    },
    {
      key: `edit-${id}${logo}${name}`,
      component: (
        <MenuItem
          css={css`
            border-top: 1px solid ${theme.colors.lightGray};
          `}
        >
          <MenuIconContainer>
            <EditIcon color={theme.colors.mediumGray} />
          </MenuIconContainer>
          Edit
        </MenuItem>
      ),
      onClick: onClickEditAction,
    },
  ];

  const jsxToReturn = original ? (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <ActionButton onClick={onClickDeleteAction}>
        <TrashIcon color={theme.colors.darkerGray} />
      </ActionButton>
      <ActionButton
        css={css`
          margin-left: 1.75rem;
        `}
        onClick={onClickEditAction}
      >
        <EditIcon color={theme.colors.darkerGray} />
      </ActionButton>
    </div>
  ) : (
    <Menu
      Icon={MenuIcon}
      items={mobileActionsMenuItems}
      styledMenuItemsStyles={css`
        min-width: 7.375rem;
        z-index: ${theme.zIndex.higher};
      `}
    />
  );

  return (
    <>
      {jsxToReturn}
      <Modal title={title} isOpen={isOpen} toggle={toggle}>
        {Content}
      </Modal>
    </>
  );
};

DealershipActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.string,
    }),
  }),
  dealershipData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
  }),
};

DealershipActions.defaultProps = {
  row: { original: null },
  dealershipData: {},
};

export default DealershipActions;
