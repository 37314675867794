import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = ({ auth }) => auth;

export const selectAuthData = createSelector(selectAuthState, (auth = {}) => ({
  ...auth,
  isLogged: !!auth?.uid,
  userId: auth?.uid,
}));

export const selectActivateUserAccountState = createSelector(
  selectAuthState,
  ({ activateUserAccountLoading, activateUserAccountSuccess, activateUserAccountFailed }) => ({
    activateUserAccountLoading,
    activateUserAccountSuccess,
    activateUserAccountFailed,
  })
);
