import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const ReactPortal = ({ children, wrapperId }) => {
  const [wrapper, setWrapper] = useState(null);

  useEffect(() => {
    let element = document.getElementById(wrapperId);
    let created = false;

    if (!element) {
      created = true;

      const portalWrapper = document.createElement('div');

      portalWrapper.setAttribute('id', wrapperId);
      document.body.appendChild(portalWrapper);
      element = portalWrapper;
    }

    setWrapper(element);

    return () => {
      if (created && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapper === null) return null;

  return createPortal(children, wrapper);
};

ReactPortal.propTypes = {
  children: PropTypes.node,
  wrapperId: PropTypes.string,
};

ReactPortal.defaultProps = {
  children: null,
  wrapperId: 'porta-wrapper-id',
};

export default ReactPortal;
