import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Paths from 'utils/constants/paths';
import { getUserDataAction } from 'store/asyncActions';
import { selectAuthData } from 'store/selectors/auth';
import { userRoles } from 'constants/users';
import { clearOemsState } from 'store/slices/oemsSlice';
import { clearAuthState } from 'store/slices/authSlice';
import { clearUsersState } from 'store/slices/usersSlice';
import { clearDealershipsState } from 'store/slices/dealershipsSlice';

const withPrivateRoute = (Component) => {
  const PrivateComponent = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLogged, role, userId } = useSelector(selectAuthData, shallowEqual);

    useEffect(() => {
      dispatch(getUserDataAction(userId));
    }, [dispatch, userId]);

    useEffect(() => {
      if (!isLogged || role !== userRoles.admin) {
        navigate(Paths.Login);
        dispatch(clearOemsState());
        dispatch(clearAuthState());
        dispatch(clearUsersState());
        dispatch(clearDealershipsState());
      }
    }, [isLogged, role, navigate, dispatch]);

    return <Component {...props} />;
  };

  if (Component.getInitialProps) {
    PrivateComponent.getInitialProps = Component.getInitialProps;
  }

  return PrivateComponent;
};

export default withPrivateRoute;
