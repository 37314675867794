import { Collections } from 'enums/collections';
import { getDocument } from 'services/firebase/db';

const getUserData = async (uid) => {
  const userData = await getDocument(Collections.Users, uid);

  return userData;
};

export default getUserData;
