import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styled, { css, useTheme } from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/common/ErrorMessage';
import Label from 'components/common/Label';
import Button, { ButtonKind, ButtonSize } from 'components/common/Button';
import { CrossIcon, FileDocumentIcon } from 'icons';

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .container {
    padding: 2.5rem 0 2rem 0;
    height: 5.25rem;
    width: 100%;
  }
`;

const UploadFileContainer = styled.div`
  width: 100%;
  height: 5.25rem;
  padding: 0.375rem 0.75rem;
  border: 1px dashed ${({ theme }) => theme.colors.darkerGray};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background-color: ${({ theme }) => theme.colors.lighterGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ fileIsSelected }) =>
    !fileIsSelected &&
    css`
      cursor: pointer;
    `}
`;

const StyledLabel = styled.label`
  font-weight: 200;

  ${({ theme, error }) =>
    css`
      font-size: ${theme.fonts.primary.size.md};
      ${error &&
      css`
        color: ${theme.colors.error};
      `}
    `};
`;

const FileFormControl = ({ name, fileTypes, Icon, label, deletable, defaultValue, setFieldsToUpdate }) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
  } = methods;

  const error = errors?.[name];

  useEffect(() => {
    if (defaultValue) {
      setFile(defaultValue);
    }
  }, [defaultValue, setFile]);

  const onClickDelete = () => setFile(null);

  return (
    <InputWrapper>
      {label && (
        <StyledLabel htmlFor={name} error={error}>
          {label}
        </StyledLabel>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, ref } }) =>
          file?.name ? (
            <UploadFileContainer fileIsSelected>
              <span
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                `}
              >
                <Label>{file.name}</Label>
                {deletable && (
                  <Button
                    css={css`
                      padding: 0;
                      border: none;
                    `}
                    kind={ButtonKind.Ghost}
                    size={ButtonSize.Small}
                    onClick={() => {
                      onClickDelete();
                      onChange(null);
                      setFieldsToUpdate((prevState) => ({ ...prevState, [name]: true }));
                    }}
                  >
                    <CrossIcon color={theme.colors.darkerGray} />
                  </Button>
                )}
              </span>
            </UploadFileContainer>
          ) : (
            <FileUploader
              handleChange={(value) => {
                onChange(value);
                setFile(value);
                setFieldsToUpdate((prevState) => ({ ...prevState, [name]: true }));
              }}
              types={fileTypes}
              ref={ref}
              className="container"
            >
              <UploadFileContainer>
                <Icon color={theme.colors.mediumGray} />
                <Label
                  css={css`
                    cursor: pointer;
                  `}
                >
                  Drag your file here, or&nbsp;
                  <span
                    css={css`
                      color: ${theme.colors.primary};
                    `}
                  >
                    browse
                  </span>
                </Label>
              </UploadFileContainer>
              {error?.message && <ErrorMessage>{error?.message?.toString()}</ErrorMessage>}
            </FileUploader>
          )
        }
      />
    </InputWrapper>
  );
};

FileFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  Icon: PropTypes.func,
  label: PropTypes.string,
  deletable: PropTypes.bool,
  defaultValue: PropTypes.string,
  setFieldsToUpdate: PropTypes.func,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  customErrors: PropTypes.shape({}),
  conditionToShow: PropTypes.shape({ field: PropTypes.string, isEqualTo: PropTypes.string }),
};

FileFormControl.defaultProps = {
  customErrors: {},
  conditionToShow: null,
  deletable: false,
  label: null,
  defaultValue: null,
  Icon: FileDocumentIcon,
  setFieldsToUpdate: null,
  fileTypes: ['PDF'],
};

export default FileFormControl;
