import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/common/Button';
import { ArrowWithVector } from 'icons';
import useCustomMediaQuery from 'hooks/useMediaQuery';

const StyledPaginationButton = styled(Button)`
  background: none;
  border: none;
  height: 2.75rem;
  max-width: 2.75rem;
  padding: 1rem;
  white-space: nowrap;

  ${({ theme }) =>
    css`
      max-width: fit-content;
      border: 1px solid ${theme.colors.mediumGray};
      border-radius: ${theme.border.radius.sm};
      color: ${theme.colors.black};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        padding: 1rem 1.625rem;
      }
    `};
`;

const StyledPagesButton = styled(Button)`
  background: none;
  border: none;
  height: 2.75rem;
  width: 2.75rem;

  ${({ theme, active }) =>
    active &&
    css`
      background-color: ${theme.colors.salmonLight};
    `};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  color: ${({ theme }) => theme.colors.black};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.813rem 1.25rem;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.mediaQueries.tablet}) {
      padding: 0.813rem 3.75rem;
    }
  `}
`;

const InnerContainer = styled.div`
  width: fit-content;
  max-width: 35%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.mediaQueries.tablet}) {
      justify-content: flex-start;
    }
  `}
`;

// eslint-disable-next-line react/prop-types
const Arrow = ({ rotate }) => (
  <span
    css={css`
      display: flex;
      ${rotate && `transform: rotate(180deg);`}
    `}
  >
    <ArrowWithVector />
  </span>
);

const PaginationController = ({ setCurrentPage, currentPage, pages }) => {
  const theme = useTheme();
  const isTablet = useCustomMediaQuery(theme.mediaQueries.tablet);

  const pageHop = 1;
  const amountOfPages = pages.length;

  const canPreviousPage = currentPage > 0;
  const canNextPage = currentPage < amountOfPages - 1;

  const previousPageHandler = () => {
    if (canPreviousPage) {
      setCurrentPage((prevState) => prevState - pageHop);
    }
  };

  const nextPageHandler = () => {
    if (canNextPage) {
      setCurrentPage((prevState) => prevState + pageHop);
    }
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const pagesToRender = pages?.map((page, index) => (
    <StyledPagesButton active={currentPage === index} onClick={() => goToPage(index)} key={page[0]}>
      {index + 1}
    </StyledPagesButton>
  ));

  return (
    <Container>
      <StyledPaginationButton onClick={() => previousPageHandler()} disabled={!canPreviousPage}>
        <Arrow />
        {isTablet && 'Previous'}
      </StyledPaginationButton>
      <InnerContainer>{isTablet ? pagesToRender : `Page ${currentPage + 1} of ${amountOfPages}`}</InnerContainer>
      <StyledPaginationButton onClick={() => nextPageHandler()} disabled={!canNextPage}>
        {isTablet && 'Next'}
        <Arrow rotate />
      </StyledPaginationButton>
    </Container>
  );
};

PaginationController.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pages: PropTypes.array.isRequired,
};

export default PaginationController;
