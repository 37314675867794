import React, { useEffect, useMemo, useState } from 'react';
import { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getAllDealershipsAction } from 'store/asyncActions';
import {
  selectDealershipTableSearchState,
  selectFetchDealershipsState,
  selectDealershipsTableFiltersState,
} from 'store/selectors/dealerships';
import paginateArray from 'utils/paginateArray';
import sortTable from 'utils/Sorting/sortTable';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import Table from 'components/Tables/Table';
import Badge from 'components/common/Badge';
import DealershipCard from 'components/pages/Home/DealershipTab/DealershipCard';
import DealershipActions from 'components/pages/Home/DealershipTab/DealershipActions';
import PaginationController from 'components/common/PaginationController';
import { DefaultDealershipIcon, LoadingAnimatedIcon } from 'icons';

/* eslint-disable react/prop-types */
const OEMBadges = ({
  row: {
    original: { oems },
  },
}) => {
  const amountToShow = 3;
  const OEMSsToShow = oems.slice(0, amountToShow);
  const amountOfOEMsToHide = oems.length - amountToShow;

  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-size: ${({ theme }) => theme.fonts.primary.size.xs};
      `}
    >
      {OEMSsToShow.map((oem) => (
        <Badge key={oem}>{oem}</Badge>
      ))}
      {amountOfOEMsToHide > 0 && <Badge>+{amountOfOEMsToHide}</Badge>}
    </span>
  );
};

const DealershipName = ({
  row: {
    original: { Logo, name },
  },
}) => (
  <span
    css={css`
      display: flex;
      align-items: center;
      gap: 0.75rem;
      color: ${({ theme }) => theme.colors.black};
      font-weight: 500;
    `}
  >
    {Logo}
    {name}
  </span>
);

const checkSearchParamsValidity = ({ searchParams, dealership }) => {
  const { column, value: searchValue } = searchParams;

  const userParam = dealership?.[column] || '';

  const fulfillSearchParam =
    !column ||
    userParam?.toLowerCase()?.startsWith(searchValue?.toLowerCase()) ||
    userParam?.toLowerCase()?.includes(searchValue?.toLowerCase());

  return fulfillSearchParam;
};

const filterTableDealershipsData = ({ tableDealerships, selectedOems, mustIncludeAllOems, searchParams }) =>
  tableDealerships?.filter((dealership) => {
    const { oems } = dealership;
    const isValid = checkSearchParamsValidity({ searchParams, dealership });
    if (mustIncludeAllOems) {
      return isValid && !selectedOems?.some((selectedOem) => !oems.includes(selectedOem));
    }

    if (!isValid) {
      return false;
    }

    return !selectedOems?.length || selectedOems?.find((oem) => oems.includes(oem));
  }) || [];

const DealershipsMobileList = ({ page, loading }) => {
  const theme = useTheme();

  if (loading) {
    return (
      <span
        css={css`
          display: flex;
          margin: 0.5rem auto;
          height: 2rem;
          width: 2rem;
        `}
      >
        <LoadingAnimatedIcon />
      </span>
    );
  }

  return page.length ? (
    page.map(({ id, logo, Logo, name, address, oems, dealershipImage, dealershipContract }) => (
      <DealershipCard
        id={id}
        dealershipLogo={logo}
        Logo={Logo}
        name={name}
        address={address}
        oems={oems}
        dealershipImage={dealershipImage}
        dealershipContract={dealershipContract}
      />
    ))
  ) : (
    <p
      css={css`
        margin: 1rem 0;
        width: 100%;
        text-align: center;
        color: ${theme.colors.darkerGray};
        font-family: ${theme.fonts.primary.family};
      `}
    >
      Not Available Results
    </p>
  );
};

/* eslint-enable react/prop-types */

const DealershipsTable = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isDesktop = useCustomMediaQuery(theme.mediaQueries.largerTablet);

  const [currentPage, setCurrentPage] = useState(0);

  const { selectedOems, mustIncludeAllOems, sorting } = useSelector(selectDealershipsTableFiltersState, shallowEqual);
  const { dealershipSearchTable } = useSelector(selectDealershipTableSearchState, shallowEqual);
  const { dealerships, fetchDealershipLoading } = useSelector(selectFetchDealershipsState, shallowEqual);

  useEffect(() => {
    dispatch(getAllDealershipsAction());
  }, [dispatch]);

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: DealershipName,
        sortable: true,
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'OEMs',
        accessor: 'oems',
        Cell: OEMBadges,
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: DealershipActions,
      },
    ],
    []
  );

  const tableDealerships = useMemo(
    () =>
      dealerships?.map(({ id, image: logo, name, address, oems, imageName, contractName, contract }) => ({
        id,
        Logo: logo ? (
          <img
            css={css`
              height: 2.5rem;
              width: 2.5rem;
              border-radius: 50%;
              border: 1px solid ${theme.colors.lightGray};
            `}
            src={logo}
            alt="dealershipLogo"
          />
        ) : (
          <DefaultDealershipIcon />
        ),
        logo,
        name,
        address,
        oems,
        dealershipImage: {
          name: imageName,
          url: logo,
        },
        dealershipContract: { name: contractName, url: contract },
      })) || [],
    [dealerships, theme.colors.lightGray]
  );

  const sortedTableDealerships = useMemo(
    () => sortTable({ tableData: tableDealerships, paramToSort: 'name', sort: sorting }),
    [tableDealerships, sorting]
  );

  const paginationOffset = 10;
  const filteredDealershipsPages = useMemo(
    () =>
      (selectedOems?.length || dealershipSearchTable?.column || sorting
        ? paginateArray(
            filterTableDealershipsData({
              tableDealerships: sortedTableDealerships,
              selectedOems,
              mustIncludeAllOems,
              searchParams: dealershipSearchTable,
            }),
            paginationOffset
          ) || [[]]
        : paginateArray(sortedTableDealerships, paginationOffset)) || [[]],
    [selectedOems, dealershipSearchTable, sortedTableDealerships, mustIncludeAllOems, sorting]
  );

  return (
    <>
      {isDesktop ? (
        <Table columns={tableColumns} data={filteredDealershipsPages[currentPage]} loading={fetchDealershipLoading} />
      ) : (
        <DealershipsMobileList page={filteredDealershipsPages[currentPage]} loading={fetchDealershipLoading} />
      )}

      {!!filteredDealershipsPages[currentPage].length && !fetchDealershipLoading && (
        <PaginationController
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pages={filteredDealershipsPages}
        />
      )}
    </>
  );
};

export default DealershipsTable;
