import firebase from 'services/firebase';
import { Auth } from 'enums/cloudFunctions';

const createUser = ({ firstName, lastName, email, role, dealership, invitationMessage }) => {
  const httpsCreateUser = firebase.functions().httpsCallable(Auth.CreateUser);

  return httpsCreateUser({ firstName, lastName, email, role, dealership, invitationMessage });
};

export default createUser;
