import React from 'react';
import PropTypes from 'prop-types';

const PersonIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM1 16C0.716667 16 0.479333 15.904 0.288 15.712C0.096 15.5207 0 15.2833 0 15V13.2C0 12.6333 0.146 12.1123 0.438 11.637C0.729333 11.1623 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64567 4.75 9.387C5.81667 9.129 6.9 9 8 9C9.1 9 10.1833 9.129 11.25 9.387C12.3167 9.64567 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2707 11.1623 15.562 11.637C15.854 12.1123 16 12.6333 16 13.2V15C16 15.2833 15.904 15.5207 15.712 15.712C15.5207 15.904 15.2833 16 15 16H1Z"
      fill={color}
    />
  </svg>
);

PersonIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

PersonIcon.defaultProps = {
  color: '#212121',
  width: 16,
  height: 16,
};

export default PersonIcon;
