import React, { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';

import withLayout from 'hocs/withLayout';
import withPrivateRoute from 'hocs/auth/withPrivateRoute';
import UsersTable from 'components/Tables/UsersTable';
import Badge from 'components/common/Badge';
import Tabs from 'components/common/Tabs';
import DealershipsTable from 'components/Tables/DealershipsTable';

import { PersonVectorIcon, PersonIcon, BuildAndCarIcon } from 'icons';
import { useDispatch } from 'react-redux';
import { clearUsersState } from 'store/slices/usersSlice';
import TabsMiddleContent from 'components/pages/Home/MiddleContent/TabsMiddleContent';

const StyledMainContainer = styled.main`
  min-height: 57vh;

  ${({ theme }) => css`
    font-family: ${theme.fonts.primary.family};
    font-size: 3vw;

    @media screen and (min-width: ${theme.mediaQueries.smallMobile}) {
      font-size: ${theme.fonts.primary.size.sm};
    }

    @media screen and (min-width: ${theme.mediaQueries.mobile}) {
      font-size: ${theme.fonts.primary.size.md};
    }
  `}
`;

const StyledBadge = styled(Badge)`
  white-space: nowrap;
  border-radius: 100px;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1.25rem 1.25rem;
  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    padding: 1.25rem 3.75rem;
  }
`;

/* eslint-disable react/prop-types */
const UserListTab = ({ active }) => {
  const theme = useTheme();

  const textColor = active ? theme.colors.black : theme.colors.darkerGray;
  const bgColor = active ? theme.colors.lightGray : 'none';

  return (
    <StyledBadge textColor={textColor} bgColor={bgColor} padding="0.75rem 1.5rem">
      {active ? <PersonIcon /> : <PersonVectorIcon color={theme.colors.darkerGray} />}
      User List
    </StyledBadge>
  );
};

const DealershipListTab = ({ active }) => {
  const theme = useTheme();

  const textColor = active ? theme.colors.black : theme.colors.darkerGray;
  const bgColor = active ? theme.colors.lightGray : 'none';

  return (
    <StyledBadge textColor={textColor} bgColor={bgColor} padding="0.75rem 1.5rem">
      <BuildAndCarIcon color={textColor} />
      Dealership list
    </StyledBadge>
  );
};
/* eslint-enable react/prop-types */

const Home = () => {
  const dispatch = useDispatch();

  const tabs = [UserListTab, DealershipListTab];
  const panels = [UsersTable, DealershipsTable];

  useEffect(() => () => dispatch(clearUsersState()), [dispatch]);

  return (
    <StyledMainContainer>
      <Tabs MiddleContent={TabsMiddleContent} TabsWrapper={TabsWrapper} tabs={tabs} panels={panels} />
    </StyledMainContainer>
  );
};

export default withPrivateRoute(withLayout(Home));
