import React, { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { activateUserAccountAction, getUserByTokenAction } from 'store/asyncActions';
import { selectAuthData, selectActivateUserAccountState } from 'store/selectors/auth';
import { clearActivateUserAccountState } from 'store/slices/authSlice';
import { selectUserDataState } from 'store/selectors/users';
import { activateUserAccountFields, validationSchema } from 'utils/formFields/activateUserAccountFields';
import Paths from 'utils/constants/paths';
import Button, { ButtonType } from 'components/common/Button';
import Title from 'components/common/Title';
import Form from 'components/Forms/Form';
import { LoadingAnimatedIcon, LoginStarsIcon } from 'icons';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35.625rem;
  padding: 2rem 1.5rem;
  box-shadow: 0px 4px 32px 7px rgba(0, 0, 0, 0.04);
  gap: 2rem;
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};
      border-radius: ${theme.border.radius.xl};
    `};
`;

const ActivateUserAccountForm = ({ confirmToken }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({});

  const { error } = useSelector(selectAuthData, shallowEqual);
  const userData = useSelector(selectUserDataState, shallowEqual);
  const { activateUserAccountLoading, activateUserAccountSuccess } = useSelector(
    selectActivateUserAccountState,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getUserByTokenAction(confirmToken));
  }, [dispatch, confirmToken]);

  useEffect(() => {
    if (activateUserAccountSuccess) {
      navigate(Paths.Login);
    }
  }, [activateUserAccountSuccess, navigate]);

  useEffect(() => () => dispatch(clearActivateUserAccountState), [dispatch]);

  const useFormProps = useMemo(
    () => ({
      mode: 'all',
      resolver: yupResolver(validationSchema),
    }),
    []
  );

  const onSubmit = ({ firstName, lastName, password }) =>
    dispatch(activateUserAccountAction({ firstName, lastName, password, token: confirmToken }));

  const { data } = userData || { data: {} };

  return (
    <StyledForm setFormState={setFormState} useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
          `}
        >
          <Title
            css={css`
              margin: 0;
              color: ${theme.colors.black};
            `}
          >
            Hi {data?.firstName}!
            <br />
            Welcome aboard
          </Title>
          <LoginStarsIcon />
        </div>
        <span css="font-weight: 200;">Please create a password to activate your account</span>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        {activateUserAccountFields.map(({ name, FormControl, ...field }) => (
          <FormControl
            key={name}
            inputContainerCss={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            `}
            submitError={!!error}
            name={name}
            defaultValue={data?.[name]}
            {...field}
          />
        ))}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          gap: 1.5rem;
        `}
      >
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          type={ButtonType.Submit}
          disabled={!formState?.isValid}
        >
          {activateUserAccountLoading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Activate account'
          )}
        </Button>
      </div>
    </StyledForm>
  );
};

ActivateUserAccountForm.propTypes = {
  confirmToken: PropTypes.string,
};

ActivateUserAccountForm.defaultProps = {
  confirmToken: null,
};

export default ActivateUserAccountForm;
