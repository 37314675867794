import { Collections } from 'enums/collections';
import { getDocuments, getFirestoreRef } from 'services/firebase/db';

const getUsers = async () => {
  const usersCollection = getFirestoreRef(Collections.Users);

  const users = await getDocuments(usersCollection);

  return users;
};

export default getUsers;
