const ToastContainerPosition = {
  TopLeft: 'top-left',

  TopRight: 'top-right',

  TopCenter: 'top-center',

  BottomLeft: 'bottom-left',

  BottomRight: 'bottom-right',

  BottomCenter: 'bottom-center',
};

export default ToastContainerPosition;
