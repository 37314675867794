import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const LabelKind = {
  Normal: 'normal',
  Error: 'error',
  Warning: 'warning',
};

const KindStyles = {
  [LabelKind.Normal]: css`
    ${({ theme }) => `color: ${theme.colors.text};`}
  `,
  [LabelKind.Error]: css`
    ${({ theme }) => `color: ${theme.colors.error};`}
  `,
  [LabelKind.Warning]: css`
    ${({ theme }) => `color: ${theme.colors.warning};`}
  `,
};

const StyledLabel = styled.label`
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: 0.875rem;
  line-height: 1.25rem;
  ${({ kind }) => KindStyles[kind]}
`;

const Label = (props) => <StyledLabel {...props} />;

Label.propTypes = {
  children: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(Object.values(LabelKind)),
};

Label.defaultProps = {
  kind: LabelKind.Normal,
};

export default Label;
