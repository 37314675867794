import firebase from 'services/firebase';
import { Users } from 'enums/cloudFunctions';

const editUser = ({ uid, firstName, lastName, email, dealership }) => {
  const httpsEditUser = firebase.functions().httpsCallable(Users.EditUser);

  return httpsEditUser({ uid, firstName, lastName, email, dealership });
};

export default editUser;
