import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FocusTrap } from '@headlessui/react';

import ReactPortal from 'components/common/ReactPortal';
import Title, { TitleKind, TitleSize } from 'components/common/Title';

const BackDrop = styled.div`
  ${({ theme }) =>
    css`
      background-color: ${theme.colors.mediumBlack};
      z-index: ${theme.zIndex.modalBackdrop};
    `};
  opacity: 0.2;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
  padding: 2rem 1.125rem;
  overflow-y: scroll;
  max-height: 70vh;

  ${({ theme }) => css`
    border-radius: ${theme.border.radius.lg} ${theme.border.radius.lg} 0 0;
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.modal};
  `}
`;

const StyledTitle = styled(Title)`
  line-height: 2rem;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const MobileModal = ({ children, portalId, title, isOpen, toggle }) => {
  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId={portalId}>
      <BackDrop onClick={toggle} />
      <FocusTrap>
        <ModalWrapper>
          {title && (
            <StyledTitle size={TitleSize.xxl} kind={TitleKind.h3}>
              {title}
            </StyledTitle>
          )}
          {children}
        </ModalWrapper>
      </FocusTrap>
    </ReactPortal>
  );
};

MobileModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  portalId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

MobileModal.defaultProps = {
  children: null,
  title: null,
  portalId: 'mobile-modal-root',
};

export default MobileModal;
