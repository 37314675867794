import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, useTheme } from 'styled-components';

import Badge from 'components/common/Badge';
import DealershipActions from '../DealershipActions';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin: 0.5rem 1.25rem;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);

  ${({ theme }) => css`
    font-family: ${theme.fonts.primary.family};
    color: ${theme.colors.darkerGray};
    border-radius: ${theme.border.radius.sm};

    @media screen and (min-width: ${theme.mediaQueries.tablet}) {
      margin: 0.5rem 4rem;
    }
  `}
`;

/* eslint-disable react/prop-types */
const OEMBadges = ({ oems }) => {
  const AMOUNT_TO_SHOW = 3;
  const OEMSsToShow = oems.slice(0, AMOUNT_TO_SHOW);
  const amountOfOEMsToHide = oems.length - AMOUNT_TO_SHOW;

  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-size: ${({ theme }) => theme.fonts.primary.size.xs};
      `}
    >
      {OEMSsToShow.map((oem) => (
        <Badge key={oem}>{oem}</Badge>
      ))}
      {amountOfOEMsToHide > 0 && <Badge>+{amountOfOEMsToHide}</Badge>}
    </span>
  );
};

/* eslint-enable react/prop-types */

const DealershipCard = ({ Logo, id, dealershipLogo, name, oems, address, dealershipImage, dealershipContract }) => {
  const theme = useTheme();

  const dealershipData = { id, logo: dealershipLogo, name, oems, address, dealershipImage, dealershipContract };

  return (
    <CardContainer>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          {Logo}
          <span
            css={css`
              display: flex;
              flex-direction: column;
              margin-left: 0.75rem;
            `}
          >
            <span
              css={css`
                color: ${theme.colors.black};
                font-weight: 500;
              `}
            >
              {name}
            </span>
            <span
              css={css`
                font-weight: 100;
              `}
            >
              {address}
            </span>
          </span>
        </div>
        <span
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: 1.5rem;
          `}
        >
          <DealershipActions dealershipData={dealershipData} />
        </span>
      </div>
      <OEMBadges oems={oems} />
    </CardContainer>
  );
};

DealershipCard.propTypes = {
  Logo: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
  oems: PropTypes.arrayOf(PropTypes.string).isRequired,
  dealershipContract: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }).isRequired,
  address: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dealershipImage: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }),
  dealershipLogo: PropTypes.string,
};

DealershipCard.defaultProps = {
  dealershipLogo: null,
  dealershipImage: null,
};

export default DealershipCard;
