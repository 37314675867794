import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, useTheme } from 'styled-components';

import { userRolesParsed, userStatus } from 'constants/users';
import UserActions from '../UserActions';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin: 0.5rem 1.25rem;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);

  ${({ theme }) => css`
    font-family: ${theme.fonts.primary.family};
    color: ${theme.colors.darkerGray};
    border-radius: ${theme.border.radius.sm};

    @media screen and (min-width: ${theme.mediaQueries.tablet}) {
      margin: 0.5rem 4rem;
    }
  `}
`;

// eslint-disable-next-line no-unused-vars
const UserCard = ({ iconUrl, Icon, name, firstName, lastName, id, email, role, status, StatusBadge }) => {
  const theme = useTheme();

  const currentUserStatus = status?.toLowerCase();
  const currentUserRole = role?.toLowerCase();

  const isBlocked = userStatus[currentUserStatus] === userStatus.blocked;

  const userData = { icon: iconUrl, Icon, firstName, lastName, email, role, id };

  return (
    <CardContainer>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            ${isBlocked &&
            css`
              filter: opacity(0.35);
            `}
          `}
        >
          <Icon theme={theme} />
          <span
            css={css`
              display: flex;
              flex-direction: column;
              margin-left: 0.75rem;
            `}
          >
            <span
              css={css`
                color: ${theme.colors.black};
                font-weight: 500;
              `}
            >
              {name}
            </span>
            <span
              css={css`
                font-weight: 100;
              `}
            >
              {userRolesParsed[currentUserRole] || role}
            </span>
          </span>
        </div>
        <span
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: 1.5rem;
          `}
        >
          <UserActions userData={userData} />
        </span>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          overflow-wrap: anywhere;
        `}
      >
        <span
          css={css`
            font-weight: 100;
            ${isBlocked &&
            css`
              filter: opacity(0.35);
            `}
          `}
        >
          {email}
        </span>
        {StatusBadge}
      </div>
    </CardContainer>
  );
};

UserCard.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]).isRequired,
  name: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  StatusBadge: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element, PropTypes.elementType])
    .isRequired,
};

export default UserCard;
