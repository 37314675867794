import firebase from 'services/firebase';
import { Dealership } from 'enums/cloudFunctions';

const deleteDealership = (id) => {
  const httpsDeleteDealership = firebase.functions().httpsCallable(Dealership.DeleteDealership);

  return httpsDeleteDealership({ id });
};

export default deleteDealership;
