import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { css } from 'styled-components';
import PropTypes from 'prop-types';

import validateFormFieldCondition from 'utils/validateFormFieldCondition';
import ErrorMessage from 'components/common/ErrorMessage';
import Checkbox from 'components/common/Checkbox';

export const LabelPosition = Object.freeze({
  Right: 'right',
  Left: 'left',
});

const CheckboxFormControl = ({
  name,
  parentName,
  defaultValue,
  conditionToShow,
  label,
  labelPosition,
  overrideValues,
  setOverrideValues,
}) => {
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const [checked, setChecked] = useState(defaultValue);

  const inputName = parentName ? `${parentName}.${name}` : name;

  const error = errors?.[inputName];

  const conditionIsFulfilled =
    !conditionToShow || validateFormFieldCondition(conditionToShow.isEqualTo, watch(conditionToShow.field));

  useEffect(() => {
    if (overrideValues) {
      setValue(parentName, overrideValues);
      setChecked(!!overrideValues?.[name]);
    }
  }, [name, parentName, overrideValues, setValue]);

  return conditionIsFulfilled ? (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Controller
        name={inputName}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { value, onChange, ref } }) => (
          <div
            css={css`
              display: flex;
              ${labelPosition === LabelPosition.Right && 'justify-content: row-reverse'}
            `}
          >
            <Checkbox
              ref={ref}
              id={inputName}
              onChange={() => {
                setChecked(() => {
                  const newStatus = !value;

                  onChange(newStatus);

                  return newStatus;
                });
                setOverrideValues(null);
              }}
              checked={checked}
            />
            <label htmlFor={inputName}>{label}</label>
          </div>
        )}
      />
      {error?.message && <ErrorMessage>{error?.message?.toString()}</ErrorMessage>}
    </div>
  ) : null;
};

CheckboxFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  parentName: PropTypes.string,
  setOverrideValues: PropTypes.string,
  overrideValues: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.string]),
  conditionToShow: PropTypes.shape({ field: PropTypes.string, isEqualTo: PropTypes.string }),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType, PropTypes.string]),
  labelPosition: PropTypes.string,
};

CheckboxFormControl.defaultProps = {
  parentName: null,
  overrideValues: null,
  setOverrideValues: null,
  defaultValue: false,
  conditionToShow: null,
  label: null,
  labelPosition: 'left',
};

export default CheckboxFormControl;
