/* eslint-disable no-param-reassign */

export const UPDATE_SEARCH_TABLE_VALUE = (state, { payload }) => {
  state.dealershipSearchTable = payload;
};

export const UPDATE_DEALERSHIPS_TABLE_FILTERS = (state, { payload }) => {
  state.dealershipsTableFilters = { ...state.dealershipsTableFilters, ...payload };
};

export const CLEAR_CREATE_DEALERSHIP_STATE = (state) => {
  state.createDealershipLoading = false;
  state.createDealershipFailed = false;
  state.createDealershipSuccess = false;
  state.createDealershipError = null;
};

export const CLEAR_EDIT_DEALERSHIP_STATE = (state) => {
  state.editDealershipLoading = false;
  state.editDealershipFailed = false;
  state.editDealershipSuccess = false;
  state.editDealershipError = null;
};

export const CLEAR_DELETE_DEALERSHIP_STATE = (state) => {
  state.deleteDealershipLoading = false;
  state.deleteDealershipFailed = false;
  state.deleteDealershipSuccess = false;
  state.deleteDealershipError = false;
};

export const CLEAR_DEALERSHIPS_STATE = (state) => {
  state.fetchDealershipLoading = false;
  state.fetchDealershipSuccess = false;
  state.fetchDealershipFailed = false;
  state.createDealershipLoading = false;
  state.createDealershipSuccess = false;
  state.createDealershipFailed = false;
  state.deleteDealershipLoading = false;
  state.deleteDealershipSuccess = false;
  state.deleteDealershipFailed = false;
  state.dealerships = [];
  state.dealershipSearchTable = { column: 'name', value: '' };
};
