import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import getUserAuthData from 'services/auth/getUserAuthData';

const signInWithEmail = async (email, password) => {
  const auth = getAuth();

  const {
    user: { uid, displayName, email: userEmail },
  } = await signInWithEmailAndPassword(auth, email, password);

  const {
    claims: { role },
  } = await getUserAuthData();

  return { role, uid, name: displayName, email: userEmail };
};

export default signInWithEmail;
