import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, useTheme } from 'styled-components';
import { FocusTrap } from '@headlessui/react';

import ReactPortal from 'components/common/ReactPortal';
import { CrossIcon } from 'icons';
import Button, { ButtonKind, ButtonSize } from 'components/common/Button';
import Title, { TitleKind, TitleSize } from 'components/common/Title';

const BackDrop = styled.div`
  ${({ theme }) =>
    css`
      background-color: ${theme.colors.mediumBlack};
      z-index: ${theme.zIndex.modalBackdrop};
    `};
  opacity: 0.2;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
  padding: 1.375rem 3rem 3rem;
  overflow-y: scroll;
  max-height: 80vh;

  ${({ theme }) => css`
    border-radius: ${theme.border.radius.xl};
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.modal};
  `}
`;

const ModalHeaderWrapper = styled.div`
  padding: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledTitle = styled(Title)`
  line-height: 2rem;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const Modal = ({ children, portalId, title, isOpen, toggle }) => {
  const theme = useTheme();

  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId={portalId}>
      <BackDrop onClick={toggle} />
      <FocusTrap>
        <ModalWrapper>
          <ModalHeaderWrapper>
            <Button
              css={css`
                padding: 0;
                border: none;
              `}
              kind={ButtonKind.Ghost}
              size={ButtonSize.Small}
              onClick={toggle}
            >
              <CrossIcon color={theme.colors.darkerGray} />
            </Button>
          </ModalHeaderWrapper>
          {title && (
            <StyledTitle size={TitleSize.xxl} kind={TitleKind.h3}>
              {title}
            </StyledTitle>
          )}
          {children}
        </ModalWrapper>
      </FocusTrap>
    </ReactPortal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  portalId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  children: null,
  title: null,
  portalId: 'modal-root',
};

export default Modal;
