import { createAsyncThunk } from '@reduxjs/toolkit';

import createUser from 'services/users/createUser';

// eslint-disable-next-line import/prefer-default-export
export const createUserAction = createAsyncThunk(
  'users/createUser',
  async ({ firstName, lastName, email, userRole, dealership, optionalMessage }) => {
    const uid = await createUser({
      firstName,
      lastName,
      email,
      role: userRole,
      dealership,
      invitationMessage: optionalMessage,
    });

    return uid;
  }
);
