import React from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import BuildAndCarIcon from 'icons/BuildAndCarIcon';

const DealershipIconContainer = styled.span`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.salmonLight};
  border-radius: 50%;
`;

const DefaultDealershipIcon = ({ height, width }) => {
  const theme = useTheme();

  return (
    <DealershipIconContainer height={height} width={width}>
      <BuildAndCarIcon color={theme.colors.primary} />
    </DealershipIconContainer>
  );
};

DefaultDealershipIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

DefaultDealershipIcon.defaultProps = {
  height: '2.5rem',
  width: '2.5rem',
};

export default DefaultDealershipIcon;
