import { createAsyncThunk } from '@reduxjs/toolkit';

import createDealership from 'services/dealerships/createDealership';

export const createDealershipAction = createAsyncThunk(
  'dealerships/createDealership',
  async ({ dealershipName, address, oems, dealershipImage, dealerContract }) => {
    const uid = await createDealership({
      name: dealershipName,
      address,
      oems,
      image: dealershipImage,
      contract: dealerContract,
    });

    return uid;
  }
);
