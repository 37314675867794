import { createSelector } from '@reduxjs/toolkit';

const dealershipsState = ({ dealerships }) => dealerships;

export const selectAllDealershipsState = createSelector(dealershipsState, ({ dealerships }) => ({ dealerships }));

export const selectDealershipTableSearchState = createSelector(dealershipsState, ({ dealershipSearchTable }) => ({
  dealershipSearchTable,
}));

export const selectDealershipsTableFiltersState = createSelector(dealershipsState, ({ dealershipsTableFilters }) => {
  const { selectedOems, mustIncludeAllOems, sorting } = dealershipsTableFilters;

  return {
    selectedOems,
    mustIncludeAllOems,
    sorting,
  };
});

export const selectFetchDealershipsState = createSelector(
  dealershipsState,
  ({ fetchDealershipLoading, fetchDealershipFailed, fetchDealershipSuccess, dealerships }) => ({
    fetchDealershipLoading,
    fetchDealershipFailed,
    fetchDealershipSuccess,
    dealerships,
  })
);

export const selectCreateDealershipState = createSelector(
  dealershipsState,
  ({ createDealershipLoading, createDealershipSuccess, createDealershipFailed, createDealershipError }) => ({
    createDealershipLoading,
    createDealershipSuccess,
    createDealershipFailed,
    createDealershipError,
  })
);

export const selectEditDealershipState = createSelector(
  dealershipsState,
  ({ editDealershipLoading, editDealershipSuccess, editDealershipFailed, editDealershipError }) => ({
    editDealershipLoading,
    editDealershipSuccess,
    editDealershipFailed,
    editDealershipError,
  })
);

export const selectDeleteDealershipState = createSelector(
  dealershipsState,
  ({ deleteDealershipLoading, deleteDealershipSuccess, deleteDealershipFailed, deleteDealershipError }) => ({
    deleteDealershipLoading,
    deleteDealershipSuccess,
    deleteDealershipFailed,
    deleteDealershipError,
  })
);
