import { Sort } from 'enums/filters';
import customSortCompare from './customSortCompare';

const sortTable = ({ tableData, paramToSort, sort = Sort.Ascending }) => {
  const sortedUsers = tableData.sort(customSortCompare(paramToSort));

  return sort === Sort.Descending ? sortedUsers.reverse() : sortedUsers;
};

export default sortTable;
