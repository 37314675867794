import React from 'react';
import PropTypes from 'prop-types';

const FileImageIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.25 19.7505C2.48333 19.7505 1.83333 19.4838 1.3 18.9505C0.766667 18.4172 0.5 17.7672 0.5 17.0005V3.50049C0.5 2.73382 0.766667 2.08382 1.3 1.55049C1.83333 1.01715 2.48333 0.750488 3.25 0.750488H16.75C17.5167 0.750488 18.1667 1.01715 18.7 1.55049C19.2333 2.08382 19.5 2.73382 19.5 3.50049V17.0005C19.5 17.7672 19.2333 18.4172 18.7 18.9505C18.1667 19.4838 17.5167 19.7505 16.75 19.7505H3.25ZM3.25 18.2505H16.75C17.1 18.2505 17.396 18.1295 17.638 17.8875C17.8793 17.6462 18 17.3505 18 17.0005V3.50049C18 3.15049 17.8793 2.85449 17.638 2.61249C17.396 2.37115 17.1 2.25049 16.75 2.25049H3.25C2.9 2.25049 2.60433 2.37115 2.363 2.61249C2.121 2.85449 2 3.15049 2 3.50049V17.0005C2 17.3505 2.121 17.6462 2.363 17.8875C2.60433 18.1295 2.9 18.2505 3.25 18.2505ZM4.6 15.7505L8 12.3505L9.825 14.1505L12 11.4005L15.475 15.7505H4.6ZM6.25 8.00049C5.76667 8.00049 5.35433 7.82982 5.013 7.48849C4.671 7.14649 4.5 6.73382 4.5 6.25049C4.5 5.76716 4.671 5.35449 5.013 5.01249C5.35433 4.67115 5.76667 4.50049 6.25 4.50049C6.73333 4.50049 7.146 4.67115 7.488 5.01249C7.82933 5.35449 8 5.76716 8 6.25049C8 6.73382 7.82933 7.14649 7.488 7.48849C7.146 7.82982 6.73333 8.00049 6.25 8.00049Z"
      fill={color}
    />
  </svg>
);

FileImageIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

FileImageIcon.defaultProps = {
  color: '#212121',
  width: 20,
  height: 20,
};

export default FileImageIcon;
