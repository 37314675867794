import React, { useMemo, useCallback, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { deleteDealershipAction, getAllDealershipsAction } from 'store/asyncActions';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';
import { BigWarningIcon, DefaultDealershipIcon, LoadingAnimatedIcon } from 'icons';
import Title, { TitleKind } from 'components/common/Title';
import { selectDeleteDealershipState } from 'store/selectors/dealerships';
import { clearDeleteDealershipState } from 'store/slices/dealershipsSlice';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  gap: 2rem;
  margin-top: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      background-color: ${theme.colors.white};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        width: 50vw;
      }

      @media screen and (min-width: ${theme.mediaQueries.largerTabet}) {
        width: 35vw;
      }
    `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.mobile}) {
    flex-direction: row;
  }
`;

const DeleteDealershipForm = ({ toggle, dealershipData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { deleteDealershipLoading, deleteDealershipSuccess, deleteDealershipError } = useSelector(
    selectDeleteDealershipState,
    shallowEqual
  );

  const { logo, name, id } = dealershipData;

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
    }),
    []
  );

  const onSubmit = useCallback(() => {
    dispatch(deleteDealershipAction(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (deleteDealershipSuccess) {
      dispatch(getAllDealershipsAction());
      toggle();
    }
  }, [dispatch, toggle, deleteDealershipSuccess]);

  useEffect(
    () => () => {
      dispatch(clearDeleteDealershipState());
      toast.clearWaitingQueue();
    },
    [dispatch]
  );

  useEffect(() => {
    if (deleteDealershipError?.message) {
      toast.error(deleteDealershipError.message);
    }
  }, [deleteDealershipError?.message]);

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <BigWarningIcon />
        <Title
          css={css`
            font-weight: 500;
            color: ${theme.colors.black};
            margin: 2rem 0;
          `}
          kind={TitleKind.h3}
        >
          Are you sure you want to delete this dealership?
        </Title>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.75rem;
            font-weight: 500;
          `}
        >
          {logo ? (
            <img
              css={css`
                height: 2.5rem;
                width: 2.5rem;
                border-radius: 50%;
                border: 1px solid ${theme.colors.lightGray};
              `}
              src={logo}
              alt="dealershipLogo"
            />
          ) : (
            <DefaultDealershipIcon height="3.5rem" width="3.5rem" />
          )}
          {name}
        </div>
      </div>
      <ButtonsContainer>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
          `}
          disabled={deleteDealershipLoading}
          kind={ButtonKind.Secondary}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          disabled={deleteDealershipLoading}
          type={ButtonType.Submit}
        >
          {deleteDealershipLoading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Delete dealership'
          )}
        </Button>
      </ButtonsContainer>
    </StyledForm>
  );
};

DeleteDealershipForm.propTypes = {
  toggle: PropTypes.func,
  dealershipData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    Logo: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
};

DeleteDealershipForm.defaultProps = {
  toggle: null,
};

export default DeleteDealershipForm;
