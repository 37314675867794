import * as yup from 'yup';

import CheckboxFormControl from 'components/FormControls/CheckboxFormControl';
import { userRoles, userRolesParsed, userStatus } from 'constants/users';
import styled, { css } from 'styled-components';
import { InputType } from 'components/common/Input';
import RadioButton from 'components/common/RadioButton';
import FormControl from 'components/FormControls/FormControl';
import { Sort } from 'enums/filters';

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem 0.75rem;

  ${({ theme }) => css`
    color: ${theme.colors.darkerGray};
    caret-color: ${theme.colors.darkerGray};
    font-size: ${theme.fonts.primary.size.xl};
    vertical-align: middle;
  `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${({ theme }) =>
      ` -webkit-box-shadow: 0 0 0 30px ${theme.colors.white} inset !important;
    -webkit-text-fill-color: ${theme.colors.darkerGray};
    -webkit-text-size-adjust: ${theme.fonts.primary.size.xl};`}
  }
`;

export const filtersToUsersTableFields = [
  {
    label: 'Status',
    name: 'status',
    childrens: [
      {
        name: userStatus.active,
        label: userStatus.active,
        FormControl: CheckboxFormControl,
      },
      {
        name: userStatus.pending,
        label: userStatus.pending,
        FormControl: CheckboxFormControl,
      },
      {
        name: userStatus.blocked,
        label: userStatus.blocked,
        FormControl: CheckboxFormControl,
      },
    ],
  },
  {
    label: 'Role',
    name: 'role',
    childrens: [
      {
        name: userRoles.admin,
        label: userRolesParsed[userRoles.admin],
        FormControl: CheckboxFormControl,
      },
      {
        name: userRoles.sales,
        label: userRolesParsed[userRoles.sales],
        FormControl: CheckboxFormControl,
      },
      {
        name: userRoles.dealer,
        label: userRolesParsed[userRoles.dealer],
        FormControl: CheckboxFormControl,
      },
    ],
  },
  {
    label: 'Sort by name',
    name: 'sorting',
    type: InputType.Radio,
    Control: RadioButton,
    FormControl: StyledFormControl,
    controllersContainerStyle: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      ${({ theme }) =>
        css`
          @media screen and (min-width: ${theme.mediaQueries.tablet}) {
            gap: 3rem;
          }
        `}
    `,
    controllers: [
      {
        value: Sort.Ascending,
        label: 'A to Z',
      },
      {
        value: Sort.Descending,
        label: 'Z to A',
      },
    ],
  },
];

export const validationSchema = yup.object().shape({
  oems: yup.array().nullable(),
  mustIncludeAllOems: yup.bool().nullable(),
});
