import React from 'react';
import PropTypes from 'prop-types';
import { Disclosure as HeadlessDisclosure } from '@headlessui/react';
import styled, { css } from 'styled-components';

const DisclosureButton = styled(HeadlessDisclosure.Button)`
  background: none;
  border: none;
  width: 100%;
  padding: 0;
`;

const Disclosure = ({ Button, content }) => (
  <HeadlessDisclosure>
    {({ open }) => (
      <>
        <DisclosureButton>
          <Button active={open} />
        </DisclosureButton>
        <HeadlessDisclosure.Panel
          css={css`
            width: 100%;
            height: fit-content;
          `}
        >
          {content}
        </HeadlessDisclosure.Panel>
      </>
    )}
  </HeadlessDisclosure>
);

Disclosure.propTypes = {
  Button: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]).isRequired,
  content: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]).isRequired,
};

export default Disclosure;
