import React, { useMemo, useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { updateUsersTableFilters } from 'store/slices/usersSlice';
import { selectUsersTableFiltersState } from 'store/selectors/users';
import { filtersToUsersTableFields } from 'utils/formFields/FiltersToUsersTableFields';
import { Sort } from 'enums/filters';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';
import Label from 'components/common/Label';
import useCustomMediaQuery from 'hooks/useMediaQuery';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};
    `};

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    width: 15.625rem;
    padding: 1.5rem 1rem;
  }
`;

const StyledLabel = styled(Label)`
  ${({ theme, index }) =>
    css`
      ${index >= 1 &&
      css`
        border-top: solid 1px ${theme.colors.lightGray};
      `}
      width: 100%;
      padding: 0.5rem 0 0.5rem;
      color: ${theme.colors.black};
      font-size: ${theme.fonts.primary.size.md};
      font-weight: 500;
    `};
`;

const FormFields = ({ overrideValues, setOverrideValues }) => {
  const theme = useTheme();
  const isTablet = useCustomMediaQuery(theme.mediaQueries.tablet);

  const usersTableFiltersState = useSelector(selectUsersTableFiltersState, shallowEqual);

  return filtersToUsersTableFields.map(({ name, label, childrens, FormControl, controllers, ...field }, index) => {
    if (childrens) {
      return (
        <>
          <StyledLabel index={index}>{label}</StyledLabel>
          {childrens.map(({ FormControl: ChildrenFormControl, name: childrenName, ...rest }) => (
            <ChildrenFormControl
              parentName={name}
              name={childrenName}
              defaultValue={!!usersTableFiltersState?.[name]?.[childrenName]}
              overrideValues={overrideValues}
              setOverrideValues={setOverrideValues}
              {...rest}
            />
          ))}
        </>
      );
    }

    if (controllers) {
      if (isTablet) {
        return null;
      }

      return (
        <>
          <StyledLabel index={index}>{label}</StyledLabel>
          <FormControl name={name} controllers={controllers} defaultValue={usersTableFiltersState?.[name]} {...field} />
        </>
      );
    }

    return (
      <FormControl
        key={name}
        inputContainerCss={css`
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        `}
        name={name}
        {...field}
      />
    );
  });
};

const FiltersToUsersTableForm = ({ toggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [overrideValues, setOverrideValues] = useState(null);

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
    }),
    []
  );

  const onSubmit = useCallback(
    ({ role, status, sorting }) => {
      dispatch(updateUsersTableFilters({ role, status, sorting }));
      toggle?.();
    },
    [dispatch, toggle]
  );

  const clearFiltersState = () => {
    dispatch(updateUsersTableFilters({ role: {}, status: {}, sorting: Sort.Ascending }));
    setOverrideValues({});
    toggle?.();
  };

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        <FormFields overrideValues={overrideValues} setOverrideValues={setOverrideValues} />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          gap: 0.5rem;
        `}
      >
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            border: none;
          `}
          kind={ButtonKind.Secondary}
          onClick={clearFiltersState}
        >
          Clear all
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          type={ButtonType.Submit}
        >
          Apply
        </Button>
      </div>
    </StyledForm>
  );
};

FiltersToUsersTableForm.propTypes = {
  toggle: PropTypes.func,
};

FiltersToUsersTableForm.defaultProps = {
  toggle: null,
};

export default FiltersToUsersTableForm;
