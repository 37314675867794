import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { ArrowIcon, LogoIcon } from 'icons';
import Disclosure from 'components/common/Disclosure';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import Title, { TitleKind, TitleSize } from 'components/common/Title';

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  padding: 2rem 1.125rem;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    padding: 2.25rem 4rem;
    height: 19.438rem;
  }
`;

const AddressInfo = styled.address`
  width: 9.375rem;
  margin-top: 2rem;
  font-style: normal;
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      font-size: ${theme.fonts.primary.size.md};
      line-height: ${theme.fonts.primary.lineHeight.md};
      color: ${theme.colors.darkerGray};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        margin: 0;
      }
    `}
`;

const DisclosureContainer = styled.div`
  width: 100%;
`;

const DisclosureButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  width: 100%;

  ${({ theme }) => css`
    font-family: ${theme.fonts.primary.family};
  `};
`;

const DisclosureOption = styled.span`
  height: 2rem;
  font-weight: 500;
  width: 100%;
  color: ${({ theme }) => theme.colors.darkerGray};
  cursor: pointer;
  white-space: nowrap;

  &:active {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const generateDisclosureButton =
  ({ title, withArrow = true }) =>
  // eslint-disable-next-line react/prop-types
  ({ active }) => {
    const theme = useTheme();

    return (
      <DisclosureButton>
        <Title
          css={css`
            font-weight: 500;
            margin: 0;
            height: auto;
            padding: 0;
          `}
          kind={TitleKind.h3}
          size={TitleSize.xl}
        >
          {title}
        </Title>
        <span
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;

            ${active &&
            css`
              transform: rotate(-180deg);
            `}
          `}
        >
          {withArrow && <ArrowIcon color={theme.colors.primary} width={19} height={19} />}
        </span>
      </DisclosureButton>
    );
  };

const Footer = () => {
  const theme = useTheme();
  const isTablet = useCustomMediaQuery(theme.mediaQueries.tablet);

  const showFooterOptions = false;

  const disclosures = [
    { title: 'Product', items: ['How it works', 'Testimonials', 'Return Lease'] },
    { title: 'Resources', items: ['Help center', 'Case studies'] },
    { title: 'Company', items: ['About us', 'Contact us'] },
  ];

  const generateDisclosureContent = (items) => (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: baseline;
        width: 100%;
        font-family: ${theme.fonts.primary.family};
        padding-left: 2.5rem;

        @media screen and (min-width: ${theme.mediaQueries.tablet}) {
          padding: 0;
        }
      `}
    >
      {items.map((item) => (
        <DisclosureOption key={`${item}-disclosureOption`}>{item}</DisclosureOption>
      ))}
    </div>
  );

  return (
    <StyledFooter>
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: auto;
          width: 100%;
          max-width: ${theme.mediaQueries.fullDesktop};
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 5rem;

            @media screen and (min-width: ${theme.mediaQueries.tablet}) {
              flex-direction: row;
              align-self: baseline;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;

              @media screen and (min-width: ${theme.mediaQueries.tablet}) {
                width: auto;
              }
            `}
          >
            <LogoIcon
              css={css`
                align-self: center;

                @media screen and (min-width: ${theme.mediaQueries.tablet}) {
                  align-self: auto;
                  line-height: 3.125rem !important;
                }
              `}
            />
            <AddressInfo>
              100 Innovation Way <br />
              Suite 300 <br />
              Irvine, California <br />
              92618
            </AddressInfo>
          </div>
          {!isTablet &&
            showFooterOptions &&
            disclosures.map(({ title, items }) => (
              <DisclosureContainer key={`${title.toLowerCase().trim()}-closure`}>
                <Disclosure Button={generateDisclosureButton({ title })} content={generateDisclosureContent(items)} />
              </DisclosureContainer>
            ))}
          {isTablet && showFooterOptions && (
            <div
              css={css`
                display: flex;
                gap: inherit;
              `}
            >
              {disclosures.map(({ title, items }) => {
                const ItemTitle = generateDisclosureButton({ title, withArrow: false });

                return (
                  <div key={`${title?.toLowerCase()?.trim()}-footerItems`}>
                    <ItemTitle />
                    {generateDisclosureContent(items)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <span
          css={css`
            font-family: ${theme.fonts.primary.family};
            font-size: ${theme.fonts.primary.size};
            color: ${theme.colors.darkerGray};
            font-weight: 400;
            margin: 3rem 0 2rem;
          `}
        >
          @ 2022 LeaseBux. All rights reserved
        </span>
      </div>
    </StyledFooter>
  );
};

export default Footer;
