import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const ButtonKind = {
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
  Ghost: 'ghost',
};

export const ButtonType = {
  Button: 'button',
  Reset: 'reset',
  Submit: 'submit',
};

export const ButtonSize = {
  Large: 'large',
  Medium: 'medium',
  Small: 'small',
};

const ButtonKindStyles = {
  [ButtonKind.Primary]: css`
    ${({ theme }) => `
      border: none;
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary};
    `}
  `,
  [ButtonKind.Secondary]: css`
    ${({ theme }) => `
      border: none;
      color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      background: none;
  `}
  `,
  [ButtonKind.Tertiary]: css`
    ${({ theme }) => `
    border: none;
    color: ${theme.colors.white};
    background-color: ${theme.colors.tertiary};
  `}
  `,
  [ButtonKind.Ghost]: css`
    ${({ theme }) => `
      color: ${theme.colors.ghost};
      border: 1px solid ${theme.colors.ghost};
      background-color: transparent;
  `}
  `,
};

const ButtonSizeStyles = {
  [ButtonSize.Large]: css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.75rem 1.5rem;
  `,
  [ButtonSize.Medium]: css`
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  [ButtonSize.Small]: css`
    font-size: 0.875rem;
    line-height: 1.25rem;
  `,
};

const StyledButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.primary.family};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;

  &:not(:disabled) {
    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};

    &:hover {
      cursor: not-allowed;
    }
  }

  ${({ kind }) => ButtonKindStyles[kind]}

  ${({ size }) => ButtonSizeStyles[size]}
`;

const Button = ({ children, Icon, ...otherProps }) => (
  <StyledButton {...otherProps}>
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1rem;
      `}
    >
      {Icon && <Icon />}
      {children}
    </div>
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(Object.values(ButtonKind)),
  type: PropTypes.oneOf(Object.values(ButtonType)),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
};

Button.defaultProps = {
  kind: ButtonKind.Primary,
  type: ButtonType.Button,
  disabled: false,
  size: ButtonSize.Medium,
  Icon: null,
};

export default Button;
