import firebase from 'services/firebase';
import { Users } from 'enums/cloudFunctions';

const deleteUser = (uid) => {
  const httpsDeleteUser = firebase.functions().httpsCallable(Users.DeleteUser);

  return httpsDeleteUser({ uid });
};

export default deleteUser;
