import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import EndEnhancer from 'components/common/EndEnhancer';

export const InputType = {
  File: 'file',
  Text: 'text',
  Email: 'email',
  Radio: 'radio',
  Number: 'number',
  Checkbox: 'checkbox',
  Password: 'password',
};

const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.fonts.primary.family};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.ghost};
  padding: 0.625rem 0.875rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.outline};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};

    &:hover {
      cursor: not-allowed;
    }
  }

  ${({ type }) =>
    type !== InputType.Checkbox &&
    css`
      width: 100%;
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.colors.error};
    `}
`;

const Input = ({ register, name, endEnhancer, defaultValue, type, ...props }) => (
  <div
    css={
      type !== InputType.Checkbox &&
      `
      width: 100%;
      position: relative;
    `
    }
  >
    <StyledInput name={name} defaultValue={defaultValue} type={type} {...register?.(name)} {...props} />
    {endEnhancer && <EndEnhancer>{endEnhancer}</EndEnhancer>}
  </div>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  error: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(InputType)).isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  endEnhancer: PropTypes.node,
};

Input.defaultProps = {
  disabled: false,
  register: null,
  error: false,
  endEnhancer: null,
  defaultValue: '',
};

export default Input;
