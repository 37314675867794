import { v4 as uuidv4 } from 'uuid';

import firebase from 'services/firebase';
import { Dealership } from 'enums/cloudFunctions';
import { uploadFileAndGetUrl } from 'services/firebase/storage';
import StoragePaths, { StorageChildsPaths } from 'enums/storagePaths';

const createDealership = async ({ name, address, oems, image, contract }) => {
  const httpsCreateDealership = firebase.functions().httpsCallable(Dealership.CreateDealership);

  const dealershipId = uuidv4();

  const uploadImagePath = `${StoragePaths.Dealerships}/${
    StorageChildsPaths[StoragePaths.Dealerships].Logos
  }/${dealershipId}`;
  const uploadContractPath = `${StoragePaths.Dealerships}/${
    StorageChildsPaths[StoragePaths.Dealerships].Contracts
  }/${dealershipId}`;

  const contractName = contract?.name || null;
  const imageName = image?.name || null;

  let contractUrl = '';
  let imageUrl = '';

  try {
    contractUrl = await uploadFileAndGetUrl(uploadContractPath, contract);

    if (image) {
      imageUrl = await uploadFileAndGetUrl(uploadImagePath, image);
    }
  } catch (error) {
    throw new Error('Error uploading files');
  }

  try {
    await httpsCreateDealership({
      name,
      address,
      oems,
      image: imageUrl,
      imageName,
      contract: contractUrl,
      contractName,
    });
  } catch (error) {
    throw new Error('Error Creating dealership files');
  }
};

export default createDealership;
