import { useCallback, useEffect, useState } from 'react';

const useModal = (initialMode = false) => {
  const [isOpen, setIsOpen] = useState(initialMode);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const toggle = useCallback(() => setIsOpen((prevState) => !prevState), []);

  return { isOpen, setIsOpen, toggle };
};

export default useModal;
