import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Paths from 'utils/constants/paths';
import Login from 'pages/Login';
import Home from 'pages/Home';
import ActivateUserAccount from 'pages/ActivateUserAccount';

const browserRouter = createBrowserRouter([
  {
    path: Paths.Home,
    element: <Home />,
  },
  { path: Paths.Login, element: <Login /> },
  { path: Paths.ConfirmAccount, element: <ActivateUserAccount /> },
  { path: '*', element: <Navigate to={Paths.Home} /> },
]);

const Router = () => <RouterProvider router={browserRouter} />;

export default Router;
