import React from 'react';

const ArrowWithVector = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 8L0.999999 8M0.999999 8L8 15M0.999999 8L8 1"
      stroke="#212121"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowWithVector;
