/* eslint-disable react-hooks/exhaustive-deps */
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

import { getAllOemsAction } from 'store/asyncActions';
import { updateDealershipsTableFilters } from 'store/slices/dealershipsSlice';
import { selectAllOemsState } from 'store/selectors/oems';
import { filtersToDealershipTableFields, validationSchema } from 'utils/formFields/FiltersToDealershipTableFields';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import { selectDealershipsTableFiltersState } from 'store/selectors/dealerships';
import { Sort } from 'enums/filters';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};
    `};

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    width: 30rem;
    padding: 1rem;
  }
`;

const SubmitButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`;

const FiltersToDealershipTableForm = ({ toggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isTablet = useCustomMediaQuery(theme.mediaQueries.tablet);

  const [overrideValues, setOverrideValues] = useState(null);

  const { oems } = useSelector(selectAllOemsState, shallowEqual);
  const { selectedOems: filterSelectedOems, sorting: filterSorting } = useSelector(
    selectDealershipsTableFiltersState,
    shallowEqual
  );

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    }),
    []
  );

  const onSubmit = useCallback(
    ({ oems: selectedOems, mustIncludeAllOems, sorting }) => {
      dispatch(updateDealershipsTableFilters({ selectedOems, mustIncludeAllOems, sorting }));
      toggle();
    },
    [dispatch]
  );

  const defaultValues = { oems: filterSelectedOems, sorting: filterSorting };

  useEffect(() => {
    dispatch(getAllOemsAction());
  }, [dispatch]);

  const selectOems = useMemo(() => oems?.map(({ make }) => ({ value: make, label: make })) || [], [oems]);

  const clearSelectState = () => {
    dispatch(updateDealershipsTableFilters({ selectedOems: [], mustIncludeAllOems: false, sorting: Sort.Ascending }));
    setOverrideValues([]);
  };

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        {filtersToDealershipTableFields.map(({ name, FormControl, ...field }, index) => {
          if (isTablet && index === 2) {
            return null;
          }

          return (
            <FormControl
              key={name}
              inputContainerCss={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
              overrideValues={overrideValues}
              setOverrideValues={setOverrideValues}
              defaultValue={defaultValues[name]}
              options={selectOems}
              name={name}
              {...field}
            />
          );
        })}
      </div>
      <SubmitButtonsContainer>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            border: none;
          `}
          kind={ButtonKind.Secondary}
          onClick={clearSelectState}
        >
          Clear all
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          type={ButtonType.Submit}
        >
          Apply
        </Button>
      </SubmitButtonsContainer>
    </StyledForm>
  );
};

FiltersToDealershipTableForm.propTypes = {
  toggle: PropTypes.func,
};

FiltersToDealershipTableForm.defaultProps = {
  toggle: null,
};

export default FiltersToDealershipTableForm;
