import axios from 'axios';

const getAllOems = async () => {
  const oems = await axios.get(
    `https://carmakemodeldb.com/api/v1/car-lists/get/all/makes?api_token=${process.env.REACT_APP_CARLIST_TOKEN}`
  );

  return oems.data;
};

export default getAllOems;
