import React, { useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import Button, { ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  gap: 2rem;
  margin-top: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        width: 50vw;
      }

      @media screen and (min-width: ${theme.mediaQueries.largerTabet}) {
        width: 35vw;
      }
    `};
`;

const TermsAndConditionsForm = ({ toggle }) => {
  const theme = useTheme();

  const onSubmit = useCallback(() => {
    toggle();
  }, [toggle]);

  return (
    <StyledForm onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Tellus id interdum velit laoreet id donec. Integer malesuada nunc vel risus commodo viverra
        maecenas accumsan. Dui nunc mattis enim ut tellus elementum sagittis. Felis donec et odio pellentesque diam
        volutpat. Et leo duis ut diam quam nulla porttitor massa. Non sodales neque sodales ut etiam. Cursus vitae
        congue mauris rhoncus aenean vel elit scelerisque. Viverra adipiscing at in tellus integer feugiat scelerisque
        varius. Nulla pellentesque dignissim enim sit. Commodo viverra maecenas accumsan lacus vel facilisis volutpat.
        Venenatis urna cursus eget nunc scelerisque viverra. Facilisi cras fermentum odio eu feugiat pretium nibh. Augue
        lacus viverra vitae congue eu consequat. Aenean sed adipiscing diam donec adipiscing tristique. Enim lobortis
        scelerisque fermentum dui. Semper auctor neque vitae tempus quam pellentesque nec. Quis hendrerit dolor magna
        eget est. Nisl suscipit adipiscing bibendum est. Porttitor leo a diam sollicitudin tempor id eu nisl. Iaculis
        nunc sed augue lacus viverra vitae. Amet cursus sit amet dictum sit amet justo donec enim. Diam quam nulla
        porttitor massa id. Integer malesuada nunc vel risus commodo. Faucibus scelerisque eleifend donec pretium
        vulputate sapien nec sagittis. Suspendisse sed nisi lacus sed. Auctor eu augue ut lectus arcu bibendum at. Nunc
        lobortis mattis aliquam faucibus purus in massa tempor. Donec pretium vulputate sapien nec sagittis. Ultrices
        gravida dictum fusce ut placerat orci. Sit amet facilisis magna etiam tempor. Eleifend donec pretium vulputate
        sapien nec sagittis aliquam malesuada. Habitant morbi tristique senectus et. Tempor orci dapibus ultrices in.
        Malesuada fames ac turpis egestas sed. Quis enim lobortis scelerisque fermentum dui faucibus. Nulla pharetra
        diam sit amet nisl suscipit adipiscing. Nibh ipsum consequat nisl vel pretium lectus. Euismod nisi porta lorem
        mollis aliquam ut porttitor leo a. Sit amet venenatis urna cursus eget nunc scelerisque viverra. In egestas erat
        imperdiet sed. Tortor condimentum lacinia quis vel eros donec. Erat velit scelerisque in dictum non. At auctor
        urna nunc id cursus metus. Amet cursus sit amet dictum. Ullamcorper velit sed ullamcorper morbi tincidunt. Dui
        faucibus in ornare quam viverra orci sagittis. Varius vel pharetra vel turpis. Amet dictum sit amet justo donec.
        Nullam vehicula ipsum a arcu cursus vitae. Accumsan sit amet nulla facilisi morbi. Odio euismod lacinia at quis
        risus sed. Vulputate dignissim suspendisse in est ante in. Nisi lacus sed viverra tellus in hac. Vel fringilla
        est ullamcorper eget nulla facilisi. Pretium aenean pharetra magna ac placerat vestibulum lectus mauris
        ultrices. Tincidunt arcu non sodales neque sodales. Praesent elementum facilisis leo vel fringilla est
        ullamcorper eget. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Vel quam elementum
        pulvinar etiam non quam lacus suspendisse faucibus. Nullam eget felis eget nunc lobortis mattis aliquam
        faucibus. Ac placerat vestibulum lectus mauris ultrices eros. In vitae turpis massa sed elementum tempus. Lorem
        mollis aliquam ut porttitor leo a diam. Viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est.
        Cursus turpis massa tincidunt dui ut ornare. Magna eget est lorem ipsum dolor sit amet consectetur. Tortor at
        auctor urna nunc id. Commodo ullamcorper a lacus vestibulum sed arcu. Scelerisque eleifend donec pretium
        vulputate sapien nec sagittis aliquam malesuada. Ultrices in iaculis nunc sed augue lacus. Nisl nisi scelerisque
        eu ultrices vitae auctor eu augue. Sed odio morbi quis commodo odio aenean sed adipiscing. Cursus metus aliquam
        eleifend mi in nulla posuere sollicitudin. Feugiat nibh sed pulvinar proin gravida. Eu consequat ac felis donec
        et odio pellentesque diam volutpat. Sagittis nisl rhoncus mattis rhoncus. Faucibus pulvinar elementum integer
        enim neque. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Risus viverra
        adipiscing at in tellus integer feugiat scelerisque varius. Nullam ac tortor vitae purus faucibus ornare
        suspendisse sed nisi. Lectus mauris ultrices eros in cursus turpis massa tincidunt. Consectetur adipiscing elit
        duis tristique sollicitudin nibh. Pellentesque id nibh tortor id aliquet lectus. Eu feugiat pretium nibh ipsum
        consequat nisl vel pretium lectus. Justo eget magna fermentum iaculis eu non diam phasellus. Aliquam malesuada
        bibendum arcu vitae elementum curabitur vitae. Faucibus turpis in eu mi bibendum neque. Morbi tristique senectus
        et netus. Ipsum dolor sit amet consectetur adipiscing elit duis. Ultricies tristique nulla aliquet enim tortor
        at auctor urna nunc. Risus pretium quam vulputate dignissim suspendisse. Cursus turpis massa tincidunt dui ut
        ornare lectus. Praesent semper feugiat nibh sed pulvinar proin gravida hendrerit lectus. Nunc id cursus metus
        aliquam eleifend mi in nulla posuere. Faucibus interdum posuere lorem ipsum dolor sit amet. Varius duis at
        consectetur lorem donec. Tortor at auctor urna nunc. Eget felis eget nunc lobortis mattis. Sem nulla pharetra
        diam sit amet nisl suscipit adipiscing bibendum. Commodo nulla facilisi nullam vehicula ipsum. Laoreet sit amet
        cursus sit amet dictum sit. Volutpat ac tincidunt vitae semper quis lectus nulla at. Tortor pretium viverra
        suspendisse potenti nullam ac tortor vitae purus. Leo urna molestie at elementum eu facilisis sed odio.
        Consequat interdum varius sit amet mattis vulputate enim nulla. Netus et malesuada fames ac turpis egestas sed
        tempus. Libero id faucibus nisl tincidunt eget nullam non nisi. Suspendisse interdum consectetur libero id
        faucibus nisl tincidunt. Quis lectus nulla at volutpat. Ornare arcu odio ut sem nulla pharetra diam. Viverra
        ipsum nunc aliquet bibendum enim facilisis gravida neque. Sollicitudin aliquam ultrices sagittis orci a
        scelerisque purus semper. At tellus at urna condimentum mattis pellentesque id nibh. Et leo duis ut diam quam.
        Vel turpis nunc eget lorem. Convallis tellus id interdum velit laoreet id. Viverra justo nec ultrices dui sapien
        eget. Congue quisque egestas diam in. Laoreet non curabitur gravida arcu ac tortor. Mattis nunc sed blandit
        libero volutpat sed. Sed turpis tincidunt id aliquet risus feugiat in ante. Aliquam nulla facilisi cras
        fermentum odio eu feugiat pretium nibh. Rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt
        lobortis feugiat. Vel elit scelerisque mauris pellentesque pulvinar pellentesque. Nullam ac tortor vitae purus
        faucibus ornare. Nisi vitae suscipit tellus mauris a. Vitae proin sagittis nisl rhoncus mattis rhoncus.
        Tristique risus nec feugiat in fermentum. Curabitur vitae nunc sed velit. Eget est lorem ipsum dolor sit. Magna
        fermentum iaculis eu non diam phasellus. Mauris in aliquam sem fringilla ut morbi tincidunt. Tellus rutrum
        tellus pellentesque eu tincidunt tortor aliquam. Aliquam faucibus purus in massa tempor nec feugiat nisl
        pretium. Amet volutpat consequat mauris nunc congue nisi vitae. Interdum velit laoreet id donec. Magna etiam
        tempor orci eu lobortis elementum nibh tellus. Volutpat blandit aliquam etiam erat velit scelerisque.
      </div>
      <div
        css={css`
          display: flex;
          width: 100%;
          gap: 0.5rem;
        `}
      >
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          type={ButtonType.Submit}
        >
          Close
        </Button>
      </div>
    </StyledForm>
  );
};

TermsAndConditionsForm.propTypes = {
  toggle: PropTypes.func,
};

TermsAndConditionsForm.defaultProps = {
  toggle: null,
};

export default TermsAndConditionsForm;
