import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00002 1.1665V12.8332M1.16669 6.99984H12.8334"
      stroke={color}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

PlusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

PlusIcon.defaultProps = {
  color: '#212121',
  width: 14,
  height: 14,
};

export default PlusIcon;
