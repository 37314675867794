import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import usersReducer from './usersSlice';
import dealershipsReducer from './dealershipsSlice';
import oemsReducer from './oemsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  dealerships: dealershipsReducer,
  oems: oemsReducer,
});

export default rootReducer;
