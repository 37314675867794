import firebase from 'firebase/compat/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}

const db = firebase.firestore();
const auth = getAuth();
const functions = firebase.functions();
const storage = firebase.storage();
const rtdb = getDatabase();

if (
  process.env.NODE_ENV === 'development' ||
  // eslint-disable-next-line no-restricted-globals
  location.hostname === 'localhost'
) {
  db.useEmulator('localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099/');
  functions.useEmulator('localhost', 5001);
  storage.useEmulator('localhost', 9199);
  connectDatabaseEmulator(rtdb, 'localhost', 9000);
}

export default firebase;
