import React from 'react';
import ReactDOM from 'react-dom/client';

import 'services/firebase';
import RootProvider from 'contexts/RootProvider';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RootProvider>
      <App />
    </RootProvider>
  </React.StrictMode>
);
