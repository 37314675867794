import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const TitleKind = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

const TitleSize = {
  default: 'default',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xxxl: 'xxxl',
};

const DefaultTitleStyles = {
  [TitleKind.h1]: TitleSize.xxxl,
  [TitleKind.h2]: TitleSize.xxl,
  [TitleKind.h3]: TitleSize.xl,
  [TitleKind.h4]: TitleSize.lg,
  [TitleKind.h5]: TitleSize.md,
  [TitleKind.h6]: TitleSize.sm,
};

const generateStyledComponent = ({ kind, size }) => {
  const StyledTitle = styled[kind]`
    font-family: ${({ theme }) => theme.fonts.primary.family};

    ${() => {
      const titleSize = size === TitleSize.default ? DefaultTitleStyles[kind] : size;

      return css`
        font-size: ${({ theme }) => theme.fonts.primary.size[titleSize]};
        line-height: ${({ theme }) => theme.fonts.primary.lineHeight[titleSize]};
      `;
    }}
  `;

  return StyledTitle;
};

// Ignored because kind is being checked in the parent component
// eslint-disable-next-line react/prop-types
const SelectedTitle = ({ kind, size, ...otherProps }) => {
  const StyledTitle = generateStyledComponent({ kind, size });

  return <StyledTitle kind={kind} size={size} {...otherProps} />;
};

const Title = ({ children, kind, ...props }) => (
  <SelectedTitle kind={kind} {...props}>
    {children}
  </SelectedTitle>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(Object.values(TitleKind)),
  size: PropTypes.oneOf(Object.values(TitleSize)),
};

Title.defaultProps = {
  kind: TitleKind.h1,
  size: TitleSize.default,
};

export { TitleKind, TitleSize };

export default Title;
