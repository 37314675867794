import styled, { css } from 'styled-components';
import * as yup from 'yup';

import Input, { InputType } from 'components/common/Input';
import RadioButton from 'components/common/RadioButton';
import FormControl from 'components/FormControls/FormControl';
import SelectFormControl from 'components/FormControls/SelectFormControl';
import { userRoles } from 'constants/users';

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem 0.75rem;

  ${({ theme }) => css`
    color: ${theme.colors.darkerGray};
    caret-color: ${theme.colors.darkerGray};
    font-size: ${theme.fonts.primary.size.xl};
    vertical-align: middle;
  `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${({ theme }) =>
      ` -webkit-box-shadow: 0 0 0 30px ${theme.colors.white} inset !important;
    -webkit-text-fill-color: ${theme.colors.darkerGray};
    -webkit-text-size-adjust: ${theme.fonts.primary.size.xl};`}
  }
`;

export const editUserFields = [
  {
    name: 'firstName',
    label: 'First Name',
    placeholder: 'First Name',
    type: InputType.Text,
    Control: StyledInput,
    FormControl: StyledFormControl,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    placeholder: 'Last Name',
    type: InputType.Text,
    Control: StyledInput,
    FormControl: StyledFormControl,
  },
  {
    name: 'email',
    label: 'Email',
    placeholder: 'Email',
    type: InputType.Email,
    Control: StyledInput,
    FormControl: StyledFormControl,
    disabled: true,
  },
  {
    name: 'role',
    label: 'Select Role',
    type: InputType.Radio,
    Control: RadioButton,
    FormControl: StyledFormControl,
    controllersContainerStyle: css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;

      ${({ theme }) =>
        css`
          @media screen and (min-width: ${theme.mediaQueries.tablet}) {
            gap: 3rem;
          }
        `}
    `,
    controllers: [
      {
        value: 'admin',
        label: 'Admin',
        disabled: true,
      },
      {
        value: 'dealer',
        label: 'Dealer',
        disabled: true,
      },
      {
        value: 'sales',
        label: 'Sales',
        disabled: true,
      },
    ],
  },
  {
    name: 'dealership',
    placeholder: 'Dealership',
    FormControl: SelectFormControl,
    conditionToShow: {
      field: 'role',
      isEqualTo: userRoles.dealer,
    },
  },
];

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  dealership: yup.string().nullable(),
  // .when('role', { is: userRoles.dealer, then: (schema) => schema.required('Dealership is Required') }),
});
