import React, { useEffect } from 'react';

import withLayout from 'hocs/withLayout';
import styled, { css, useTheme } from 'styled-components';
import { LoginImage } from 'icons';
import LoginForm from 'components/Forms/LoginForm/LoginForm';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import { shallowEqual, useSelector } from 'react-redux';
import { selectAuthData } from 'store/selectors/auth';
import { useNavigate } from 'react-router-dom';
import Paths from 'utils/constants/paths';
import { userRoles } from 'constants/users';

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(105.96deg, rgba(126, 192, 240, 0.13) 7.37%, rgba(234, 246, 254, 0) 106.88%);
  padding: 3rem 0;
`;

const Login = (props) => {
  const theme = useTheme();
  const isLargerTablet = useCustomMediaQuery(theme.mediaQueries.largerTablet);
  const { isLogged, role } = useSelector(selectAuthData, shallowEqual);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogged) {
      if (role === userRoles.admin) {
        navigate(Paths.Home);
      }

      if (role === userRoles.dealer) {
        window.location.href = 'https://leasebux.com';
      }
    }
  }, [isLogged, navigate, role]);

  return (
    <StyledSection {...props}>
      <div
        css={css`
          width: 100%;
          max-width: ${theme.mediaQueries.fullDesktop};
          display: flex;
          align-items: center;
          justify-content: center;

          ${css`
            @media screen and (min-width: ${theme.mediaQueries.largerTablet}) {
              justify-content: space-between;
            }
          `}
        `}
      >
        {isLargerTablet && <LoginImage />}
        <LoginForm />
      </div>
    </StyledSection>
  );
};

export default withLayout(Login);
