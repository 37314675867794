import React from 'react';
import PropTypes from 'prop-types';

const FormatQuoteIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.175 11C12.675 11 12.2917 10.7877 12.025 10.363C11.7583 9.93767 11.7417 9.5 11.975 9.05L13 7H11C10.45 7 9.97933 6.804 9.588 6.412C9.196 6.02067 9 5.55 9 5V2C9 1.45 9.196 0.979333 9.588 0.588C9.97933 0.196 10.45 0 11 0H14C14.55 0 15.021 0.196 15.413 0.588C15.8043 0.979333 16 1.45 16 2V6.525C16 6.675 15.9873 6.82933 15.962 6.988C15.9373 7.146 15.8833 7.29167 15.8 7.425L14.375 10.25C14.2583 10.4833 14.0917 10.6667 13.875 10.8C13.6583 10.9333 13.425 11 13.175 11ZM4.175 11C3.675 11 3.29167 10.7877 3.025 10.363C2.75833 9.93767 2.74167 9.5 2.975 9.05L4 7H2C1.45 7 0.979333 6.804 0.588 6.412C0.196 6.02067 0 5.55 0 5V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H5C5.55 0 6.021 0.196 6.413 0.588C6.80433 0.979333 7 1.45 7 2V6.525C7 6.675 6.98767 6.82933 6.963 6.988C6.93767 7.146 6.88333 7.29167 6.8 7.425L5.375 10.25C5.25833 10.4833 5.09167 10.6667 4.875 10.8C4.65833 10.9333 4.425 11 4.175 11Z"
      fill={color}
    />
  </svg>
);

FormatQuoteIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

FormatQuoteIcon.defaultProps = {
  color: '#212121',
  width: 16,
  height: 11,
};

export default FormatQuoteIcon;
