import React, { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { signInAction } from 'store/asyncActions';
import { selectAuthData } from 'store/selectors/auth';
import { authErrors } from 'utils/constants/firebaseErrors';
import { loginFields, validationSchema } from 'utils/formFields/loginFields';
import Button, { ButtonType } from 'components/common/Button';
import FormControl from 'components/FormControls/FormControl';
import Title from 'components/common/Title';
import Form from 'components/Forms/Form';
import { LoadingAnimatedIcon, LoginStarsIcon } from 'icons';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35.625rem;
  padding: 2rem 1.5rem;
  box-shadow: 0px 4px 32px 7px rgba(0, 0, 0, 0.04);
  gap: 2rem;
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};
      border-radius: ${theme.border.radius.xl};
    `};
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem 0.75rem;

  ${({ theme }) => css`
    color: ${theme.colors.darkerGray};
    caret-color: ${theme.colors.darkerGray};
    font-size: ${theme.fonts.primary.size.xl};
    vertical-align: middle;
  `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${({ theme }) =>
      ` -webkit-box-shadow: 0 0 0 30px ${theme.colors.white} inset !important;
    -webkit-text-fill-color: ${theme.colors.darkerGray};
    -webkit-text-size-adjust: ${theme.fonts.primary.size.xl};`}
  }
`;

const LoginForm = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [customErrors, setCustomErrors] = useState({});
  const [formState, setFormState] = useState({});
  const { error, loading } = useSelector(selectAuthData, shallowEqual);

  const errorCode = error?.code;

  useEffect(() => {
    if (errorCode) {
      if (errorCode === authErrors.userNotFound) {
        return setCustomErrors((prevState) => ({ ...prevState, email: true }));
      }

      if (errorCode === authErrors.userWrongPassword) {
        return setCustomErrors((prevState) => ({ ...prevState, password: true }));
      }
    }

    return setCustomErrors({});
  }, [errorCode]);

  const useFormProps = useMemo(
    () => ({
      mode: 'all',
      resolver: yupResolver(validationSchema),
    }),
    []
  );

  const onSubmit = (data) => {
    const { email, password } = data;

    dispatch(signInAction({ email, password }));
  };

  return (
    <StyledForm setFormState={setFormState} useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
          `}
        >
          <Title
            css={css`
              margin: 0;
              color: ${theme.colors.black};
            `}
          >
            Hello,
            <br />
            Welcome Back
          </Title>
          <LoginStarsIcon />
        </div>
        <span css="font-weight: 200;">Please enter your details</span>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        {loginFields.map(({ name, ...field }) => (
          <StyledFormControl
            key={name}
            inputContainerCss={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            `}
            submitError={!!error}
            name={name}
            customErrors={customErrors}
            {...field}
          />
        ))}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
          gap: 1.5rem;
        `}
      >
        <span
          css={css`
            color: ${theme.colors.primary};
          `}
        >
          Forgot password?
        </span>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          type={ButtonType.Submit}
          disabled={!formState?.isValid}
        >
          {loading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Sign in'
          )}
        </Button>
      </div>
    </StyledForm>
  );
};

export default LoginForm;
