import React, { useId } from 'react';
import styled from 'styled-components';

export const LabelPosition = {
  Right: 'right',
};

const labelPositioningStyles = {
  [LabelPosition.Right]: `flex-direction: row-reverse;`,
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  ${({ labelPosition }) => labelPositioningStyles[labelPosition]}
`;

// eslint-disable-next-line react/prop-types
const RadioButton = ({ onChange, name, register, label, value, labelPosition = 'right', ...props }) => {
  const inputId = `${name}-${useId()}`;

  return (
    <StyledDiv labelPosition={labelPosition}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        css="margin: 0;"
        id={inputId}
        onChange={onChange}
        name={name}
        value={value}
        type="radio"
        {...register?.(name)}
        {...props}
      />
    </StyledDiv>
  );
};

export default RadioButton;
