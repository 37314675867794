import firebase from 'services/firebase';
import { Users } from 'enums/cloudFunctions';

const getUserByToken = (token) => {
  const httpsGetUserByToken = firebase.functions().httpsCallable(Users.GetUserByToken);

  return httpsGetUserByToken({ userToken: token });
};

export default getUserByToken;
