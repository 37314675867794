import React from 'react';

const BigWarningIcon = () => (
  <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="45" cy="45" r="43" stroke="#A31621" strokeWidth="4" />
    <g clipPath="url(#clip0_1069_18506)">
      <mask
        id="mask0_1069_18506"
        style={{ 'mask-type': 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="19"
        y="19"
        width="52"
        height="52"
      >
        <rect x="19.9414" y="19.293" width="50.7569" height="50.7569" rx="25.3784" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1069_18506)">
        <path
          d="M45.3198 49.1125C43.9766 49.1125 42.8877 48.0236 42.8877 46.6804V31.9292C42.8877 30.586 43.9766 29.4971 45.3198 29.4971V29.4971C46.663 29.4971 47.7519 30.586 47.7519 31.9292V46.6804C47.7519 48.0236 46.663 49.1125 45.3198 49.1125V49.1125ZM45.2934 59.8455C43.9647 59.8455 42.8877 58.7684 42.8877 57.4398V57.4398C42.8877 56.1112 43.9647 55.0341 45.2934 55.0341H45.3462C46.6748 55.0341 47.7519 56.1112 47.7519 57.4398V57.4398C47.7519 58.7684 46.6748 59.8455 45.3462 59.8455H45.2934Z"
          fill="#A31621"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1069_18506">
        <rect width="50.7541" height="50.7541" fill="white" transform="translate(19.623 19.6235)" />
      </clipPath>
    </defs>
  </svg>
);
export default BigWarningIcon;
