import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ToastContainerPosition from 'enums/toastContainerPosition';

const ToastCustomContainer = () => (
  <ToastContainer
    position={ToastContainerPosition.BottomRight}
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={3}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export default ToastCustomContainer;
