import React, { useMemo, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { clearCreateUserState } from 'store/slices/usersSlice';
import { selectCreateUserState } from 'store/selectors/users';
import { createUserAction, getAllDealershipsAction, getUsersAction } from 'store/asyncActions';
import { selectAllDealershipsState } from 'store/selectors/dealerships';
import { addUserFields, validationSchema } from 'utils/formFields/addUserFields';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';
import { LoadingAnimatedIcon } from 'icons';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  gap: 2rem;
  margin-top: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        width: 50vw;
      }

      @media screen and (min-width: ${theme.mediaQueries.largerTabet}) {
        width: 35vw;
      }
    `};
`;

const AddUserForm = ({ toggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { createUserLoading, createUserSuccess, createUserError } = useSelector(selectCreateUserState, shallowEqual);

  const { dealerships } = useSelector(selectAllDealershipsState, shallowEqual);

  useEffect(() => {
    dispatch(getAllDealershipsAction());
  }, [dispatch]);

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    }),
    []
  );

  const onSubmit = useCallback(
    (data) => {
      dispatch(createUserAction(data));
    },
    [dispatch]
  );

  useEffect(() => {
    if (createUserSuccess) {
      dispatch(getUsersAction());
      toggle();
    }
  }, [createUserSuccess, dispatch, toggle]);

  useEffect(() => () => dispatch(clearCreateUserState()), [dispatch]);

  useEffect(() => {
    if (createUserError?.message) {
      toast.error(createUserError.message);
    }
  }, [createUserError]);

  const dealershipSelectOptions = dealerships.map(({ id, name }) => ({ value: id, label: name }));

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        {addUserFields.map(({ name, FormControl, ...field }) => (
          <FormControl
            key={name}
            inputContainerCss={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            `}
            options={dealershipSelectOptions}
            name={name}
            {...field}
          />
        ))}
      </div>

      <div
        css={css`
          display: flex;
          width: 100%;
          gap: 0.5rem;
        `}
      >
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
          `}
          disabled={createUserLoading}
          kind={ButtonKind.Secondary}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          disabled={createUserLoading}
          type={ButtonType.Submit}
        >
          {createUserLoading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Invite User'
          )}
        </Button>
      </div>
    </StyledForm>
  );
};

AddUserForm.propTypes = {
  toggle: PropTypes.func,
};

AddUserForm.defaultProps = {
  toggle: null,
};

export default AddUserForm;
