import React from 'react';
import styled from 'styled-components';

import CommonLayout from 'components/layout/CommonLayout';

const generateStyledComponent = (Component) => styled(Component)`
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

const withLayout = (Component) => {
  const StyledComponent = generateStyledComponent(Component);

  const Layout = (props) => (
    <CommonLayout>
      <StyledComponent {...props} />
    </CommonLayout>
  );

  if (Component.getInitialProps) {
    Layout.getInitialProps = Component.getInitialProps;
  }

  return Layout;
};

export default withLayout;
