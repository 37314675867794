import React from 'react';
import PropTypes from 'prop-types';

const BurguerIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7H19M1 1H19M1 13H19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

BurguerIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

BurguerIcon.defaultProps = {
  color: '#212121',
  width: 20,
  height: 14,
};

export default BurguerIcon;
