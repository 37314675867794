import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

const StyledParagraph = styled.p`
  margin: 0;

  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-size: ${theme.fonts.primary.size.sm};
  `}
`;

const ErrorMessage = ({ children }) => <StyledParagraph>{children}</StyledParagraph>;

ErrorMessage.propTypes = {
  children: PropTypes.node,
};

ErrorMessage.defaultProps = {
  children: undefined,
};

export default ErrorMessage;
