import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const BadgeContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 0.438rem;
  width: fit-content;
  line-height: 1.125rem;

  ${({ bgColor, textColor, padding, theme }) =>
    css`
      padding: ${padding};
      border-radius: ${theme.border.radius.xxl};
      background-color: ${bgColor || theme.colors.lightGray};
      color: ${textColor || theme.colors.black};
    `}
`;

const Badge = ({ bgColor, textColor, padding, Icon, children, ...rest }) => (
  <BadgeContainer bgColor={bgColor} textColor={textColor} padding={padding} {...rest}>
    {Icon && <Icon />}
    {children}
  </BadgeContainer>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  bgColor: PropTypes.string,
  padding: PropTypes.string,
  textColor: PropTypes.string,
};

Badge.defaultProps = {
  Icon: null,
  bgColor: null,
  textColor: null,
  padding: '0.125rem 0.5rem',
};

export default Badge;
