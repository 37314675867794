/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './slices';

const customizedMiddleware = {
  serializableCheck: false,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(customizedMiddleware),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./slices', () => store.replaceReducer(rootReducer));
}

export default store;
