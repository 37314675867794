/* eslint-disable no-param-reassign */

export const CLEAR_OEMS_STATE = (state) => {
  state.loading = false;
  state.success = false;
  state.failed = false;
  state.error = null;
  state.oems = [];
};

export const UPDATE_SELECTED_OEMS_DEALERSHIPS_TABLE = (state, { payload }) => {
  state.dealershipsTableSelectedOems = payload;
};
