import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { clearUsersState } from 'store/slices/usersSlice';
import { selectAuthData } from 'store/selectors/auth';
import withLayout from 'hocs/withLayout';
import Paths from 'utils/constants/paths';
import { userRoles } from 'constants/users';
import ActivateUserAccountForm from 'components/Forms/ActivateUserAccountForm';

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(105.96deg, rgba(126, 192, 240, 0.13) 7.37%, rgba(234, 246, 254, 0) 106.88%);
  padding: 3rem 0;
`;

const ActivateUserAccount = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { confirmToken } = useParams();
  const { isLogged, role } = useSelector(selectAuthData, shallowEqual);

  useEffect(() => {
    if (isLogged && role === userRoles.admin) {
      dispatch(clearUsersState());
      navigate(Paths.Home);
    }
  }, [isLogged, navigate, role, dispatch]);

  return (
    <StyledSection {...props}>
      <div
        css={css`
          width: 100%;
          max-width: ${theme.mediaQueries.fullDesktop};
          display: flex;
          justify-content: center;
        `}
      >
        <ActivateUserAccountForm confirmToken={confirmToken} />
      </div>
    </StyledSection>
  );
};

export default withLayout(ActivateUserAccount);
