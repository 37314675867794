import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import ThemeProvider from 'contexts/ThemeProvider';
import StylesProvider from 'contexts/StylesProvider';
import ToastCustomContainer from 'components/common/ToastCustomContainer';
import store from 'store';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
`;

const persistor = persistStore(store);

const RootProvider = ({ children }) => (
  <ThemeProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyles />
        <ToastCustomContainer />
        <StylesProvider>{children}</StylesProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

RootProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootProvider;
