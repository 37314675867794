import { getBlob } from 'firebase/storage';

import firebase from 'services/firebase';

const getStorageRef = (path) => firebase.storage().ref(path);

export const uploadFile = (path, file) => firebase.storage().ref(path).put(file);

export const removeFile = (path) => getStorageRef(path).delete();

export const getFileUrl = (path) => getStorageRef(path).getDownloadURL();

export const getFileBlob = (storagePath) => getBlob(getStorageRef(storagePath));

export const uploadFileAndGetUrl = (path, file) =>
  new Promise((resolve, reject) => {
    const uploadTask = uploadFile(path, file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => {},
      (error) => {
        reject(error);
      },
      async () => {
        const imgURL = await uploadTask.snapshot.ref.getDownloadURL();

        resolve(imgURL);
      }
    );
  });
