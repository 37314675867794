/* eslint-disable no-param-reassign */

export const CLEAR_CONFIRM_ACTIVATE_ACCOUNT = (state) => {
  state.confirmAdminAccountFailed = false;
  state.confirmAdminAccountLoading = false;
  state.confirmAdminAccountSuccess = false;
};

export const CLEAN_ACTIVATE_USER_ACCOUNT = (state) => {
  state.activateUserAccountFailed = false;
  state.activateUserAccountLoading = false;
  state.activateUserAccountSuccess = false;
};

export const CLEAR_AUTH_STATE = (state) => {
  state.loading = false;
  state.success = false;
  state.failed = false;
  state.error = null;
  state.uid = null;
  state.role = null;
  state.name = null;
  state.email = null;
  state.activateUserAccountFailed = false;
  state.activateUserAccountLoading = false;
  state.activateUserAccountSuccess = false;
};
