import { createAsyncThunk } from '@reduxjs/toolkit';

import editDealership from 'services/dealerships/editDealership';

export const editDealershipAction = createAsyncThunk(
  'dealerships/editDealership',
  async ({ dealershipId, dealershipName, address, oems, dealershipImage, dealerContract, fieldsToUpdate }) => {
    const uid = await editDealership({
      dealershipId,
      name: dealershipName,
      address,
      oems,
      image: dealershipImage,
      contract: dealerContract,
      fieldsToUpdate,
    });

    return uid;
  }
);
