/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { CLEAR_OEMS_STATE, UPDATE_SELECTED_OEMS_DEALERSHIPS_TABLE } from 'store/actions';
import { getAllOemsAction } from 'store/asyncActions';

const initialState = {
  fetchAllOemsLoading: false,
  fetchAllOemsSuccess: false,
  fetchAllOemsFailed: false,
  fetchAllOemsError: null,
  dealershipsTableSelectedOems: [],
  oems: [],
};

const reducers = {
  clearOemsState: CLEAR_OEMS_STATE,
  updateSelectedOemsDealershipsTable: UPDATE_SELECTED_OEMS_DEALERSHIPS_TABLE,
};

const extraReducers = {
  [getAllOemsAction.pending]: (state) => {
    state.fetchAllOemsLoading = true;
    state.fetchAllOemsFailed = false;
    state.fetchAllOemsSuccess = false;
    state.fetchAllOemsError = null;
    state.oems = initialState.oems;
  },
  [getAllOemsAction.rejected]: (state, { error }) => {
    state.fetchAllOemsFailed = true;
    state.fetchAllOemsLoading = false;
    state.fetchAllOemsError = error;
  },
  [getAllOemsAction.fulfilled]: (state, { payload }) => {
    state.fetchAllOemsLoading = false;
    state.fetchAllOemsSuccess = true;
    state.oems = payload.oems;
  },
};

const persistConfig = {
  key: 'oems',
  storage,
  blackList: [
    'error',
    'fetchAllOemsLoading',
    'fetchAllOemsSuccess',
    'fetchAllOemsFailed',
    'fetchAllOemsError',
    'dealershipsTableSelectedOems',
    'oems',
  ],
};

const { reducer, actions } = createSlice({
  name: 'oems',
  initialState,
  reducers,
  extraReducers,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const { clearOemsState, updateSelectedOemsDealershipsTable } = actions;

export default persistedReducer;
