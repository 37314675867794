/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  CLEAR_BLOCK_USER_STATE,
  CLEAR_CREATE_USER_STATE,
  CLEAR_DELETE_USER_STATE,
  CLEAR_EDIT_USER_STATE,
  CLEAR_USERS_STATE,
  UPDATE_SEARCH_TABLE_INPUT,
  UPDATE_USERS_TABLE_FILTERS,
} from 'store/actions';
import {
  createUserAction,
  editUserAction,
  getUserDataAction,
  getUserByTokenAction,
  getUsersAction,
  deleteUserAction,
  blockUserAction,
} from 'store/asyncActions';

import { Sort } from 'enums/filters';

const initialState = {
  fetchUserLoading: false,
  fetchUserSuccess: false,
  fetchUserFailed: false,
  createUserLoading: false,
  createUserSuccess: false,
  createUserFailed: false,
  createUserError: null,
  editUserLoading: false,
  editUserSuccess: false,
  editUserFailed: false,
  editUserError: null,
  deleteUserLoading: false,
  deleteUserSuccess: false,
  deleteUserFailed: false,
  deleteUserError: null,
  blockUserLoading: false,
  blockUserSuccess: false,
  blockUserFailed: false,
  blockUserError: null,
  getUserDataLoading: false,
  getUserDataSuccess: false,
  getUserDataFailed: false,
  getUserByTokenLoading: false,
  getUserByTokenSuccess: false,
  getUserByTokenFailed: false,
  usersSearchTableInput: { column: 'name', value: '' },
  users: [],
  userData: {},
  usersTableFilters: { role: {}, status: {}, sorting: Sort.Ascending },
};

const reducers = {
  clearCreateUserState: CLEAR_CREATE_USER_STATE,
  clearEditUserState: CLEAR_EDIT_USER_STATE,
  clearDeleteUserState: CLEAR_DELETE_USER_STATE,
  clearBlockUserState: CLEAR_BLOCK_USER_STATE,
  clearUsersState: CLEAR_USERS_STATE,
  updateUsersTableFilters: UPDATE_USERS_TABLE_FILTERS,
  updateSearchTableInput: UPDATE_SEARCH_TABLE_INPUT,
};

const extraReducers = {
  [getUserDataAction.pending]: (state) => {
    state.fetchUserLoading = true;
    state.fetchUserFailed = false;
    state.fetchUserSuccess = false;
  },
  [getUserDataAction.rejected]: (state) => {
    state.fetchUserFailed = true;
    state.fetchUserLoading = false;
  },
  [getUserDataAction.fulfilled]: (state, { payload }) => {
    state.fetchUserLoading = false;
    state.fetchUserSuccess = true;
    state.userData = payload.userData;
  },
  [getUsersAction.pending]: (state) => {
    state.fetchUserLoading = true;
    state.fetchUserFailed = false;
    state.fetchUserSuccess = false;
  },
  [getUsersAction.rejected]: (state) => {
    state.fetchUserFailed = true;
    state.fetchUserLoading = false;
  },
  [getUsersAction.fulfilled]: (state, { payload }) => {
    state.fetchUserLoading = false;
    state.fetchUserSuccess = true;
    state.users = payload.users;
  },
  [createUserAction.pending]: (state) => {
    state.createUserLoading = true;
    state.createUserFailed = false;
    state.createUserSuccess = false;
  },
  [createUserAction.rejected]: (state, { error }) => {
    state.createUserFailed = true;
    state.createUserLoading = false;
    state.createUserError = error;
  },
  [createUserAction.fulfilled]: (state) => {
    state.createUserLoading = false;
    state.createUserSuccess = true;
  },
  [editUserAction.pending]: (state) => {
    state.editUserLoading = true;
    state.editUserFailed = false;
    state.editUserSuccess = false;
  },
  [editUserAction.rejected]: (state, { error }) => {
    state.editUserFailed = true;
    state.editUserLoading = false;
    state.editUserError = error;
  },
  [editUserAction.fulfilled]: (state) => {
    state.editUserLoading = false;
    state.editUserSuccess = true;
  },
  [deleteUserAction.pending]: (state) => {
    state.deleteUserLoading = true;
    state.deleteUserFailed = false;
    state.deleteUserSuccess = false;
  },
  [deleteUserAction.rejected]: (state, { error }) => {
    state.deleteUserFailed = true;
    state.deleteUserLoading = false;
    state.deleteUserError = error;
  },
  [deleteUserAction.fulfilled]: (state) => {
    state.deleteUserLoading = false;
    state.deleteUserSuccess = true;
  },
  [blockUserAction.pending]: (state) => {
    state.blockUserLoading = true;
    state.blockUserFailed = false;
    state.blockUserSuccess = false;
  },
  [blockUserAction.rejected]: (state, { error }) => {
    state.blockUserFailed = true;
    state.blockUserLoading = false;
    state.blockUserError = error;
  },
  [blockUserAction.fulfilled]: (state) => {
    state.blockUserLoading = false;
    state.blockUserSuccess = true;
  },
  [getUserByTokenAction.pending]: (state) => {
    state.getUserByTokenLoading = true;
    state.getUserByTokenFailed = false;
    state.getUserByTokenSuccess = false;
  },
  [getUserByTokenAction.rejected]: (state, { error }) => {
    state.getUserByTokenFailed = true;
    state.getUserByTokenLoading = false;
    state.getUserByTokenError = error;
  },
  [getUserByTokenAction.fulfilled]: (state, { payload }) => {
    state.getUserByTokenLoading = false;
    state.getUserByTokenSuccess = true;
    state.userData = payload.userData;
  },
};

const persistConfig = {
  key: 'users',
  storage,
  blackList: [
    'fetchUserError',
    'fetchUserLoading',
    'fetchUserSuccess',
    'usersSearchTableInput',
    'userData',
    'users',
    'usersTableFilters',
    'createUserLoading',
    'createUserSuccess',
    'createUserFailed',
    'createUserError',
    'editUserLoading',
    'editUserSuccess',
    'editUserFailed',
    'editUserError',
    'deleteUserLoading',
    'deleteUserSuccess',
    'deleteUserFailed',
    'deleteUserError',
    'blockUserLoading',
    'blockUserSuccess',
    'blockUserFailed',
    'blockUserError',
  ],
};

const { reducer, actions } = createSlice({
  name: 'users',
  initialState,
  reducers,
  extraReducers,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const {
  clearCreateUserState,
  clearEditUserState,
  clearDeleteUserState,
  clearBlockUserState,
  clearUsersState,
  updateUsersTableFilters,
  updateSearchTableInput,
} = actions;

export default persistedReducer;
