import { createAsyncThunk } from '@reduxjs/toolkit';

import editUser from 'services/users/editUser';

export const editUserAction = createAsyncThunk(
  'users/editUser',
  async ({ id, firstName, lastName, email, dealership }) => {
    const uid = await editUser({ uid: id, firstName, lastName, email, dealership });

    return uid;
  }
);
