import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ color, width, height, title }) => (
  <svg width={width} height={height} viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <path d="M1 6L6 11L16 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

CheckIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
};

CheckIcon.defaultProps = {
  color: '#ffffff',
  width: 12,
  height: 17,
  title: '',
};

export default CheckIcon;
