import React from 'react';
import PropTypes from 'prop-types';

const ThreeDotsIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 18C2.9 18 1.95833 17.6083 1.175 16.825C0.391667 16.0417 0 15.1 0 14C0 12.9 0.391667 11.9583 1.175 11.175C1.95833 10.3917 2.9 10 4 10C5.1 10 6.04167 10.3917 6.825 11.175C7.60833 11.9583 8 12.9 8 14C8 15.1 7.60833 16.0417 6.825 16.825C6.04167 17.6083 5.1 18 4 18ZM16 18C14.9 18 13.9583 17.6083 13.175 16.825C12.3917 16.0417 12 15.1 12 14C12 12.9 12.3917 11.9583 13.175 11.175C13.9583 10.3917 14.9 10 16 10C17.1 10 18.0417 10.3917 18.825 11.175C19.6083 11.9583 20 12.9 20 14C20 15.1 19.6083 16.0417 18.825 16.825C18.0417 17.6083 17.1 18 16 18ZM10 8C8.9 8 7.95833 7.60833 7.175 6.825C6.39167 6.04167 6 5.1 6 4C6 2.9 6.39167 1.95833 7.175 1.175C7.95833 0.391667 8.9 0 10 0C11.1 0 12.0417 0.391667 12.825 1.175C13.6083 1.95833 14 2.9 14 4C14 5.1 13.6083 6.04167 12.825 6.825C12.0417 7.60833 11.1 8 10 8Z"
      fill={color}
    />
  </svg>
);

ThreeDotsIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ThreeDotsIcon.defaultProps = {
  color: '#212121',
  width: 20,
  height: 18,
};

export default ThreeDotsIcon;
