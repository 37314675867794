import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import useModal from 'hooks/useModal';
import { userStatus } from 'constants/users';
import Modal from 'components/common/Modals/Modal';
import EditUserForm from 'components/Forms/EditUserForm';
import DeleteUserForm from 'components/Forms/DeleteUserForm';
import BlockUserForm from 'components/Forms/BlockUserForm';
import Menu from 'components/common/Menu';
import { BlockIcon, EditIcon, EllipsisIcon, TrashIcon } from 'icons';

const ActionButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  border: none;
  background: none;

  :hover {
    cursor: pointer;
  }

  :active {
    ${({ isBlocked }) =>
      isBlocked
        ? css`
            filter: invert(0.9);
          `
        : css`
            filter: brightness(0);
          `}
  }
`;

const MenuItem = styled.span`
  display: flex;
  gap: 0.75rem;
  align-items: baseline;
  padding: 1rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const MenuIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;

/* eslint-disable react/prop-types */
const MenuIcon = ({ active }) => {
  const theme = useTheme();

  return (
    <span
      css={css`
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        ${active &&
        css`
          background-color: ${theme.colors.lightGray};
        `}
      `}
    >
      <EllipsisIcon color={theme.colors.darkerGray} />
    </span>
  );
};
/* eslint-enable react/prop-types */

const UserActions = ({ row: { original }, userData }) => {
  const theme = useTheme();
  const { isOpen, toggle } = useModal();
  const { email, firstName, id, lastName, role, status, icon, dealership } = original || userData;
  const isBlocked = userStatus[status] === userStatus.blocked;
  const [{ Content, title }, setActionModal] = useState({ Content: null, title: null });

  const actionsUserData = { icon, firstName, lastName, email, role, id, dealership };

  const onClickEditAction = () => {
    setActionModal((prevState) => ({
      ...prevState,
      Content: <EditUserForm userData={actionsUserData} toggle={toggle} />,
      title: 'User Details',
    }));
    toggle();
  };

  const onClickDeleteAction = () => {
    setActionModal((prevState) => ({
      ...prevState,
      Content: <DeleteUserForm userData={actionsUserData} toggle={toggle} />,
      title: null,
    }));
    toggle();
  };

  const onClickBlockAction = () => {
    setActionModal((prevState) => ({
      ...prevState,
      Content: <BlockUserForm userData={actionsUserData} toggle={toggle} />,
      title: null,
    }));
    toggle();
  };

  const mobileActionsMenuItems = [
    {
      key: `block-${firstName}${lastName}${email}${role}${status}`,
      component: (
        <MenuItem
          css={css`
            border-bottom: 1px solid ${theme.colors.lightGray};
          `}
        >
          <MenuIconContainer>
            <BlockIcon color={theme.colors.mediumGray} />
          </MenuIconContainer>
          Block
        </MenuItem>
      ),
      onClick: onClickBlockAction,
    },
    {
      key: `delete-${firstName}${lastName}${email}${role}${status}`,
      component: (
        <MenuItem>
          <MenuIconContainer>
            <TrashIcon color={theme.colors.mediumGray} />
          </MenuIconContainer>
          Delete
        </MenuItem>
      ),
      onClick: onClickDeleteAction,
    },
    {
      key: `edit-${firstName}${lastName}${email}${role}${status}`,
      component: (
        <MenuItem
          css={css`
            border-top: 1px solid ${theme.colors.lightGray};
          `}
        >
          <MenuIconContainer>
            <EditIcon color={theme.colors.mediumGray} />
          </MenuIconContainer>
          Edit
        </MenuItem>
      ),
      onClick: onClickEditAction,
    },
  ];

  const jsxToReturn = original ? (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <ActionButton
        css={css`
          ${isBlocked
            ? `
              padding: 0.688rem;
              background-color: ${theme.colors.mediumDarkGray};
              border-radius: 50%;
              `
            : `
                padding: 0.688rem;
              `}
        `}
        isBlocked={isBlocked}
        onClick={onClickBlockAction}
      >
        <BlockIcon color={isBlocked ? theme.colors.black : theme.colors.darkerGray} />
      </ActionButton>
      <ActionButton
        css={css`
          margin-left: 1.75rem;
        `}
        onClick={onClickDeleteAction}
      >
        <TrashIcon color={theme.colors.darkerGray} />
      </ActionButton>
      <ActionButton
        css={css`
          margin-left: 1.75rem;
        `}
        onClick={onClickEditAction}
      >
        <EditIcon color={theme.colors.darkerGray} />
      </ActionButton>
    </div>
  ) : (
    <Menu
      Icon={MenuIcon}
      items={mobileActionsMenuItems}
      styledMenuItemsStyles={css`
        min-width: 7.375rem;
        z-index: ${theme.zIndex.higher};
      `}
    />
  );

  return (
    <>
      {jsxToReturn}
      <Modal title={title} isOpen={isOpen} toggle={toggle}>
        {Content}
      </Modal>
    </>
  );
};

UserActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      status: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
      role: PropTypes.string,
      icon: PropTypes.string,
      Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
      dealership: PropTypes.string,
    }),
  }),
  userData: PropTypes.shape({
    status: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    role: PropTypes.string,
    icon: PropTypes.string,
    Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
    dealership: PropTypes.string,
  }),
};

UserActions.defaultProps = {
  row: { original: null },
  userData: {},
};

export default UserActions;
