import React from 'react';
import PropTypes from 'prop-types';

const EndEnhancer = ({ children, css }) => (
  <div css={css} aria-label="end enhancer">
    {children}
  </div>
);

EndEnhancer.propTypes = {
  children: PropTypes.node.isRequired,
  css: PropTypes.string,
};

EndEnhancer.defaultProps = {
  css: '',
};

export default EndEnhancer;
