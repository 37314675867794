import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { updateDealershipsSearchTableValue } from 'store/slices/dealershipsSlice';
import { updateSearchTableInput } from 'store/slices/usersSlice';
import Button, { ButtonKind } from 'components/common/Button';
import DealershipMiddleContent from 'components/pages/Home/MiddleContent/DealershipMiddleContent';
import FiltersToDealershipTableForm from 'components/Forms/FiltersToDealershipTableForm';
import UserMiddleContent from 'components/pages/Home/MiddleContent/UserMiddleContent';
import Input from 'components/common/Input';
import Menu from 'components/common/Menu';
import { FilterLinesIcon, SearchIcon } from 'icons';
import FiltersToUsersTableForm from 'components/Forms/FiltersToUsersTableForm';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import MobileModal from 'components/common/MobileModal';

const MiddleContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 0.75rem 1.25rem;

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    padding: 0.75rem 3.75rem;
  }
`;

const FiltersContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 38.375rem;

  ${({ theme }) =>
    css`
      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        gap: 0.75rem;
      }
    `};
`;

const StyledInput = styled(Input)`
  height: 100%;
  padding-left: 2.625rem;
`;

const StyledButton = styled(Button)`
  height: 2.625rem;
  margin: 0rem 1rem 0 0.5rem;

  ${({ theme }) =>
    css`
      color: ${theme.colors.black};
      border-color: ${theme.colors.outline};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        margin: none;
      }
    `};
`;

/* eslint-disable react/prop-types */
const FilterButton = ({ onClick }) => {
  const theme = useTheme();
  const isTablet = useCustomMediaQuery(theme.mediaQueries.tablet);

  return (
    <StyledButton onClick={onClick} kind={ButtonKind.Ghost}>
      <FilterLinesIcon /> {isTablet && 'Filters'}
    </StyledButton>
  );
};

const FiltersMenu = ({ items, itemsPosition }) => (
  <Menu items={items} itemsPosition={itemsPosition} Icon={FilterButton} />
);
/* eslint-enable react/prop-types */

const defaultInputState = {
  selectedIndex0: '',
  selectedIndex1: '',
};

const defaultQueryState = { column: 'name', value: '' };

const TabsMiddleContent = ({ selectedIndex }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const isTablet = useCustomMediaQuery(theme.mediaQueries.tablet);
  const { isOpen, toggle } = useModal();

  const [query, setQuery] = useState(defaultQueryState);
  const [inputValue, setInputValue] = useState(defaultInputState);

  const isUsersSelected = selectedIndex === 0;
  const currentInputKey = `selectedIndex${selectedIndex}`;

  const onChangeInputValue = ({ target: { value } }) => {
    setInputValue((prevState) => ({ ...prevState, [currentInputKey]: value }));
    setQuery((prevState) => ({ ...prevState, value }));
  };

  useEffect(() => {
    const { column, value } = query;

    if (column && value === inputValue[currentInputKey]) {
      const timeoutId = setTimeout(() => {
        if (isUsersSelected) {
          return dispatch(updateSearchTableInput({ column, value }));
        }

        return dispatch(updateDealershipsSearchTableValue({ column, value }));
      }, 300);

      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [dispatch, query, isUsersSelected, inputValue, currentInputKey]);

  const menuItems = useMemo(
    () =>
      isUsersSelected
        ? [
            {
              key: 'users',
              component: <FiltersToUsersTableForm />,
              customHover: true,
              onClick: (e) => {
                e.currentTarget?.preventDefault();
              },
            },
          ]
        : [
            {
              key: 'oems',
              component: <FiltersToDealershipTableForm />,
              customHover: true,
              onClick: (e) => {
                e.currentTarget?.preventDefault();
              },
            },
          ],
    [isUsersSelected]
  );

  return (
    <>
      <MiddleContentWrapper selectedIndex={selectedIndex}>
        <FiltersContainer>
          <span
            css={css`
              position: absolute;
              z-index: ${theme.zIndex.modal};
              top: 0.875rem;
              left: 1rem;
            `}
          >
            <SearchIcon />
          </span>
          <StyledInput onChange={onChangeInputValue} value={inputValue[currentInputKey]} placeholder="Search" />
          {isTablet ? (
            <FiltersMenu itemsPosition={{ top: 'calc(100% + 0.5rem)' }} items={menuItems} />
          ) : (
            <FilterButton onClick={toggle} />
          )}
        </FiltersContainer>
        {isUsersSelected ? <UserMiddleContent /> : <DealershipMiddleContent />}
      </MiddleContentWrapper>
      {!isTablet && (
        <MobileModal isOpen={isOpen} toggle={toggle}>
          {isUsersSelected ? (
            <FiltersToUsersTableForm toggle={toggle} />
          ) : (
            <FiltersToDealershipTableForm toggle={toggle} />
          )}
        </MobileModal>
      )}
    </>
  );
};

TabsMiddleContent.propTypes = {
  selectedIndex: PropTypes.number,
};

TabsMiddleContent.defaultProps = {
  selectedIndex: 0,
};

export default TabsMiddleContent;
