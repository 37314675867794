/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { CLEAN_ACTIVATE_USER_ACCOUNT, CLEAR_AUTH_STATE, CLEAR_CONFIRM_ACTIVATE_ACCOUNT } from 'store/actions';
import { signInAction, signOutAction, activateUserAccountAction } from 'store/asyncActions';

const initialState = {
  loading: false,
  success: false,
  failed: false,
  error: null,
  uid: null,
  role: null,
  name: null,
  email: null,
  activateUserAccountLoading: false,
  activateUserAccountSuccess: false,
  activateUserAccountFailed: false,
};

const reducers = {
  clearActivateUserAccountState: CLEAN_ACTIVATE_USER_ACCOUNT,
  clearConfirmAdminAccountState: CLEAR_CONFIRM_ACTIVATE_ACCOUNT,
  clearAuthState: CLEAR_AUTH_STATE,
};

const extraReducers = {
  [signInAction.pending]: (state) => {
    state.loading = true;
    state.failed = false;
    state.success = false;
    state.error = null;
  },
  [signInAction.rejected]: (state, { error }) => {
    state.failed = true;
    state.loading = false;
    state.error = error;
  },
  [signInAction.fulfilled]: (state, { payload }) => {
    state.loading = false;
    state.success = true;
    state.uid = payload.uid;
    state.role = payload.role;
    state.name = payload.name;
    state.email = payload.email;
  },
  [signOutAction.pending]: (state) => {
    state.loading = true;
    state.failed = false;
    state.success = false;
  },
  [signOutAction.rejected]: (state) => {
    state.failed = true;
    state.loading = false;
  },
  [signOutAction.fulfilled]: (state) => {
    state.loading = false;
    state.success = true;
    state.uid = initialState.uid;
    state.role = initialState.role;
    state.name = initialState.name;
    state.email = initialState.email;
  },
  [activateUserAccountAction.pending]: (state) => {
    state.activateUserAccountLoading = true;
    state.activateUserAccountFailed = false;
    state.activateUserAccountSuccess = false;
  },
  [activateUserAccountAction.rejected]: (state) => {
    state.activateUserAccountFailed = true;
    state.activateUserAccountLoading = false;
    state.activateUserAccountSuccess = false;
  },
  [activateUserAccountAction.fulfilled]: (state) => {
    state.activateUserAccountLoading = false;
    state.activateUserAccountSuccess = true;
  },
};

const persistConfig = {
  key: 'auth',
  storage,
  blackList: [
    'error',
    'loading',
    'success',
    'activateUserAccountLoading',
    'activateUserAccountSuccess',
    'activateUserAccountFailed',
  ],
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers,
  extraReducers,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const { clearActivateUserAccountState, clearConfirmAdminAccountState, clearAuthState } = actions;

export default persistedReducer;
