/* eslint-disable import/prefer-default-export */

import { createSelector } from '@reduxjs/toolkit';

const selectUsersState = ({ users }) => users;

export const selectUserDataState = createSelector(selectUsersState, ({ userData }) => userData);

export const selectUserTableSearchState = createSelector(
  selectUsersState,
  ({ usersSearchTableInput }) => usersSearchTableInput
);

export const selectFetchAllUsers = createSelector(
  selectUsersState,
  ({ fetchUserLoading, fetchUserFailed, fetchUserSuccess, users }) => ({
    fetchUserLoading,
    fetchUserFailed,
    fetchUserSuccess,
    users,
  })
);

export const selectUsersTableFiltersState = createSelector(
  selectUsersState,
  ({ usersTableFilters }) => usersTableFilters
);

export const selectCreateUserState = createSelector(
  selectUsersState,
  ({ createUserLoading, createUserSuccess, createUserFailed, createUserError }) => ({
    createUserLoading,
    createUserSuccess,
    createUserFailed,
    createUserError,
  })
);

export const selectEditUserState = createSelector(
  selectUsersState,
  ({ editUserLoading, editUserSuccess, editUserFailed, editUserError }) => ({
    editUserLoading,
    editUserSuccess,
    editUserFailed,
    editUserError,
  })
);

export const selectDeleteUserState = createSelector(
  selectUsersState,
  ({ deleteUserLoading, deleteUserSuccess, deleteUserFailed, deleteUserError }) => ({
    deleteUserLoading,
    deleteUserSuccess,
    deleteUserFailed,
    deleteUserError,
  })
);

export const selectGetUserByTokenState = createSelector(
  selectUsersState,
  ({ getUserByTokenLoading, getUserByTokenSuccess, getUserByTokenFailed }) => ({
    getUserByTokenLoading,
    getUserByTokenSuccess,
    getUserByTokenFailed,
  })
);

export const selectBlockUserState = createSelector(
  selectUsersState,
  ({ blockUserLoading, blockUserSuccess, blockUserFailed, blockUserError }) => ({
    blockUserLoading,
    blockUserSuccess,
    blockUserFailed,
    blockUserError,
  })
);
