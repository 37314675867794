import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import Footer from 'components/navigation/Footer';
import NavBar from 'components/navigation/NavBar';

const CommonLayout = ({ children }) => {
  const theme = useTheme();

  return (
    <main
      css={`
        padding-top: 4.5rem;

        @media screen and (min-width: ${theme.mediaQueries.tablet}) {
          padding-top: 5.75rem;
        }
      `}
    >
      <NavBar />
      {children}
      <Footer />
    </main>
  );
};

CommonLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CommonLayout;
