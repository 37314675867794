import React from 'react';
import PropTypes from 'prop-types';

const ArrowIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.90999 0.967422L4.99999 3.87742L2.08999 0.967422C1.79749 0.674922 1.32499 0.674922 1.03249 0.967422C0.739985 1.25992 0.739985 1.73242 1.03249 2.02492L4.47499 5.46742C4.76749 5.75992 5.23999 5.75992 5.53249 5.46742L8.97498 2.02492C9.26748 1.73242 9.26748 1.25992 8.97498 0.967422C8.68248 0.682422 8.20249 0.674922 7.90999 0.967422Z"
      fill={color}
    />
  </svg>
);

ArrowIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowIcon.defaultProps = {
  color: '#212121',
  width: 10,
  height: 6,
};

export default ArrowIcon;
