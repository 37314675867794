import React from 'react';
import PropTypes from 'prop-types';

const ExitIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.05 13.9751C13.8834 13.8084 13.8 13.6043 13.8 13.3626C13.8 13.1209 13.8834 12.9168 14.05 12.7501L15.95 10.8501H8.15002C7.91669 10.8501 7.71669 10.7668 7.55002 10.6001C7.38336 10.4334 7.30002 10.2334 7.30002 10.0001C7.30002 9.7501 7.38336 9.54593 7.55002 9.3876C7.71669 9.22926 7.91669 9.1501 8.15002 9.1501H15.9L13.975 7.2251C13.825 7.0751 13.75 6.88343 13.75 6.6501C13.75 6.41676 13.8334 6.20843 14 6.0251C14.1667 5.85843 14.3709 5.7751 14.6125 5.7751C14.8542 5.7751 15.0584 5.85843 15.225 6.0251L18.625 9.4251C18.7084 9.5251 18.7709 9.6251 18.8125 9.7251C18.8542 9.8251 18.875 9.9251 18.875 10.0251C18.875 10.1418 18.8542 10.2459 18.8125 10.3376C18.7709 10.4293 18.7084 10.5251 18.625 10.6251L15.25 14.0001C15.1 14.1501 14.9084 14.2251 14.675 14.2251C14.4417 14.2251 14.2334 14.1418 14.05 13.9751ZM2.50002 19.1501C2.03336 19.1501 1.62919 18.9793 1.28752 18.6376C0.945858 18.2959 0.775024 17.9001 0.775024 17.4501V2.5501C0.775024 2.08343 0.945858 1.68343 1.28752 1.3501C1.62919 1.01676 2.03336 0.850098 2.50002 0.850098H8.95002C9.20002 0.850098 9.40419 0.933431 9.56252 1.1001C9.72086 1.26676 9.80002 1.46676 9.80002 1.7001C9.80002 1.9501 9.72086 2.15426 9.56252 2.3126C9.40419 2.47093 9.20002 2.5501 8.95002 2.5501H2.50002V17.4501H8.95002C9.20002 17.4501 9.40419 17.5334 9.56252 17.7001C9.72086 17.8668 9.80002 18.0668 9.80002 18.3001C9.80002 18.5501 9.72086 18.7543 9.56252 18.9126C9.40419 19.0709 9.20002 19.1501 8.95002 19.1501H2.50002Z"
      fill={color}
    />
  </svg>
);

ExitIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ExitIcon.defaultProps = {
  color: '#212121',
  width: 19,
  height: 20,
};

export default ExitIcon;
