import React, { useEffect, useMemo, useState } from 'react';
import { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getUsersAction } from 'store/asyncActions';
import { selectFetchAllUsers, selectUsersTableFiltersState, selectUserTableSearchState } from 'store/selectors/users';
import useCustomMediaQuery from 'hooks/useMediaQuery';
import paginateArray from 'utils/paginateArray';
import sortTable from 'utils/Sorting/sortTable';
import { userRolesParsed, userStatus } from 'constants/users';
import Table from 'components/Tables/Table';
import Badge from 'components/common/Badge';
import UserCard from 'components/pages/Home/UserTab/UserCard';
import { BlockIcon, DefaultUserIcon, DotIcon, InvisibleDotIcon, LoadingAnimatedIcon } from 'icons';
import UserActions from 'components/pages/Home/UserTab/UserActions';
import PaginationController from 'components/common/PaginationController';

/* eslint-disable react/prop-types */
const generateStatusBadge = (status, theme) => {
  const lowerCaseStatus = status?.toLowerCase();

  const badgeBgColors = {
    active: theme.colors.active,
    pending: theme.colors.pending,
    blocked: theme.colors.blocked,
  };

  const badgeIcon = {
    active: () => <DotIcon color={theme.colors.success} />,
    pending: () => <InvisibleDotIcon color={theme.colors.orange} />,
    blocked: () => <BlockIcon color={theme.colors.error} width={6} height={6} />,
  };

  const textColor = {
    active: theme.colors.success,
    pending: theme.colors.orange,
    blocked: theme.colors.error,
  };

  return (
    <Badge
      Icon={badgeIcon[lowerCaseStatus]}
      bgColor={badgeBgColors[lowerCaseStatus]}
      textColor={textColor[lowerCaseStatus]}
      css={css`
        font-size: ${theme.fonts.primary.size.xs};
      `}
    >
      {userStatus[lowerCaseStatus] || status}
    </Badge>
  );
};

const NameContent = ({
  row: {
    original: { name, Icon, status },
  },
}) => {
  const theme = useTheme();

  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        gap: 0.75rem;
        color: ${theme.colors.black};
        font-weight: 500;
        ${userStatus[status] === userStatus.blocked &&
        css`
          filter: opacity(0.35);
        `}
      `}
    >
      <Icon />
      {name}
    </span>
  );
};

const EmailContent = ({
  row: {
    original: { email, status },
  },
}) => (
  <span
    css={css`
      ${userStatus[status] === userStatus.blocked &&
      css`
        filter: opacity(0.35);
      `}
    `}
  >
    {email}
  </span>
);

const RoleContent = ({
  row: {
    original: { role, status },
  },
}) => (
  <span
    css={css`
      ${userStatus[status] === userStatus.blocked &&
      css`
        filter: opacity(0.35);
      `}
    `}
  >
    {userRolesParsed[role?.toLowerCase()] || role}
  </span>
);

const UsersMobileList = ({ page, loading }) => {
  const theme = useTheme();

  if (loading) {
    return (
      loading && (
        <span
          css={css`
            display: flex;
            margin: 0.5rem auto;
            height: 2rem;
            width: 2rem;
          `}
        >
          <LoadingAnimatedIcon />
        </span>
      )
    );
  }

  return page.length ? (
    page.map(({ id, icon, Icon, name, firstName, lastName, email, role, status, dealership }) => (
      <UserCard
        key={id}
        id={id}
        firstName={firstName}
        lastName={lastName}
        Icon={Icon}
        iconUrl={icon}
        name={name}
        email={email}
        role={role}
        status={status}
        dealership={dealership}
        StatusBadge={generateStatusBadge(status, theme)}
      />
    ))
  ) : (
    <p
      css={css`
        margin: 1rem 0;
        width: 100%;
        text-align: center;
        color: ${theme.colors.darkerGray};
        font-family: ${theme.fonts.primary.family};
      `}
    >
      Not Available Results
    </p>
  );
};
/* eslint-enable react/prop-types */

// eslint-disable-next-line no-unused-vars
const parseFilters = (filters) => Object.entries(filters)?.filter(([_, value]) => value);

const checkUserFiltersFulfillment = ({
  userHasRole,
  userHasStatus,
  haveFiltersByRole,
  haveFiltersByStatus,
  fulfillSearchParam,
}) => {
  if (!haveFiltersByStatus && !haveFiltersByRole && fulfillSearchParam) {
    return true;
  }

  const fulfillAllConditions = !!haveFiltersByRole && !!haveFiltersByStatus && !!userHasRole && !!userHasStatus;

  if (fulfillAllConditions) {
    return true;
  }

  if (userHasRole && !userHasStatus && !haveFiltersByStatus) {
    return true;
  }

  if (userHasStatus && !userHasRole && !haveFiltersByRole) {
    return true;
  }

  return false;
};

const filterTableUsersData = ({ tableUsers, filtersByRole, filtersByStatus, searchParams }) =>
  tableUsers.filter((user) => {
    const { status, role } = user;
    const { column, value: searchValue } = searchParams;

    const userParam = user?.[column] || '';

    const fulfillSearchParam =
      !column ||
      userParam?.toLowerCase()?.startsWith(searchValue?.toLowerCase()) ||
      userParam?.toLowerCase()?.includes(searchValue?.toLowerCase());

    if (!fulfillSearchParam) {
      return false;
    }

    const userHasRole = filtersByRole?.find(([filterRole]) => role?.toLowerCase() === filterRole?.toLowerCase());
    const userHasStatus = filtersByStatus?.find(
      ([filterStatus]) => status?.toLowerCase() === filterStatus?.toLowerCase()
    );

    return checkUserFiltersFulfillment({
      userHasRole,
      userHasStatus,
      fulfillSearchParam,
      haveFiltersByRole: !!filtersByRole?.length,
      haveFiltersByStatus: !!filtersByStatus?.length,
    });
  });

const UsersTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isDesktop = useCustomMediaQuery(theme.mediaQueries.largerTablet);

  const [currentPage, setCurrentPage] = useState(0);

  const {
    role: filtersRoles,
    status: filtersStatus,
    sorting: filtersSorting,
  } = useSelector(selectUsersTableFiltersState, shallowEqual);

  const { users, fetchUserLoading } = useSelector(selectFetchAllUsers, shallowEqual);
  const usersSearchTableInput = useSelector(selectUserTableSearchState, shallowEqual);

  useEffect(() => {
    dispatch(getUsersAction());
  }, [dispatch]);

  /* eslint-disable react/prop-types */
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: NameContent,
        sortable: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({
          row: {
            original: { status },
          },
        }) => generateStatusBadge(status, theme),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: RoleContent,
      },
      {
        Header: 'Email Address',
        accessor: 'email',
        Cell: EmailContent,
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: UserActions,
      },
    ],
    [theme]
  );

  /* eslint-enable react/prop-types */

  const filtersByRole = parseFilters(filtersRoles);
  const filtersByStatus = parseFilters(filtersStatus);

  const tableUsers = useMemo(
    () =>
      users?.map(({ id, icon, firstName, lastName, status, role, email, dealership }) => ({
        id,
        icon,
        Icon: icon ? () => <img src={icon} alt="UserIcon" /> : DefaultUserIcon,
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        status,
        role,
        email,
        dealership,
      })) || [],
    [users]
  );

  const sortedTableUsers = useMemo(
    () => sortTable({ tableData: tableUsers, paramToSort: 'name', sort: filtersSorting }),
    [tableUsers, filtersSorting]
  );

  const paginationOffset = 10;
  const filteredUsersPages = useMemo(
    () =>
      ((usersSearchTableInput.column && usersSearchTableInput.value) || filtersByRole || filtersByStatus
        ? paginateArray(
            filterTableUsersData({
              tableUsers: sortedTableUsers,
              filtersByRole,
              filtersByStatus,
              searchParams: usersSearchTableInput,
            }),
            paginationOffset
          ) || [[]]
        : paginateArray(sortedTableUsers, paginationOffset)) || [[]],
    [filtersByRole, filtersByStatus, sortedTableUsers, usersSearchTableInput]
  );

  useEffect(() => {
    if (filteredUsersPages.length - 1 < currentPage) {
      setCurrentPage(0);
    }
  }, [filteredUsersPages.length, currentPage]);

  return (
    <>
      {isDesktop ? (
        <Table columns={tableColumns} data={filteredUsersPages?.[currentPage] || []} loading={fetchUserLoading} />
      ) : (
        <UsersMobileList page={filteredUsersPages[currentPage]} loading={fetchUserLoading} />
      )}
      {!!filteredUsersPages[currentPage].length && !fetchUserLoading && (
        <PaginationController setCurrentPage={setCurrentPage} currentPage={currentPage} pages={filteredUsersPages} />
      )}
    </>
  );
};

export default UsersTable;
