const paginateArray = (array, offset) => {
  const slices = [];

  if (!array?.length) {
    return [[]];
  }

  for (let index = 0; index < array.length; index += offset) {
    slices.push(array.slice(index, index + offset));
  }

  return slices;
};

export default paginateArray;
