import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

const theme = {
  colors: {
    primary: '#ff9c95',
    secondary: '#344054',
    tertiary: '#667085',
    ghost: '#98a2b3',
    disabled: '#e4e7ec',
    success: '#00d9c0',
    error: '#a31621',
    warning: '#fda604',
    text: '#bec5cf',
    white: 'white',
    outline: '#e4e7ec',
    outlineDarker: '#475467',
    orange: '#f3c969',

    // badges
    active: 'rgba(0, 217, 192, 0.1)',
    pending: 'rgba(243, 201, 105, 0.15)',
    blocked: 'rgba(163, 22, 33, 0.1)',

    // green
    greenLogo: '#54BB5D',
    lightGreen: '#f8fdfd',

    // black
    black: '#212121',
    mediumBlack: 'rgba(33, 33, 33, 0.5)',

    // gray
    darkerGray: '#8A9198',
    mediumDarkGray: '#d9d9d9',
    mediumGray: '#d7dade',
    lightGray: ' #f2f4f7',
    lighterGray: ' #f9fafc',

    // salmon
    salmon: '#ff9c95',
    salmonLight: '#fceae9',
  },
  fonts: {
    primary: {
      family: 'Plus Jakarta Sans, Arial',
      size: {
        xs: '0.75rem',
        sm: '0.875rem',
        md: '1rem',
        lg: '1.125rem',
        xl: '1.25rem',
        xxl: '1.5rem',
        xxxl: '1.875rem',
      },
      lineHeight: {
        xs: '1rem',
        sm: '1.25rem',
        md: '1.5rem',
        lg: '1.75rem',
        xl: '1.75rem',
        xxl: '2rem',
        xxxl: '2.25rem',
      },
    },
  },
  border: {
    radius: {
      sm: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.75rem',
      xxl: '1rem',
    },
  },
  zIndex: {
    modal: 30,
    modalBackdrop: 25,
    max: 20,
    higher: 15,
    high: 10,
    medium: 5,
    lowest: 0,
  },
  mediaQueries: {
    maxDesktop: '4000px',
    fullDesktop: '1408px',
    wideDesktop: '1216px',
    desktop: '1024px',
    largerTablet: '900px',
    tablet: '768px',
    mobile: '500px',
    smallMobile: '325px',
    smallestMobile: '280px',
  },
};

const ThemeProvider = ({ children }) => (
  <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
