import firebase from 'services/firebase';
import { Users } from 'enums/cloudFunctions';

const blockUser = (uid) => {
  const httpsBlockUser = firebase.functions().httpsCallable(Users.BlockUser);

  return httpsBlockUser({ uid });
};

export default blockUser;
