import React, { useMemo, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styled, { css, useTheme } from 'styled-components';
import PropTypes from 'prop-types';

import validateFormFieldCondition from 'utils/validateFormFieldCondition';
import ErrorMessage from 'components/common/ErrorMessage';
import Select from 'components/common/Select';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledLabel = styled.label`
  font-weight: 200;

  ${({ theme, error }) =>
    css`
      font-size: ${theme.fonts.primary.size.md};
      ${error &&
      css`
        color: ${theme.colors.error};
      `}
    `};
`;

const selectAllOption = { value: 'selectAll', label: 'Select All' };

const SelectFormControl = ({
  name,
  label,
  placeholder,
  canSelectAll,
  options,
  defaultValue,
  conditionToShow,
  isMulti,
  overrideValues,
  setOverrideValues,
}) => {
  const theme = useTheme();
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const error = errors?.[name];

  const conditionIsFulfilled =
    !conditionToShow || validateFormFieldCondition(conditionToShow.isEqualTo, watch(conditionToShow.field));

  useEffect(() => {
    if (overrideValues) setValue(name, overrideValues);
  }, [name, overrideValues, setValue]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      filter: 'blur(0)',
      'max-height': '10rem',
      'overflow-y': 'scroll',
    }),
    option: (provided) => ({
      ...provided,
      ...(canSelectAll &&
        isMulti && {
          ':nth-child(1)': {
            color: theme.colors.black,
          },
        }),
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.primary,
      ':hover': {
        filter: 'grayscale(0.4)',
      },
    }),
  };

  const optionsToShow = useMemo(() => {
    const currentOptions = structuredClone(options);

    if (isMulti && canSelectAll && options.length > 0) {
      currentOptions.unshift(selectAllOption);
    }

    return currentOptions;
  }, [canSelectAll, isMulti, options]);

  const handleSelectChange = (onChange) => (selectedOptions) => {
    if (isMulti) {
      const selectAllIsSelected = selectedOptions.find(({ value }) => value === selectAllOption.value);

      if (canSelectAll && selectAllIsSelected) {
        return onChange(options.map(({ value }) => value));
      }

      const newOptions = selectedOptions.map(({ value }) => value);

      onChange(newOptions);

      return setOverrideValues(null);
    }

    onChange(selectedOptions.value);

    return setOverrideValues(null);
  };

  return conditionIsFulfilled ? (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      `}
    >
      {label && (
        <StyledLabel htmlFor={name} error={error}>
          {label}
        </StyledLabel>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ref } }) => (
          <StyledSelect
            placeholder={placeholder}
            ref={ref}
            onChange={handleSelectChange(onChange)}
            canSelectAll={canSelectAll}
            value={options?.filter((option) => {
              const actualValue = overrideValues || value || defaultValue;

              if (isMulti) {
                return actualValue?.find((selectedOption) => selectedOption === option.value) || null;
              }

              return actualValue === option.value;
            })}
            options={optionsToShow}
            styles={customStyles}
            isMulti={isMulti}
          />
        )}
      />
      {error?.message && <ErrorMessage>{error?.message?.toString()}</ErrorMessage>}
    </div>
  ) : null;
};

SelectFormControl.defaultProps = {
  label: null,
  isMulti: false,
  canSelectAll: false,
  placeholder: '',
  customErrors: {},
  options: [],
  conditionToShow: null,
  overrideValues: null,
  setOverrideValues: null,
  defaultValue: null,
};

SelectFormControl.propTypes = {
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  canSelectAll: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  setOverrideValues: PropTypes.string,
  overrideValues: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.string]),
  customErrors: PropTypes.shape({}),
  defaultValue: PropTypes.string,
  conditionToShow: PropTypes.shape({ field: PropTypes.string, isEqualTo: PropTypes.string }),
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
};

export default SelectFormControl;
