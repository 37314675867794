import React from 'react';
import PropTypes from 'prop-types';

const DotIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3.00001" cy="3" r="3" fill={color} />
  </svg>
);

DotIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DotIcon.defaultProps = {
  color: '#212121',
  width: 6,
  height: 6,
};

export default DotIcon;
