import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import Input, { InputType } from 'components/common/Input';
import styled, { css } from 'styled-components';
import ErrorMessage from 'components/common/ErrorMessage';

export const ControlInputType = { ...InputType };

export const LabelPosition = {
  Top: 'top',
  Bottom: 'bottom',
  Left: 'left',
  Right: 'right',
};

export const CheckboxKind = {
  Default: '',
  BlueCircleWithWhiteCheck: 'blueCircleWithWhiteCheck',
};

const StyledLabel = styled.label`
  font-weight: 200;

  ${({ theme, error }) =>
    css`
      font-size: ${theme.fonts.primary.size.md};
      ${error &&
      css`
        color: ${theme.colors.error};
      `}
    `};
`;

const FormControl = ({
  name,
  label,
  placeholder,
  type,
  Control,
  submitError,
  inputContainerCss,
  required,
  messageCustomError,
  customErrors,
  endEnhancer,
  controllers,
  defaultValue,
  controllersContainerStyle,
  ...rest
}) => {
  const {
    register,
    setError,
    setValue,
    formState: { errors, isSubmitSuccessful, isSubmitted },
  } = useFormContext();
  const [currentType, setCurrentType] = useState(type);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  const error = errors?.[name];

  const controlHaveErrors = !!error?.message;

  const inputHaveCustomError = customErrors?.[name];

  const shouldSetError = useMemo(
    () => inputHaveCustomError && submitError && messageCustomError && isSubmitSuccessful && isSubmitted,
    [inputHaveCustomError, isSubmitSuccessful, isSubmitted, messageCustomError, submitError]
  );

  useEffect(() => {
    if (shouldSetError) {
      setError(name, { message: messageCustomError });
    }
  }, [name, setError, messageCustomError, shouldSetError]);

  const toggleType = useCallback(
    ({ currentTarget }) => {
      if (currentTarget.name === name) {
        setCurrentType((prevState) => (prevState === InputType.Password ? InputType.Text : InputType.Password));
      }
    },
    [name]
  );

  const memoizedEndEnhancer = useCallback(
    () =>
      endEnhancer({
        error: controlHaveErrors,
        active: currentType === InputType.Text,
        onClickEnhancer: toggleType,
        name,
      }),
    [endEnhancer, controlHaveErrors, currentType, toggleType, name]
  );

  return (
    <div
      css={css`
        width: 100%;
        ${inputContainerCss}
      `}
    >
      <div
        css={css`
          width: 100%;
          ${inputContainerCss}
        `}
      >
        {label && (
          <StyledLabel htmlFor={name} error={controlHaveErrors}>
            {label}
          </StyledLabel>
        )}
        {type === InputType.Radio ? (
          <div css={controllersContainerStyle}>
            {controllers.map(({ value, label: controllerLabel, ...controllerRest }) => (
              <Control label={controllerLabel} name={name} value={value} register={register} {...controllerRest} />
            ))}
          </div>
        ) : (
          <Control
            name={name}
            type={currentType}
            placeholder={placeholder}
            error={controlHaveErrors}
            required={required}
            register={register}
            endEnhancer={endEnhancer ? memoizedEndEnhancer() : null}
            {...rest}
          />
        )}
      </div>
      {error && <ErrorMessage>{error?.message}</ErrorMessage>}
    </div>
  );
};

FormControl.defaultProps = {
  label: null,
  type: InputType.Text,
  placeholder: '',
  Control: Input,
  required: false,
  inputContainerCss: '',
  messageCustomError: undefined,
  submitError: false,
  customErrors: {},
  endEnhancer: null,
  controllers: [],
  controllersContainerStyle: '',
  defaultValue: null,
};

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
  submitError: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(Object.values(InputType)),
  placeholder: PropTypes.string,
  Control: PropTypes.oneOf([Input]),
  required: PropTypes.bool,
  inputContainerCss: PropTypes.string,
  messageCustomError: PropTypes.string,
  customErrors: PropTypes.shape({}),
  endEnhancer: PropTypes.func,
  controllers: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  controllersContainerStyle: PropTypes.string,
  defaultValue: PropTypes.string,
};

export default FormControl;
