import firebase from 'services/firebase';
import { Auth } from 'enums/cloudFunctions';

const activateUserAccount = ({ firstName, lastName, password, token }) => {
  const httpsActivateUserAccount = firebase.functions().httpsCallable(Auth.ActivateUserAccount);

  return httpsActivateUserAccount({ firstName, lastName, password, token });
};

export default activateUserAccount;
