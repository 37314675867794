const parseParam = (param) => {
  if (typeof param === 'string') {
    return param?.toLowerCase() || '';
  }

  if (Number.isNaN(Number(param))) {
    throw new Error('Invalid type provided');
  }

  return param;
};

const customSortCompare = (param) => (a, b) => {
  const aParam = parseParam(a[param]);
  const bParam = parseParam(b[param]);

  if (aParam < bParam) {
    return -1;
  }

  if (aParam > bParam) {
    return 1;
  }

  return 0;
};

export default customSortCompare;
