/* eslint-disable no-param-reassign */

import { Sort } from 'enums/filters';

export const CLEAR_CREATE_USER_STATE = (state) => {
  state.createUserLoading = false;
  state.createUserFailed = false;
  state.createUserSuccess = false;
  state.createUserError = null;
};

export const UPDATE_SEARCH_TABLE_INPUT = (state, { payload }) => {
  state.usersSearchTableInput = payload;
};

export const CLEAR_EDIT_USER_STATE = (state) => {
  state.editUserLoading = false;
  state.editUserFailed = false;
  state.editUserSuccess = false;
  state.editUserError = null;
};

export const CLEAR_DELETE_USER_STATE = (state) => {
  state.deleteUserLoading = false;
  state.deleteUserFailed = false;
  state.deleteUserSuccess = false;
  state.deleteUserError = null;
};

export const CLEAR_BLOCK_USER_STATE = (state) => {
  state.blockUserLoading = false;
  state.blockUserFailed = false;
  state.blockUserSuccess = false;
  state.blockUserError = null;
};

export const UPDATE_USERS_TABLE_FILTERS = (state, { payload }) => {
  state.usersTableFilters = payload;
};

export const CLEAR_USERS_STATE = (state) => {
  state.loading = false;
  state.success = false;
  state.failed = false;
  state.createUserLoading = false;
  state.createUserSuccess = false;
  state.createUserFailed = false;
  state.createUserError = null;
  state.editUserLoading = false;
  state.editUserSuccess = false;
  state.editUserFailed = false;
  state.editUserError = null;
  state.deleteUserLoading = false;
  state.deleteUserSuccess = false;
  state.deleteUserFailed = false;
  state.deleteUserError = null;
  state.blockUserLoading = false;
  state.blockUserSuccess = false;
  state.blockUserFailed = false;
  state.blockUserError = null;
  state.getUserDataLoading = false;
  state.getUserDataSuccess = false;
  state.getUserDataFailed = false;
  state.getUserByTokenLoading = false;
  state.getUserByTokenSuccess = false;
  state.getUserByTokenFailed = false;
  state.users = [];
  state.userData = {};
  state.usersTableFilters = { role: {}, status: {}, sorting: Sort.Ascending };
};
