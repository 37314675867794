import { Collections } from 'enums/collections';
import { getDocuments, getFirestoreRef } from 'services/firebase/db';

const getDealerships = async () => {
  const dealershipsCollection = getFirestoreRef(Collections.Dealerships);

  const dealerships = await getDocuments(dealershipsCollection);

  return dealerships;
};

export default getDealerships;
