import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import * as yup from 'yup';

import renderEnhancer from 'utils/formValidation/renderEnhancerWithChecks';
import CheckboxFormControl from 'components/FormControls/CheckboxFormControl';
import FormControl from 'components/FormControls/FormControl';
import Input, { InputType } from 'components/common/Input';
import { ErrorCircleIcon, EyeVisibility, EyeVisibilityOff } from 'icons';
import useModal from 'hooks/useModal';
import Modal from 'components/common/Modals/Modal';
import TermsAndConditionsForm from 'components/Forms/TermsAndConditionsForm';
import Button, { ButtonType } from 'components/common/Button';
import { PASSWORD_VALIDATION_REGEX } from 'utils/constants/forms';

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem 0.75rem;

  ${({ theme }) => css`
    color: ${theme.colors.darkerGray};
    caret-color: ${theme.colors.darkerGray};
    font-size: ${theme.fonts.primary.size.xl};
    vertical-align: middle;
  `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    ${({ theme }) =>
      ` -webkit-box-shadow: 0 0 0 30px ${theme.colors.white} inset !important;
    -webkit-text-fill-color: ${theme.colors.darkerGray};
    -webkit-text-size-adjust: ${theme.fonts.primary.size.xl};`}
  }
`;

const EnhancerContainer = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1rem;
`;

const StyledButton = styled.button.attrs({ type: 'button' })`
  padding: 0;
  border-style: none;
  background: none;
  cursor: pointer;
`;

const PasswordEndEnhancer = ({ onClickEnhancer, active, error, name }) => {
  const theme = useTheme();

  const baseEnhancer = (
    <StyledButton onClick={onClickEnhancer} name={name}>
      {active ? (
        <EyeVisibility height={21} color={theme.colors.black} />
      ) : (
        <EyeVisibilityOff color={theme.colors.mediumGray} />
      )}
    </StyledButton>
  );

  const enhancer = error ? (
    <>
      {baseEnhancer}
      <ErrorCircleIcon color={theme.colors.error} />
    </>
  ) : (
    baseEnhancer
  );

  return renderEnhancer({
    enhancer: (
      <EnhancerContainer
        css={css`
          display: flex;
          gap: 1rem;
        `}
      >
        {enhancer}
      </EnhancerContainer>
    ),
  });
};

const TermsAndConditionsLabel = () => {
  const theme = useTheme();
  const { isOpen, toggle } = useModal();
  return (
    <>
      <Modal title="Terms & Conditions" isOpen={isOpen} toggle={toggle}>
        <TermsAndConditionsForm toggle={toggle} />
      </Modal>
      <span>
        Accept&nbsp;
        <Button
          css={css`
            all: unset;
            cursor: pointer;
            color: ${theme.colors.primary};
          `}
          type={ButtonType.Button}
          onClick={toggle}
        >
          Terms & Conditions
        </Button>
      </span>
    </>
  );
};

export const activateUserAccountFields = [
  {
    name: 'firstName',
    label: 'First Name',
    placeholder: 'First Name',
    type: InputType.Text,
    Control: StyledInput,
    FormControl: StyledFormControl,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    placeholder: 'Last Name',
    type: InputType.Text,
    Control: StyledInput,
    FormControl: StyledFormControl,
  },
  {
    name: 'email',
    label: 'Email',
    placeholder: 'Email',
    type: InputType.Email,
    Control: StyledInput,
    FormControl: StyledFormControl,
    disabled: true,
  },
  {
    name: 'role',
    label: 'Role',
    placeholder: 'Role',
    type: InputType.Text,
    Control: StyledInput,
    FormControl: StyledFormControl,
    disabled: true,
  },
  {
    name: 'password',
    label: 'Password',
    placeholder: 'Enter your password',
    type: InputType.Password,
    control: StyledInput,
    FormControl: StyledFormControl,
    endEnhancer: PasswordEndEnhancer,
    messageCustomError:
      'Your password is wrong. Remember that it must contain at least 8 characters, 1 uppercase and 1 number.',
  },
  {
    name: 'termsAndConditions',
    label: <TermsAndConditionsLabel />,
    FormControl: CheckboxFormControl,
  },
];

export const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  password: yup
    .string()
    .matches(PASSWORD_VALIDATION_REGEX, 'Your password must contain at least 8 characters, 1 uppercase and 1 number.')
    .required('Password is required'),
  termsAndConditions: yup
    .boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'Terms and conditions must be accepted.'),
});
