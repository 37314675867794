import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';

import { PlusIcon } from 'icons';
import useModal from 'hooks/useModal';
import Button from 'components/common/Button';
import Modal from 'components/common/Modals/Modal';
import AddDealershipForm from 'components/Forms/AddDealershipForm';
import { selectCreateDealershipState } from 'store/selectors/dealerships';
import useCustomMediaQuery from 'hooks/useMediaQuery';

const StyledButton = styled(Button)`
  white-space: nowrap;
  height: 2.625rem;

  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
`;

const AddDealership = () => {
  const theme = useTheme();
  const isSmallMobile = useCustomMediaQuery(theme.mediaQueries.smallMobile);

  const { isOpen, toggle } = useModal();

  const { createDealershipLoading } = useSelector(selectCreateDealershipState, shallowEqual);

  const toggleFunction = createDealershipLoading ? null : toggle;

  return (
    <>
      <StyledButton onClick={toggle}>
        <PlusIcon />
        {isSmallMobile && 'Add dealership'}
      </StyledButton>
      <Modal title="Dealership details" isOpen={isOpen} toggle={toggleFunction}>
        <AddDealershipForm toggle={toggleFunction} />
      </Modal>
    </>
  );
};

export default AddDealership;
