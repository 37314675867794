import React from 'react';
import styled from 'styled-components';

import Title, { TitleKind } from 'components/common/Title';

const LogoText = styled(Title)`
  font-weight: 800;
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
`;

const InnerLogoText = styled.span`
  color: ${({ theme }) => theme.colors.greenLogo};
`;

const LogoIcon = (props) => (
  <LogoText {...props} kind={TitleKind.h2}>
    Lease
    <InnerLogoText>Bux</InnerLogoText>
  </LogoText>
);

export default LogoIcon;
