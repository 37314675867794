import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab } from '@headlessui/react';

const StyledTab = styled(Tab)`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const Tabs = ({ onChange, tabs, panels, MiddleContent, TabsWrapper, PannelsWrapper }) => (
  <Tab.Group onChange={onChange}>
    {({ selectedIndex }) => (
      <>
        <Tab.List>
          <TabsWrapper>
            {tabs.map((Element) => (
              <StyledTab key={Element}>{({ selected }) => <Element active={selected} />}</StyledTab>
            ))}
          </TabsWrapper>
        </Tab.List>
        {MiddleContent && <MiddleContent selectedIndex={selectedIndex} />}
        <PannelsWrapper>
          <Tab.Panels>
            {panels.map((Element) => (
              <Tab.Panel key={Element}>
                <Element />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </PannelsWrapper>
      </>
    )}
  </Tab.Group>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType])).isRequired,
  panels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType])).isRequired,
  TabsWrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
  PannelsWrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
  MiddleContent: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.elementType]),
  onChange: PropTypes.func,
};

Tabs.defaultProps = {
  TabsWrapper: ({ children }) => children,
  PannelsWrapper: ({ children }) => children,
  MiddleContent: null,
  onChange: null,
};

export default Tabs;
