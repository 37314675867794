import firebase from 'services/firebase';
import { Dealership } from 'enums/cloudFunctions';
import { uploadFileAndGetUrl } from 'services/firebase/storage';
import StoragePaths, { StorageChildsPaths } from 'enums/storagePaths';

const editDealership = async ({ dealershipId, name, address, oems, image, contract, fieldsToUpdate }) => {
  const httpsEditDealership = firebase.functions().httpsCallable(Dealership.EditDealership);

  const uploadImagePath = `${StoragePaths.Dealerships}/${
    StorageChildsPaths[StoragePaths.Dealerships].Logos
  }/${dealershipId}`;
  const uploadContractPath = `${StoragePaths.Dealerships}/${
    StorageChildsPaths[StoragePaths.Dealerships].Contracts
  }/${dealershipId}`;

  const { dealerContract: updateContract, dealershipImage: updateImage } = fieldsToUpdate;
  const contractName = contract?.name || null;
  const imageName = image?.name || null;

  let contractUrl = '';
  let imageUrl = '';

  try {
    if (updateContract && contract) {
      contractUrl = await uploadFileAndGetUrl(uploadContractPath, contract);
    }

    if (updateImage) {
      if (image) {
        imageUrl = await uploadFileAndGetUrl(uploadImagePath, image);
      }

      if (!image) {
        imageUrl = null;
      }
    }
  } catch (error) {
    throw new Error('Error uploading files');
  }

  try {
    const newDealershipData = {
      dealershipId,
      name,
      address,
      oems,
      updateImage,
      updateContract,
      image: imageUrl,
      imageName,
      contract: contractUrl,
      contractName,
    };

    await httpsEditDealership(newDealershipData);
  } catch (error) {
    throw new Error('Error Editing dealership files');
  }
};

export default editDealership;
