/* eslint-disable import/prefer-default-export */

export const userStatus = {
  active: 'Active',
  pending: 'Pending',
  blocked: 'Blocked',
};

export const userRoles = {
  admin: 'admin',
  sales: 'sales',
  dealer: 'dealer',
};

export const userRolesParsed = {
  [userRoles.admin]: 'Admin',
  [userRoles.sales]: 'Sales',
  [userRoles.dealer]: 'Dealer',
};
