import React from 'react';
import PropTypes from 'prop-types';

const KeyboardReturnIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.30005 11.2999L0.700049 6.69988C0.600049 6.59988 0.529382 6.49155 0.488049 6.37488C0.446049 6.25821 0.425049 6.13321 0.425049 5.99988C0.425049 5.86655 0.446049 5.74155 0.488049 5.62488C0.529382 5.50821 0.600049 5.39988 0.700049 5.29988L5.32505 0.674879C5.50838 0.491545 5.73738 0.403879 6.01205 0.411879C6.28738 0.420545 6.51672 0.516545 6.70005 0.699878C6.88338 0.883212 6.97505 1.11655 6.97505 1.39988C6.97505 1.68321 6.88338 1.91655 6.70005 2.09988L3.80005 4.99988H16V1.99988C16 1.71655 16.096 1.47888 16.288 1.28688C16.4794 1.09555 16.7167 0.999879 17 0.999879C17.2834 0.999879 17.5207 1.09555 17.712 1.28688C17.904 1.47888 18 1.71655 18 1.99988V5.99988C18 6.28321 17.904 6.52055 17.712 6.71188C17.5207 6.90388 17.2834 6.99988 17 6.99988H3.80005L6.72505 9.92488C6.90838 10.1082 6.99605 10.3372 6.98805 10.6119C6.97938 10.8872 6.88338 11.1165 6.70005 11.2999C6.51672 11.4832 6.28338 11.5749 6.00005 11.5749C5.71672 11.5749 5.48338 11.4832 5.30005 11.2999Z"
      fill={color}
    />
  </svg>
);

KeyboardReturnIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

KeyboardReturnIcon.defaultProps = {
  color: '#212121',
  width: 18,
  height: 12,
};

export default KeyboardReturnIcon;
