/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useCallback, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import Form from 'components/Forms/Form';
import { LoadingAnimatedIcon } from 'icons';
import { selectCreateDealershipState } from 'store/selectors/dealerships';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import { clearCreateDealershipState } from 'store/slices/dealershipsSlice';
import { createDealershipAction, getAllDealershipsAction, getAllOemsAction } from 'store/asyncActions';
import { addDealershipFields, validationSchema } from 'utils/formFields/addDealershipFields';
import { selectAllOemsState } from 'store/selectors/oems';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  gap: 2rem;
  margin-top: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        width: 50vw;
      }

      @media screen and (min-width: ${theme.mediaQueries.largerTabet}) {
        width: 35vw;
      }
    `};
`;

const AddDealershipForm = ({ toggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { createDealershipLoading, createDealershipSuccess, createDealershipError } = useSelector(
    selectCreateDealershipState,
    shallowEqual
  );
  const { oems } = useSelector(selectAllOemsState, shallowEqual);

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    }),
    []
  );

  const onSubmit = useCallback(
    (data) => {
      dispatch(createDealershipAction(data));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getAllOemsAction());
  }, [dispatch]);

  useEffect(() => {
    if (createDealershipSuccess) {
      dispatch(getAllDealershipsAction());
      toggle();
    }
  }, [createDealershipSuccess, dispatch, toggle]);

  useEffect(() => () => dispatch(clearCreateDealershipState()), [dispatch]);

  useEffect(() => {
    if (createDealershipError?.message) {
      toast.error(createDealershipError.message);
    }
  }, [createDealershipError]);

  const selectOems = useMemo(() => oems?.map(({ make }) => ({ value: make, label: make })) || [], [oems]);

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        {addDealershipFields.map(({ name, FormControl, ...field }) => (
          <FormControl
            key={name}
            inputContainerCss={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            `}
            options={selectOems}
            name={name}
            {...field}
          />
        ))}
      </div>
      <div
        css={css`
          display: flex;
          width: 100%;
          gap: 0.5rem;
        `}
      >
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
          `}
          disabled={createDealershipLoading}
          kind={ButtonKind.Secondary}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          disabled={createDealershipLoading}
          type={ButtonType.Submit}
        >
          {createDealershipLoading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Create dealership'
          )}
        </Button>
      </div>
    </StyledForm>
  );
};

AddDealershipForm.propTypes = {
  toggle: PropTypes.func,
};

AddDealershipForm.defaultProps = {
  toggle: null,
};

export default AddDealershipForm;
