/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Menu as HeadlessMenu } from '@headlessui/react';
import styled, { css } from 'styled-components';

import { BurguerIcon } from 'icons';

const StyledMenuContainer = styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.primary.family};
`;

const StyledMenuButton = styled(HeadlessMenu.Button)`
  background: none;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const StyledMenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.125rem;
  width: 2.125rem;
  padding: 0;

  ${({ theme, active }) =>
    active &&
    `
        background-color: ${theme.colors.lightGray};
        border-radius: 50%;
      `}
`;

const StyledMenuIcon = (props) => (
  <StyledMenuIconContainer {...props}>
    <BurguerIcon />
  </StyledMenuIconContainer>
);

const StyledMenuItems = styled(HeadlessMenu.Items)`
  position: absolute;
  right: 6px;
  min-width: 15.625rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 8px 28px rgba(0, 0, 0, 0.14);
  border-radius: ${({ theme }) => theme.border.radius.md};
  z-index: ${({ theme }) => theme.zIndex.max};

  ${({ itemsposition }) => {
    const definedPositions = {};

    Object.entries(itemsposition).forEach(([position, content]) => {
      if (content !== 'default') {
        definedPositions[position] = content;
      }
    });

    return css`
      ${() => definedPositions}
    `;
  }}
`;

const StyledMenuItemInnerElement = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.5rem;
  color: ${({ theme }) => theme.colors.mediumBlack};

  ${({ customHover, onClick }) =>
    !customHover &&
    onClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  ${({ active, theme, customHover, onClick }) =>
    active &&
    !customHover &&
    onClick &&
    css`
      background-color: ${theme.colors.lighterGray};
    `}
`;

const Menu = ({ Icon, items, itemsPosition, styledMenuItemsStyles }) => (
  <StyledMenuContainer>
    <HeadlessMenu>
      {({ open }) => (
        <>
          <StyledMenuButton>
            <Icon active={open} />
          </StyledMenuButton>
          <StyledMenuItems itemsposition={itemsPosition} css={styledMenuItemsStyles}>
            {items.map(({ component, onClick = null, key, ...props }) => (
              <HeadlessMenu.Item key={`${key}-menuItem`} as="div" onClick={onClick}>
                {({ active }) => (
                  <StyledMenuItemInnerElement active={active} onClick={onClick} {...props}>
                    {component}
                  </StyledMenuItemInnerElement>
                )}
              </HeadlessMenu.Item>
            ))}
          </StyledMenuItems>
        </>
      )}
    </HeadlessMenu>
  </StyledMenuContainer>
);

const itemsObjectShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
}).isRequired;

Menu.propTypes = {
  Icon: PropTypes.elementType,
  items: PropTypes.arrayOf(itemsObjectShape).isRequired,
  itemsPosition: PropTypes.shape({
    top: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
  }),
  styledMenuItemsStyles: PropTypes.string,
};

Menu.defaultProps = {
  Icon: StyledMenuIcon,
  itemsPosition: { top: 'default', bottom: 'default', right: 'default', left: 'default' },
  styledMenuItemsStyles: '',
};

export default Menu;
