import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import { CheckIcon } from 'icons';

const CheckIconContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCheckboxWithoutCheck = styled.input.attrs({ type: 'checkbox' })`
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.colors.outlineDarker};
  width: 1rem;
  height: 1rem;
  border-radius: ${({ theme }) => theme.border.radius.sm};

  ${({ theme, checked }) =>
    checked &&
    `background: ${theme.colors.primary};
  border-color: ${theme.colors.primary};`}

  ${({ theme, disabled }) =>
    disabled &&
    `cursor: not-allowed;
    background: ${theme.colors.disabled};
    border-color: ${theme.colors.outline};
    `}
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  font-family: ${({ theme }) => theme.fonts.primary.family};
  font-size: ${({ theme }) => theme.fonts.primary.size.sm};
  line-height: ${({ theme }) => theme.fonts.primary.lineHeight.sm};
  color: ${({ theme }) => theme.colors.outlineDarker};
`;

const Checkbox = ({ name, disabled, label, onChange, checked, ...rest }) => {
  const theme = useTheme();

  return (
    <StyledLabel>
      <div
        css={`
          display: flex;
          align-items: center;
          position: relative;
        `}
      >
        <StyledCheckboxWithoutCheck name={name} disabled={disabled} onChange={onChange} checked={checked} {...rest} />
        {checked && (
          <CheckIconContainer>
            <CheckIcon color={theme.colors.white} title={name} />
          </CheckIconContainer>
        )}
      </div>
      <span>{label}</span>
    </StyledLabel>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  name: '',
  disabled: false,
  label: '',
  checked: false,
  onChange: () => {},
};

export default Checkbox;
