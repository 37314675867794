const StoragePaths = Object.freeze({
  Users: 'users',
  Dealerships: 'dealerships',
});

export const StorageChildsPaths = Object.freeze({
  [StoragePaths.Dealerships]: {
    Logos: 'logos',
    Contracts: 'contracts',
  },
});

export default StoragePaths;
