import React, { useMemo, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { selectAuthData } from 'store/selectors/auth';
import { clearEditUserState } from 'store/slices/usersSlice';
import { editUserAction, getAllDealershipsAction, getUserDataAction, getUsersAction } from 'store/asyncActions';
import { selectEditUserState } from 'store/selectors/users';
import { editUserFields, validationSchema } from 'utils/formFields/editUserFields';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';
import { LoadingAnimatedIcon } from 'icons';
import { selectAllDealershipsState } from 'store/selectors/dealerships';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  gap: 2rem;
  margin-top: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        width: 50vw;
      }

      @media screen and (min-width: ${theme.mediaQueries.largerTabet}) {
        width: 35vw;
      }
    `};
`;

const EditUserForm = ({ toggle, userData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { editUserLoading, editUserSuccess, editUserError } = useSelector(selectEditUserState, shallowEqual);

  const { dealerships } = useSelector(selectAllDealershipsState, shallowEqual);

  const { userId } = useSelector(selectAuthData, shallowEqual);

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    }),
    []
  );

  const onSubmit = useCallback(
    (data) => {
      const { firstName, lastName, dealership } = data;

      dispatch(editUserAction({ ...userData, firstName, lastName, dealership }));
    },
    [dispatch, userData]
  );

  useEffect(() => {
    dispatch(getAllDealershipsAction());
  }, [dispatch]);

  useEffect(() => {
    if (editUserSuccess) {
      dispatch(getUsersAction());
      dispatch(getUserDataAction(userId));
      toggle();
    }
  }, [dispatch, editUserSuccess, toggle, userId]);

  useEffect(() => () => dispatch(clearEditUserState()), [dispatch]);

  useEffect(() => {
    if (editUserError?.message) {
      toast.error(editUserError.message);
    }
  }, [editUserError, editUserError?.message]);

  const dealershipOptions = dealerships?.map(({ name, id }) => ({ value: id, label: name }));

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        {editUserFields.map(({ name, type, controllers, FormControl, ...field }) => {
          let customControllers = [];

          if (controllers) {
            customControllers = controllers.map(({ value, ...rest }) => ({
              value,
              checked: value === userData[name],
              ...rest,
            }));
          }

          return (
            <FormControl
              key={name}
              inputContainerCss={css`
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
              type={type}
              name={name}
              options={dealershipOptions}
              defaultValue={userData[name]}
              controllers={customControllers}
              {...field}
            />
          );
        })}
      </div>

      <div
        css={css`
          display: flex;
          width: 100%;
          gap: 0.5rem;
        `}
      >
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
          `}
          disabled={editUserLoading}
          kind={ButtonKind.Secondary}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          disabled={editUserLoading}
          type={ButtonType.Submit}
        >
          {editUserLoading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
    </StyledForm>
  );
};

EditUserForm.propTypes = {
  toggle: PropTypes.func,
  userData: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

EditUserForm.defaultProps = {
  toggle: null,
};

export default EditUserForm;
