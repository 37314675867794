/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Sort } from 'enums/filters';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  CLEAR_DEALERSHIPS_STATE,
  CLEAR_CREATE_DEALERSHIP_STATE,
  CLEAR_DELETE_DEALERSHIP_STATE,
  CLEAR_EDIT_DEALERSHIP_STATE,
  UPDATE_SEARCH_TABLE_VALUE,
  UPDATE_DEALERSHIPS_TABLE_FILTERS,
} from 'store/actions';
import {
  getAllDealershipsAction,
  createDealershipAction,
  deleteDealershipAction,
  editDealershipAction,
} from 'store/asyncActions';

const initialState = {
  fetchDealershipLoading: false,
  fetchDealershipSuccess: false,
  fetchDealershipFailed: false,
  createDealershipLoading: false,
  createDealershipSuccess: false,
  createDealershipFailed: false,
  editDealershipLoading: false,
  editDealershipSuccess: false,
  editDealershipFailed: false,
  deleteDealershipLoading: false,
  deleteDealershipSuccess: false,
  deleteDealershipFailed: false,
  dealershipsTableFilters: { sorting: Sort.Ascending, selectedOems: [], mustIncludeAllOems: false },
  dealershipSearchTable: { column: 'name', value: '' },
  dealerships: [],
};

const reducers = {
  clearCreateDealershipState: CLEAR_CREATE_DEALERSHIP_STATE,
  clearDeleteDealershipState: CLEAR_DELETE_DEALERSHIP_STATE,
  clearDealershipsState: CLEAR_DEALERSHIPS_STATE,
  clearEditDealershipState: CLEAR_EDIT_DEALERSHIP_STATE,
  updateDealershipsSearchTableValue: UPDATE_SEARCH_TABLE_VALUE,
  updateDealershipsTableFilters: UPDATE_DEALERSHIPS_TABLE_FILTERS,
};

const extraReducers = {
  [getAllDealershipsAction.pending]: (state) => {
    state.fetchDealershipLoading = true;
    state.fetchDealershipFailed = false;
    state.fetchDealershipSuccess = false;
  },
  [getAllDealershipsAction.rejected]: (state) => {
    state.fetchDealershipFailed = true;
    state.fetchDealershipLoading = false;
  },
  [getAllDealershipsAction.fulfilled]: (state, { payload }) => {
    state.fetchDealershipLoading = false;
    state.success = true;
    state.dealerships = payload.dealerships;
  },
  [createDealershipAction.pending]: (state) => {
    state.createDealershipLoading = true;
    state.createDealershipFailed = false;
    state.createDealershipSuccess = false;
  },
  [createDealershipAction.rejected]: (state, { error }) => {
    state.createDealershipFailed = true;
    state.createDealershipLoading = false;
    state.createDealershipError = error;
  },
  [createDealershipAction.fulfilled]: (state) => {
    state.createDealershipLoading = false;
    state.createDealershipSuccess = true;
  },
  [editDealershipAction.pending]: (state) => {
    state.editDealershipLoading = true;
    state.editDealershipFailed = false;
    state.editDealershipSuccess = false;
  },
  [editDealershipAction.rejected]: (state, { error }) => {
    state.editDealershipFailed = true;
    state.editDealershipLoading = false;
    state.editDealershipError = error;
  },
  [editDealershipAction.fulfilled]: (state) => {
    state.editDealershipLoading = false;
    state.editDealershipSuccess = true;
  },
  [deleteDealershipAction.pending]: (state) => {
    state.deleteDealershipLoading = true;
    state.deleteDealershipFailed = false;
    state.deleteDealershipSuccess = false;
  },
  [deleteDealershipAction.rejected]: (state, { error }) => {
    state.deleteDealershipFailed = true;
    state.deleteDealershipLoading = false;
    state.deleteDealershipError = error;
  },
  [deleteDealershipAction.fulfilled]: (state) => {
    state.deleteDealershipLoading = false;
    state.deleteDealershipSuccess = true;
  },
};

const persistConfig = {
  key: 'dealerships',
  storage,
  blackList: [
    'fetchDealershipFailed',
    'fetchDealershipLoading',
    'fetchDealershipSuccess',
    'dealerships',
    'createDealershipLoading',
    'createDealershipSuccess',
    'createDealershipFailed',
    'editDealershipLoading',
    'editDealershipSuccess',
    'editDealershipFailed',
    'deleteDealershipLoading',
    'deleteDealershipSuccess',
    'deleteDealershipFailed',
    'dealershipSearchTable',
  ],
};

const { reducer, actions } = createSlice({
  name: 'dealerships',
  initialState,
  reducers,
  extraReducers,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const {
  clearCreateDealershipState,
  clearEditDealershipState,
  clearDealershipsState,
  clearDeleteDealershipState,
  updateDealershipsSearchTableValue,
  updateDealershipsTableFilters,
} = actions;

export default persistedReducer;
