import PersonVectorIcon from 'icons/PersonVectorIcon';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';

const UserIconContainer = styled.span`
  height: ${({ height }) => height || `2.5rem`};
  width: ${({ width }) => width || `2.5rem`};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.salmonLight};
  border-radius: 50%;
`;

const DefaultUserIcon = ({ height, width }) => {
  const theme = useTheme();

  return (
    <UserIconContainer height={height} width={width}>
      <PersonVectorIcon color={theme.colors.primary} />
    </UserIconContainer>
  );
};

DefaultUserIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

DefaultUserIcon.defaultProps = {
  height: null,
  width: null,
};

export default DefaultUserIcon;
