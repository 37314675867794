export const Auth = Object.freeze({
  CreateUser: 'https-auth-inviteUser',
  ActivateUserAccount: 'https-auth-activateUserAccount',
});

export const Users = Object.freeze({
  EditUser: 'https-users-editUser',
  DeleteUser: 'https-users-deleteUser',
  GetUserByToken: 'https-users-getUserByToken',
  BlockUser: 'https-users-blockUser',
});

export const Dealership = Object.freeze({
  CreateDealership: 'https-dealerships-createDealership',
  EditDealership: 'https-dealerships-editDealership',
  DeleteDealership: 'https-dealerships-deleteDealership',
});
