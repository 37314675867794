import React from 'react';
import PropTypes from 'prop-types';

const BlockIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18C7.7655 18 6.6015 17.7656 5.508 17.2969C4.4145 16.8281 3.4575 16.1835 2.637 15.363C1.8165 14.5425 1.17188 13.5855 0.703125 12.492C0.234375 11.3985 0 10.2345 0 9C0 7.74975 0.234375 6.58163 0.703125 5.49563C1.17188 4.41038 1.8165 3.4575 2.637 2.637C3.4575 1.8165 4.4145 1.17188 5.508 0.703125C6.6015 0.234375 7.7655 0 9 0C10.2502 0 11.4184 0.234375 12.5044 0.703125C13.5896 1.17188 14.5425 1.8165 15.363 2.637C16.1835 3.4575 16.8281 4.41038 17.2969 5.49563C17.7656 6.58163 18 7.74975 18 9C18 10.2345 17.7656 11.3985 17.2969 12.492C16.8281 13.5855 16.1835 14.5425 15.363 15.363C14.5425 16.1835 13.5896 16.8281 12.5044 17.2969C11.4184 17.7656 10.2502 18 9 18ZM9 16.3125C11.031 16.3125 12.7575 15.6015 14.1795 14.1795C15.6015 12.7575 16.3125 11.031 16.3125 9C16.3125 8.1405 16.1719 7.32788 15.8906 6.56213C15.6094 5.79638 15.2186 5.10113 14.7184 4.47638L4.47638 14.7184C5.10113 15.2186 5.79638 15.6094 6.56213 15.8906C7.32788 16.1719 8.1405 16.3125 9 16.3125ZM3.28162 13.5236L13.5236 3.28162C12.8989 2.78137 12.2036 2.39062 11.4379 2.10938C10.6721 1.82812 9.8595 1.6875 9 1.6875C6.969 1.6875 5.2425 2.3985 3.8205 3.8205C2.3985 5.2425 1.6875 6.969 1.6875 9C1.6875 9.8595 1.82812 10.6721 2.10938 11.4379C2.39062 12.2036 2.78137 12.8989 3.28162 13.5236Z"
      fill={color}
    />
  </svg>
);

BlockIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

BlockIcon.defaultProps = {
  color: '#212121',
  width: 18,
  height: 18,
};

export default BlockIcon;
