import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { selectEditDealershipState } from 'store/selectors/dealerships';
import { clearEditDealershipState } from 'store/slices/dealershipsSlice';
import { selectAllOemsState } from 'store/selectors/oems';
import { editDealershipAction, getAllDealershipsAction, getAllOemsAction } from 'store/asyncActions';
import { editDealershipFields, validationSchema } from 'utils/formFields/editDealershipFields';
import Button, { ButtonKind, ButtonType } from 'components/common/Button';
import Form from 'components/Forms/Form';
import { LoadingAnimatedIcon } from 'icons';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  gap: 2rem;
  margin-top: 2rem;

  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.primary.family};
      color: ${theme.colors.darkerGray};
      background-color: ${theme.colors.white};

      @media screen and (min-width: ${theme.mediaQueries.tablet}) {
        width: 50vw;
      }

      @media screen and (min-width: ${theme.mediaQueries.largerTabet}) {
        width: 35vw;
      }
    `};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  gap: 0.5rem;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.mediaQueries.tablet}) {
      flex-direction: row;
    }
  `}
`;

const EditDealershipForm = ({ toggle, dealershipData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [fieldsToUpdate, setFieldsToUpdate] = useState({ dealerContract: false, dealershipImage: false });

  const { editDealershipLoading, editDealershipSuccess, editDealershipError } = useSelector(
    selectEditDealershipState,
    shallowEqual
  );
  const { oems } = useSelector(selectAllOemsState, shallowEqual);

  const { name: dealershipName, id: dealershipId } = dealershipData;

  const defaultDealershipData = useMemo(
    () => ({ ...dealershipData, dealershipName }),
    [dealershipData, dealershipName]
  );

  const useFormProps = useMemo(
    () => ({
      mode: 'onSubmit',
      resolver: yupResolver(validationSchema),
    }),
    []
  );

  const onSubmit = useCallback(
    (data) => {
      dispatch(editDealershipAction({ ...data, dealershipId, fieldsToUpdate }));
    },
    [dispatch, dealershipId, fieldsToUpdate]
  );

  useEffect(() => {
    dispatch(getAllOemsAction());
  }, [dispatch]);

  useEffect(() => {
    if (editDealershipSuccess) {
      dispatch(getAllDealershipsAction());
      toggle();
    }
  }, [editDealershipSuccess, dispatch, toggle]);

  useEffect(() => () => dispatch(clearEditDealershipState()), [dispatch]);

  useEffect(() => {
    if (editDealershipError?.message) {
      toast.error(editDealershipError.message);
    }
  }, [editDealershipError]);

  const selectOems = useMemo(() => oems?.map(({ make }) => ({ value: make, label: make })) || [], [oems]);

  return (
    <StyledForm useFormProps={useFormProps} onSubmit={onSubmit}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 1rem;
        `}
      >
        {editDealershipFields.map(({ name, FormControl, ...field }) => (
          <FormControl
            key={name}
            inputContainerCss={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
            `}
            options={selectOems}
            name={name}
            defaultValue={defaultDealershipData[name]}
            setFieldsToUpdate={setFieldsToUpdate}
            {...field}
          />
        ))}
      </div>
      <ButtonsContainer>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
          `}
          disabled={editDealershipLoading}
          kind={ButtonKind.Secondary}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          css={css`
            width: 100%;
            text-align: center;
            border-radius: ${theme.border.radius.sm};
            color: ${theme.colors.black};
          `}
          disabled={editDealershipLoading}
          type={ButtonType.Submit}
        >
          {editDealershipLoading ? (
            <span
              css={css`
                display: flex;
                height: ${theme.fonts.primary.size.xl};
              `}
            >
              <LoadingAnimatedIcon />
            </span>
          ) : (
            'Save changes'
          )}
        </Button>
      </ButtonsContainer>
    </StyledForm>
  );
};

EditDealershipForm.propTypes = {
  toggle: PropTypes.func,
  dealershipData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    Logo: PropTypes.string,
    logo: PropTypes.string,
    dealershipContract: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }),
    dealershipImage: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }),
  }).isRequired,
};

EditDealershipForm.defaultProps = {
  toggle: null,
};

export default EditDealershipForm;
