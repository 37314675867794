import React from 'react';
import * as yup from 'yup';
import styled, { css, useTheme } from 'styled-components';

import Input, { InputType } from 'components/common/Input';
import renderEnhancer from 'utils/formValidation/renderEnhancerWithChecks';
import { ErrorCircleIcon, EyeVisibility, EyeVisibilityOff } from 'icons';

const StyledInput = styled(Input)`
  width: 100%;
`;

const EnhancerContainer = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 1rem;
`;

const StyledButton = styled.button.attrs({ type: 'button' })`
  padding: 0;
  border-style: none;
  background: none;
  cursor: pointer;
`;

const EmailEndEnhancer = ({ error }) => {
  const theme = useTheme();
  const enhancer = error ? <ErrorCircleIcon color={theme.colors.error} /> : <span />;

  return renderEnhancer({ enhancer: <EnhancerContainer>{enhancer}</EnhancerContainer>, canShow: [error] });
};

const PasswordEndEnhancer = ({ onClickEnhancer, active, error, name }) => {
  const theme = useTheme();

  const baseEnhancer = (
    <StyledButton onClick={onClickEnhancer} name={name}>
      {active ? (
        <EyeVisibility height={21} color={theme.colors.black} />
      ) : (
        <EyeVisibilityOff color={theme.colors.mediumGray} />
      )}
    </StyledButton>
  );

  const enhancer = error ? (
    <>
      {baseEnhancer}
      <ErrorCircleIcon color={theme.colors.error} />
    </>
  ) : (
    baseEnhancer
  );

  return renderEnhancer({
    enhancer: (
      <EnhancerContainer
        css={css`
          display: flex;
          gap: 1rem;
        `}
      >
        {enhancer}
      </EnhancerContainer>
    ),
  });
};

export const loginFields = [
  {
    name: 'email',
    label: 'Email',
    placeholder: 'Enter your email',
    type: InputType.Email,
    control: StyledInput,
    endEnhancer: EmailEndEnhancer,
    messageCustomError: 'We couldn’t find an account associated to that email',
  },
  {
    name: 'password',
    label: 'Password',
    placeholder: 'Enter your password',
    type: InputType.Password,
    control: StyledInput,
    endEnhancer: PasswordEndEnhancer,
    messageCustomError:
      'Your password is wrong. Remember that it must contain at least 8 characters, 1 uppercase and 1 number.',
  },
];

export const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid Email').required('Email is required'),
  password: yup.string().required('Password is required'),
});
