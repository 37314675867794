import React from 'react';
import PropTypes from 'prop-types';

const ErrorCircleIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 15.5C10.2833 15.5 10.521 15.404 10.713 15.212C10.9043 15.0207 11 14.7833 11 14.5C11 14.2167 10.9043 13.979 10.713 13.787C10.521 13.5957 10.2833 13.5 10 13.5C9.71667 13.5 9.47933 13.5957 9.288 13.787C9.096 13.979 9 14.2167 9 14.5C9 14.7833 9.096 15.0207 9.288 15.212C9.47933 15.404 9.71667 15.5 10 15.5ZM10 20.5C8.61667 20.5 7.31667 20.2373 6.1 19.712C4.88333 19.1873 3.825 18.475 2.925 17.575C2.025 16.675 1.31267 15.6167 0.788 14.4C0.262667 13.1833 0 11.8833 0 10.5C0 9.11667 0.262667 7.81667 0.788 6.6C1.31267 5.38333 2.025 4.325 2.925 3.425C3.825 2.525 4.88333 1.81233 6.1 1.287C7.31667 0.762333 8.61667 0.5 10 0.5C11.3833 0.5 12.6833 0.762333 13.9 1.287C15.1167 1.81233 16.175 2.525 17.075 3.425C17.975 4.325 18.6873 5.38333 19.212 6.6C19.7373 7.81667 20 9.11667 20 10.5C20 11.8833 19.7373 13.1833 19.212 14.4C18.6873 15.6167 17.975 16.675 17.075 17.575C16.175 18.475 15.1167 19.1873 13.9 19.712C12.6833 20.2373 11.3833 20.5 10 20.5ZM10 11.5C10.2833 11.5 10.521 11.404 10.713 11.212C10.9043 11.0207 11 10.7833 11 10.5V6.5C11 6.21667 10.9043 5.979 10.713 5.787C10.521 5.59567 10.2833 5.5 10 5.5C9.71667 5.5 9.47933 5.59567 9.288 5.787C9.096 5.979 9 6.21667 9 6.5V10.5C9 10.7833 9.096 11.0207 9.288 11.212C9.47933 11.404 9.71667 11.5 10 11.5Z"
      fill={color}
    />
  </svg>
);

ErrorCircleIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ErrorCircleIcon.defaultProps = {
  color: '#212121',
  width: 20,
  height: 21,
};

export default ErrorCircleIcon;
