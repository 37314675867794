import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  FormatQuoteIcon,
  LogoIcon,
  ArrowIcon,
  ThreeDotsIcon,
  ExitIcon,
  PersonVectorIcon,
  KeyboardReturnIcon,
} from 'icons';
import useCustomMediaQuery from 'hooks/useMediaQuery';

import { signOutAction } from 'store/asyncActions';
import { selectAuthData } from 'store/selectors/auth';
import { userRoles } from 'constants/users';
import Title, { TitleKind } from 'components/common/Title';
import Menu from 'components/common/Menu';
import { selectUserDataState } from 'store/selectors/users';

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 4.5rem;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.05);
  ${({ theme }) => css`
    background-color:  ${theme.colors.white}};
    z-index:  ${theme.zIndex.modalBackdrop}};
  `}

  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.tablet}) {
    height: 5.75rem;
    padding: 0 4rem;
  }
`;

const MenuItem = styled.span`
  display: flex;
  align-items: baseline;
  gap: 1rem;
  width: 85%;
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  &:hover {
    cursor: pointer;
  }
`;

const LogoutButton = styled(MenuItem)`
  align-items: center;
  justify-content: center;
  gap: 0.688rem;
  background: none;
  border: none;
  height: 2.75rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  color: ${({ theme }) => theme.colors.primary};
  margin: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lighterGray};
  }

  &:active {
    transform: scale(0.99);
  }
`;

const DesktopOptionsContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary.family};
  color: ${({ theme }) => theme.colors.darkerGray};
  font-weight: 400;
  width: 100%;
  padding-left: 5.25rem;
  display: flex;
  align-items: center;
  gap: 1.875rem;
`;

const DesktopOptions = styled(Title).attrs({ kind: TitleKind.h3 })`
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  &:active {
    color: ${({ theme }) => theme.colors.black};
  }
`;

/* eslint-disable react/prop-types */
const DesktopMenuIcon = ({ active }) => {
  const theme = useTheme();
  const { name } = useSelector(selectAuthData, shallowEqual);
  const { firstName } = useSelector(selectUserDataState, shallowEqual);
  const userName = firstName || name?.split(' ')?.[0] || '';

  return (
    <div
      css={css`
        height: 3rem;
        min-width: 7.625;
        display: flex;
        align-items: center;
      `}
    >
      <span
        css={css`
          height: 3rem;
          width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${theme.colors.salmonLight};
          border-radius: 50%;
        `}
      >
        <PersonVectorIcon color={theme.colors.primary} />
      </span>
      <span
        css={css`
          color: ${theme.colors.black};
          font-size: ${theme.fonts.primary.size.md};
          margin: 0 0.625rem 0 0.75rem;
          font-weight: 500;
        `}
      >
        {userName}
      </span>
      <span
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;

          ${active &&
          css`
            transform: rotate(-180deg);
          `}
        `}
      >
        <ArrowIcon color={theme.colors.primary} />
      </span>
    </div>
  );
};
/* eslint-enable react/prop-types */

const NavBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useCustomMediaQuery(theme.mediaQueries.largerTablet);

  const { isLogged, role } = useSelector(selectAuthData, shallowEqual);

  const displayMenuOptions = isLogged && role === userRoles.admin;

  const onSignOutHandler = () => dispatch(signOutAction());

  const menuItemMockedClick = () => {};

  const showMenuItems = false;

  const mobileMenuOptions = [
    {
      key: 'howitworks',
      component: (
        <MenuItem>
          <ThreeDotsIcon color={theme.colors.mediumGray} />
          How it works
        </MenuItem>
      ),
      onClick: menuItemMockedClick,
    },
    {
      key: 'testimonials',
      component: (
        <MenuItem>
          <FormatQuoteIcon color={theme.colors.mediumGray} />
          Testimonials
        </MenuItem>
      ),
      onClick: menuItemMockedClick,
    },
    {
      key: 'returnlease',
      component: (
        <MenuItem>
          <KeyboardReturnIcon color={theme.colors.mediumGray} />
          Return Lease
        </MenuItem>
      ),
      onClick: menuItemMockedClick,
    },
  ];

  const dropdownMenuItems = displayMenuOptions
    ? [
        ...(!isDesktop && showMenuItems ? mobileMenuOptions : []),
        ...(showMenuItems
          ? [
              {
                key: 'myprofile',
                component: (
                  <MenuItem>
                    <PersonVectorIcon color={theme.colors.mediumGray} />
                    My Profile
                  </MenuItem>
                ),
                onClick: menuItemMockedClick,
              },
            ]
          : []),
        {
          key: 'logout',
          component: (
            <LogoutButton>
              <ExitIcon color={theme.colors.primary} />
              Logout
            </LogoutButton>
          ),
          onClick: onSignOutHandler,
          customHover: true,
        },
      ]
    : [];

  const menuVariableProps = {
    ...(isDesktop && {
      Icon: DesktopMenuIcon,
      itemsPosition: { top: 'calc(100% + 1rem)' },
    }),
    ...(!isDesktop && {
      itemsPosition: { top: 'calc(100% + 0.5rem)' },
    }),
  };

  return (
    <StyledHeader>
      <div
        css={`
          max-width: ${theme.mediaQueries.fullDesktop};
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <LogoIcon />

        {showMenuItems && isDesktop && (
          <DesktopOptionsContainer>
            <DesktopOptions>How it works</DesktopOptions>
            <DesktopOptions>Testimonials</DesktopOptions>
            <DesktopOptions>Return Lease</DesktopOptions>
          </DesktopOptionsContainer>
        )}

        {displayMenuOptions && <Menu {...menuVariableProps} items={dropdownMenuItems} />}
      </div>
    </StyledHeader>
  );
};

export default NavBar;
