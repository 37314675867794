import { createAsyncThunk } from '@reduxjs/toolkit';

import getUsers from 'services/users/getUsers';

// eslint-disable-next-line import/prefer-default-export
export const getUsersAction = createAsyncThunk('users/getAll', async () => {
  const users = await getUsers();

  return { users };
});
