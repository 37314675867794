import React from 'react';
import PropTypes from 'prop-types';

const PersonVectorIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00002 8.79995C7.80002 8.79995 6.81669 8.41662 6.05002 7.64995C5.28336 6.88328 4.90002 5.89995 4.90002 4.69995C4.90002 3.48328 5.28336 2.49162 6.05002 1.72495C6.81669 0.958284 7.80002 0.574951 9.00002 0.574951C10.2 0.574951 11.1875 0.958284 11.9625 1.72495C12.7375 2.49162 13.125 3.48328 13.125 4.69995C13.125 5.89995 12.7375 6.88328 11.9625 7.64995C11.1875 8.41662 10.2 8.79995 9.00002 8.79995ZM15.375 17.525H2.62502C2.07502 17.525 1.60836 17.3333 1.22502 16.95C0.841691 16.5666 0.650024 16.1 0.650024 15.55V14.8C0.650024 14.1166 0.825025 13.5208 1.17502 13.0125C1.52502 12.5041 1.98336 12.1166 2.55002 11.85C3.68336 11.3333 4.77502 10.95 5.82502 10.7C6.87502 10.45 7.93336 10.325 9.00002 10.325C10.0834 10.325 11.1459 10.4541 12.1875 10.7125C13.2292 10.9708 14.3084 11.35 15.425 11.85C16.0084 12.1 16.4792 12.4791 16.8375 12.9875C17.1959 13.4958 17.375 14.1 17.375 14.8V15.55C17.375 16.1 17.1792 16.5666 16.7875 16.95C16.3959 17.3333 15.925 17.525 15.375 17.525ZM2.62502 15.55H15.375V14.85C15.375 14.6 15.2959 14.3541 15.1375 14.1125C14.9792 13.8708 14.7834 13.7 14.55 13.6C13.5167 13.1 12.5709 12.7583 11.7125 12.575C10.8542 12.3916 9.95002 12.3 9.00002 12.3C8.06669 12.3 7.15419 12.3916 6.26252 12.575C5.37086 12.7583 4.42502 13.1 3.42502 13.6C3.19169 13.7 3.00002 13.8708 2.85002 14.1125C2.70002 14.3541 2.62502 14.6 2.62502 14.85V15.55ZM9.00002 6.79995C9.61669 6.79995 10.125 6.59995 10.525 6.19995C10.925 5.79995 11.125 5.29995 11.125 4.69995C11.125 4.06662 10.925 3.55412 10.525 3.16245C10.125 2.77078 9.61669 2.57495 9.00002 2.57495C8.38336 2.57495 7.87502 2.77078 7.47502 3.16245C7.07502 3.55412 6.87502 4.05828 6.87502 4.67495C6.87502 5.29162 7.07502 5.79995 7.47502 6.19995C7.87502 6.59995 8.38336 6.79995 9.00002 6.79995Z"
      fill={color}
    />
  </svg>
);

PersonVectorIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

PersonVectorIcon.defaultProps = {
  color: '#212121',
  width: 18,
  height: 18,
};

export default PersonVectorIcon;
