import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const TextAreaSize = {
  Big: 'big',
  Medium: 'medium',
  Small: 'small',
};

const TextAreaSizeStyles = {
  [TextAreaSize.Big]: css`
    height: 9rem;
  `,
  [TextAreaSize.Medium]: css`
    height: 6rem;
  `,
  [TextAreaSize.Small]: css`
    height: 3rem;
  `,
};

const StyledTextArea = styled.textarea`
  font-family: ${({ theme }) => theme.fonts.primary.family};
  font-size: ${({ theme }) => theme.fonts.primary.size.md};
  line-height: ${({ theme }) => theme.fonts.primary.lineHeight.md};
  border: 1px solid ${({ theme }) => theme.colors.outline};
  border-radius: ${({ theme }) => theme.border.radius.lg};
  padding: 0.625rem 0.875rem;
  width: 100% !important;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.outline};
    cursor: not-allowed;
  }

  ${({ size }) => TextAreaSizeStyles[size]}
`;

const TextArea = ({ register, name, ...rest }) => <StyledTextArea {...register?.(name)} {...rest} />;

TextArea.propTypes = {
  disabled: PropTypes.bool,
  register: PropTypes.func,
  name: PropTypes.string,
  size: PropTypes.oneOf(Object.values(TextAreaSize)),
};

TextArea.defaultProps = {
  disabled: false,
  register: null,
  name: '',
  size: TextAreaSize.Small,
};

export default TextArea;
