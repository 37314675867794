import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';

import { selectCreateUserState } from 'store/selectors/users';
import useModal from 'hooks/useModal';
import Button from 'components/common/Button';
import Modal from 'components/common/Modals/Modal';
import AddUserForm from 'components/Forms/AddUserForm';
import { PlusIcon } from 'icons';
import useCustomMediaQuery from 'hooks/useMediaQuery';

const StyledButton = styled(Button)`
  white-space: nowrap;
  height: 2.625rem;

  ${({ theme }) => css`
    color: ${theme.colors.black};
  `}
`;

const AddUser = () => {
  const theme = useTheme();
  const isSmallMobile = useCustomMediaQuery(theme.mediaQueries.smallMobile);

  const { isOpen, toggle } = useModal();

  const { createUserLoading } = useSelector(selectCreateUserState, shallowEqual);

  const toggleFunction = createUserLoading ? null : toggle;

  return (
    <>
      <StyledButton onClick={toggle}>
        <PlusIcon />
        {isSmallMobile && 'Add User'}
      </StyledButton>
      <Modal title="User Details" isOpen={isOpen} toggle={toggleFunction}>
        <AddUserForm toggle={toggleFunction} />
      </Modal>
    </>
  );
};

export default AddUser;
