import { createAsyncThunk } from '@reduxjs/toolkit';

import signInWithEmail from 'services/auth/signinWithEmail';

export const signInAction = createAsyncThunk('auth/signIn', async (userData) => {
  const { email: signInEmail, password } = userData;

  const { role, uid, name, email } = await signInWithEmail(signInEmail, password);

  return { role, uid, name, email };
});
